import React, {useState} from 'react';
import { Button,TextField } from '@material-ui/core';

export default ({signIn}) => {
	const [email, emailChange] = useState('');
	const [password, passwordChange] = useState('');

	const _signIn = (e) => {
		e.preventDefault();
		signIn({email, password});
	};

	return (
		<div style={{display:'flex', height:'100%', width:'100%'}}>
			<form style={{margin:'auto', display:'flex', flexDirection:'column'}}>
				<TextField name='email' type='email' onChange={ e => emailChange(e.target.value) } label='Email'/>
				<TextField name='password' type='password' onChange={ e => passwordChange(e.target.value) } label='Mot de passe'/>
				<Button type={'submit'} onClick={_signIn} color='primary' variant='contained'>Connect</Button>
			</form>
		</div>
	);
};
