import React, { useCallback, useEffect, useState } from 'react';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import {
	Container,
	Paper,
	Button,
	Snackbar,
	Slide,
} from "@material-ui/core";

import './VersionChecker.scss';

export default ({ ...props }) => {
	const [state, change] = useState(false);
	const [newVersion, setNewVersion] = useState({ version: props.initial });
	const [mustUpdate, update] = useState(false);
	const [showLog, changeLog] = useState(false);

	const getVersion = useCallback(() => {
		let ORIGIN = window.location.origin;
		if (!ORIGIN) {
			ORIGIN = window.location.protocol +
				'//' +
				window.location.hostname +
				(window.location.port ? ':' + window.location.port : '');
		}

		fetch(`${ORIGIN}/version.json`)
		.then(res => {
			if (res.ok) return res.json();
			return false;
		})
		.then(json => {
			if (!json) return;
			let mustUpgrade = props.initial !== json.version;
			console.debug(
				'[VersionChecker]',
				'Initial version:',
				props.initial,
				'Actual version:',
				json.version
			);
			if (mustUpdate !== mustUpgrade) {
				setNewVersion(json);
				update(true);
			}
		})
		.catch(e => {
			console.error('[VersionChecker]', 'Cannot get version', e);
			change( "ERROR" );
		});
	}, [ props, update, mustUpdate ]);

	useEffect(() => {
		if (state) return;
		getVersion();
		change(true);
	}, [ state, getVersion ]);

	const interVal = setInterval(getVersion, props.delay);

	const refreshPage = (e) => {
		window.location.reload();
	};
	const showLogs = (e) => {
		changeLog(!showLog);
	};

	if (state === 'ERROR') clearInterval(interVal);
	if (mustUpdate && !(!!props.snackbar)) clearInterval(interVal);

	return (
		<div value={props.initial}>

			{ mustUpdate && !(!!props.snackbar) ?
			<Container className="VersionChecker">
				<Paper>
					<span><WarningOutlinedIcon color="secondary" fontSize="large" /></span>
					<h1>Une nouvelle version de l'application est en ligne</h1>
					<em>Veuillez recharger la page, afin de mettre à jour le site</em>
					<Button variant="contained" color="primary" name="refresh" onClick={refreshPage}>Mettre à jour</Button>
					<div>
						<p>Version : {props.initial}</p>
						<p>Nouvelle Version : {newVersion.version}</p>
					</div>
				</Paper>
			</Container> :
				props.children
			}

			{ !!props.snackbar && <Snackbar
				open={mustUpdate}
				TransitionComponent={SlideTransition}
				message={`Nouvelle version v${props.initial} => v${newVersion.version} ! Veuillez recharger la page pour mettre à jour.`}
				key={'SlideSnackVersion'}
				transitionDuration={{ enter: 750 }}
				anchorOrigin={{ horizontal: "center", vertical: "top" }}
				disableWindowBlurListener={true}
				action={
					<React.Fragment>
						<Button color="secondary" size="small" style={{textTransform: 'none'}} variant='contained' onClick={refreshPage}>
							Mettre à jour
						</Button>&nbsp;
						{!!newVersion.logs && <Button style={{textTransform: 'none'}} color="primary" size="small" variant='outlined' onClick={showLogs}>
							Logs
						</Button> }
					</React.Fragment>
				}
			/> }

			{ !!props.snackbar && <Snackbar
				open={showLog}
				TransitionComponent={SlideTransition}
				key={'SlideSnackLog'}
				transitionDuration={{ enter: 750 }}
				anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
				disableWindowBlurListener={true}
				action={
					<React.Fragment>
						<ul style={ {listStyle: 'none', textAlign:'center', paddingRight:'4rem' } }>
							{ !!newVersion.logs ? newVersion.logs.map(l => <li>{l}</li>) : "No change to show" }
						</ul>
					</React.Fragment>
				}
			/> }

		</div>
	);
};

const SlideTransition = (props) => {
	return (
		<Slide {...props} direction="up" />
	);
};
