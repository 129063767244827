import React from "react";
import { Card, CardContent, CircularProgress } from "@material-ui/core";

import './LoadingCircle.scss';

const LoadingCircle = ({ error }) => {
	return (
		<Card className="LoadCircle" >
			<CardContent>
				{!!error ?
					<p>{error.message ? error.message : error.restCode ? error.restCode : "Error"}</p>
					: <CircularProgress thickness={3} size="5rem"/>
				}
			</CardContent>
		</Card>
	);
};

export default LoadingCircle;
