import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	GridListTile,
	GridListTileBar,
	GridList
} from "@material-ui/core";
import API from "./API";
import CropperModal from "./CropperModal";

const useStyles = makeStyles(theme => ({
	gridList: {
		flexWrap: 'nowrap',
		transform: 'translateZ(0)',
	},
	title: {
		color: theme.palette.primary.light,
	},
	titleWarn: {
		color: theme.palette.warning.light,
	},
	titleBar: {
		cursor: 'pointer',
		background:
			'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0) 100%)',
	},
}));

const ImageGallery = ({ image, from, modify, applyUpdate }) => {
	const classes = useStyles();
	const [ newImage, setNewImage ] = useState(null);
	if (!from.type && !from.id) { throw new Error("Component need 'from' provided, with id and type"); }

	const addImage = (e) => {
		e.preventDefault();
		const input = document.getElementById('addImageHidden');
		input.click();
	};
	const saveImage = (e) => {
		e.preventDefault();
		API.image.create({
			image: newImage.cropped,
			for: from
		})
		.then(freshImage => {
			applyUpdate({
				image: freshImage.id
			});
			setNewImage(null);
		});
	};
	const loadImage = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			setNewImage({ src: reader.result, fileType: files[0].type });
		};
		reader.readAsDataURL(files[0]);
	};
	const deleteImage = (value, e) => {
		e.preventDefault();
		if (window.confirm("Confirmer pour supprimer l'image")) {
			applyUpdate({
				image: null
			});
		}
	};

	return (<Grid container spacing={2} direction={"column"} >
		<Grid item md={12}>
			<GridList className={classes.gridList} cols={2}>
				{ !modify && <GridListTile style={{ width: '15rem' }}>

					{ (!!newImage && newImage.cropped) && <img src={newImage.cropped} alt={'Preview_image4Upload'} /> }
					{ (!!newImage && newImage.src) && <CropperModal src={ newImage.src } fileType={ newImage.fileType } setImage={ setNewImage } ratio={1} /> }

					<input
						id="addImageHidden" style={{ display: 'none' }}
						type={'file'} accept={"image/*"} onChange={ loadImage }
					/>
					<GridListTileBar
						title={!newImage ? 'Ajouter un image' : 'Sauvegarder l\'image'}
						onClick={!newImage ? addImage : saveImage}
						classes={{ root: classes.titleBar, title: classes.title }}
					/>
				</GridListTile>
				}
				{!!image && <GridListTile style={{ width: '15rem' }}>
					<img src={`https://datas.urban-challenge.fr/${image.reduced ? image.reduced : image.path}`}
							 alt={`ImageSimple_${from.id}`}/>
					{!modify && <GridListTileBar
						title={"Supprimer l'image"}
						onClick={deleteImage.bind(this, { from })}
						classes={{ root: classes.titleBar, title: classes.titleWarn }}
					/>
					}
				</GridListTile>
				}
			</GridList>
		</Grid>
		{ !modify && <small>{`attention à uploader des images à taille moyenne. ex : (>= 500; <= 1000) X (>= 500; <= 1000 px)`}</small> }
	</Grid>);
};

export default ImageGallery;
