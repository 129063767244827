import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	Button,
	MenuItem,
	TableBody,
	Select, Grid
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from "./UniqueTableHead";
import './Places.scss';
import API from './API';

export default () => {
	const [ state, change ] = useState("INIT");
	const [ newPlace, updatePlace ] = useState("INIT");
	const [ places, changePlaces ] = useState(null);
	const [ query, changeQuery ] = useState({
		search: null,
		status: 'published',
		orderBy: 'created_date',
		order: 'desc',
		page: 0,
		maxPage: 1,
		resultPerPage: 50,
		total: 0
	});

	useEffect(() => {
		if (places) return;
		change("PENDING");
		API.place.search(query)
		.then(results => {
			changePlaces( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		})
		.then( () => change("OK") )
		.catch(err => {
			console.error(err);
		});

	}, [ places, query ]);

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changePlaces(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change("PENDING");
		changePlaces(null);
	};

	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changePlaces(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changePlaces(null);
	};
	const handleSearchChange = (e) => {
		changeQuery({ ...query, search: e.target.value });
	};
	const handleFilterChange = (e) => {
		changeQuery({ ...query, status: e.target.value });
		changePlaces(null);
	};
	const addPlace = (e) => {
		e.preventDefault();
		const title = window.prompt("Entre le nom du lieu");
		if (title && title.length > 2) {
			change("PENDING");
			API.place.create({ title })
			.then(place => {
				updatePlace(place);
				change("REDIRECT");
			})
			.catch(console.error);
		}
	};

	const selectStatus = [ { value: 'all', display: 'Tous' }, { value: 'pending', display: 'En attente' }, { value: 'published', display: 'Publié' }, { value: 'deleted', display: 'Supprimé' } ];
	const forms = [
		{ md: 8, type: 'text', label: 'Recherche', placeholder: 'Nom du lieu', className: 'SearchField', value: query.search, onChange: handleSearchChange },
		{ md: 2, type: 'select', className: 'SelectField', value: query.status, onChange: handleFilterChange, choices: selectStatus },
		{ md: 2, type: 'button', className: 'AddField', display: 'Ajouter', onClick: addPlace, variant: 'contained', color: 'primary' }
	];

	return <Container className='Places'>
		<Grid container spacing={2} justify="center" alignItems="center" style={{ marginBottom: ".5rem" }}>
			{ forms.map( (s, i) => (
				<Grid item md={s.md} key={ "filter_" + i }>
					{s.type === 'button' ?
						<Button className={s.className} variant={s.variant} color={s.color} onClick={s.onClick}>{s.display}</Button> :
						<form onSubmit={search} noValidate autoComplete="off">
							{s.type === 'text' && <TextField fullWidth className={s.className} label={s.label} defaultValue={s.value}
																							 placeholder={s.placeholder} variant="outlined" onChange={s.onChange}/>}
							{s.type === 'select' && <Select
								variant="outlined"
								fullWidth
								className="SearchField"
								value={s.value}
								onChange={s.onChange}>
								{s.choices.map(t => <MenuItem key={'typeInvoice_' + t.value} value={t.value}>{t.display}</MenuItem>)}
							</Select>}
						</form>
					}
				</Grid>
			) )}
		</Grid>

		{ (state === "REDIRECT" && newPlace) && <Redirect to={`/places/${newPlace.id}`} /> }
		{ state === "PENDING" && <LoadingCircle /> }
		{ state === "OK" && <PlacesList
			places={ places }
			onRequestSort={ handleRequestSort }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }

	</Container>;
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'id', disablePadding: true, label: 'ID' },
		{ id: 'title', disablePadding: false, label: 'Nom' },
		{ id: 'state', disablePadding: false, label: 'Status' },
		{ id: 'created_date', disablePadding: false, label: 'Date de création' },
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const PlacesList = ({ places, ...props }) => {
	if (!places) return null;

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{places.map(place => (
					<TableRow key={place.id} hover={true} >
						<TableCell align="center">
							<Link to={`/places/${place.id}`}>{place.id}</Link>
						</TableCell>
						<TableCell align="center">{ place.title }</TableCell>
						<TableCell align="center">{ place.state === "deleted" ? "Supprimé" : place.state === "published" ? "Publié" : "En Attente" }</TableCell>
						<TableCell align="center">{ moment(place.date.creation).format('YYYY-MM-DD HH:mm') }</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};
