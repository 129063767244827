import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Grid, MenuItem, Select
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from "./UniqueTableHead";
import './Invoices.scss';
import API from './API';

const statusChoices = [
	{ value: 'all', display: "Toute"},
	{ value: 'OLD', display: "Ancienne"},
	{ value: 'DONE', display: "Prête"},
	{ value: 'ONLINE', display: "En ligne"}
];

export default () => {
	const [ state, change ] = useState("INIT");
	const [ invoices, changeInvoices ] = useState(null);
	const [ query, changeQuery ] = useState({
		coach: null,
		month: null,
		status: 'ONLINE',
		order: 'month-desc',
		page: 0,
		maxPage: 1,
		resultPerPage: 50,
		total: 0
	});

	useEffect(() => {
		if (invoices) return;
		change("PENDING");
		API.invoices.search({
			...query
		})
		.then(results => {
			changeInvoices( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		})
		.then( () => change("OK") )
		.catch(console.error);

	}, [ invoices, query ]);

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changeInvoices(null);
	};

	const handleRequestSort = (event, property) => {
		const ord = query.order.split('-');
		const isAsc = ord[0] === property && ord[1] === 'asc';
		changeQuery({
			...query,
			order: property + (isAsc ? '-desc' : '-asc'),
		});
		change("PENDING");
		changeInvoices(null);
	};
	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changeInvoices(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changeInvoices(null);
	};
	const handleCoachChange = (e) => {
		changeQuery({ ...query, coach: e.target.value });
	};
	const handleMonthChange = (e) => {
		changeQuery({ ...query, month: e.target.value });
	};
	const handleStatusChange = (e) => {
		changeQuery({ ...query, status: e.target.value });
		changeInvoices(null);
	};

	const searchField = [
		{ md: 4, type: 'text', label: 'Mois', placeholder: "YYYY-MM ex: 2020-01", value: query.month, onChange: handleMonthChange },
		{ md: 4, type: 'text', label: 'Coach', placeholder: "Nom / Prénom du coach", value: query.coach, onChange: handleCoachChange },
		{ md: 4, type: 'select', label: 'Status', choices: statusChoices, value: query.status, onChange: handleStatusChange },
	];

	return (
		<Container className='Invoices'>
			<Grid container spacing={1} direction="row" justify="space-evenly" alignItems="center">
				{ searchField.map( (s, i) => (
					<Grid item md={s.md} key={ "filter_" + i }>
						<form onSubmit={ search } noValidate autoComplete="off">
							{ s.type === 'text' && <TextField fullWidth className="SearchField" label={s.label} defaultValue={ s.value } placeholder={s.placeholder} variant="outlined" onChange={ s.onChange } />}
							{ s.type === 'select' && <Select
								variant="outlined"
								fullWidth
								className="SearchField"
								value={ s.value }
								onChange={ s.onChange }>
								{ s.choices.map(t => <MenuItem key={'typeInvoice_' + t.value} value={t.value}>{t.display}</MenuItem>) }
							</Select> }
						</form>
					</Grid>
				) )}
			</Grid>

			{ state === "PENDING" && <LoadingCircle /> }
			{ state === "OK" && <InvoiceList
				invoices={ invoices }
				onRequestSort={ handleRequestSort }
				order={ query.order }
				orderBy={ query.orderBy }
				page={ query.page - 1 }
				total={ query.total }
				resultPerPage={ query.resultPerPage }
				changePage={ handleChangePage }
				changeRowsPerPage={ handleChangeRowsPerPage }
			/> }
		</Container>
	);
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, onRequestSort } = props;
	const ord = order.split('-');
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'id', disablePadding: true, label: '#', disableSort: true },
		{ id: 'creationDate', disablePadding: false, label: 'Création' },
		{ id: 'urbanCode', disablePadding: false, label: 'Code urban', disableSort: true },
		{ id: 'firstname', disablePadding: false, label: 'Prénom', disableSort: true },
		{ id: 'lastname', disablePadding: false, label: 'Nom', disableSort: true },
		{ id: 'coach_type', disablePadding: false, label: 'Coach type' },
		{ id: 'month', disablePadding: false, label: 'Mois' },
		{ id: 'value', disablePadding: false, label: 'Valeur' },
		{ id: 'status', disablePadding: false, label: 'Status', disableSort: true },
		{ id: 'downloadUrl', disablePadding: false, label: 'Facture', disableSort: true },
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ ord[0] }
			order={ ord[1] }
			createSortHandler={ createSortHandler } />
	);
};

const InvoiceList = ({ invoices, ...props }) => {
	if (!invoices) return null;

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{invoices.map(invoice => (
					<TableRow key={invoice.id} hover={true} >
						<TableCell align="center">{invoice.id}</TableCell>
						<TableCell align="center">{ moment(invoice.creationDate).format('YYYY-MM-DD HH:mm') }</TableCell>
						<TableCell align="center">{ invoice.coach.urbanCode }</TableCell>
						<TableCell align="center">
							<Link to={`/users/${invoice.coach.id}`}>
								{ invoice.coach.firstname }
							</Link>
						</TableCell>
						<TableCell align="center">{ invoice.coach.lastname }</TableCell>
						<TableCell align="center">{ invoice.coach.type }</TableCell>
						<TableCell align="center">{ invoice.month }</TableCell>
						<TableCell align="center">{ invoice.value / 100 }€</TableCell>
						<TableCell align="center">{ invoice.status }</TableCell>
						<TableCell align="center">
							<a
								style={{ textTransform: "none" }}
								download
								href={invoice.downloadUrl}>
								Download
							</a>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};
