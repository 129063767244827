import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Cropper from "react-cropper";
import '../node_modules/cropperjs/dist/cropper.css';

const cropper = React.createRef('cropper');

export default class CropperModal extends Component {

	constructor(props, context) {
		super(props, context);
		if (
			!this.props.src || !this.props.fileType ||
			!this.props.setImage || !this.props.ratio
		) { throw new Error("Component need 'src, fileType, setImage, ratio' provided"); }

		this.state = { open: true, cropped: null };
	}

	handleClose = (e) => {
		e.preventDefault();
		this.setState({ open: false });
	};

	crop = () => {
		let img = cropper.current.cropper.getCroppedCanvas();
		this.props.setImage({ src: this.props.src, fileType: this.props.fileType, cropped: img.toDataURL( this.props.fileType ) });
		this.setState({ open: true, cropped: img });
	};

	render() {
		return (
			<Dialog open={ this.state.open } >
				<DialogTitle>
					Découpe de l'image
				</DialogTitle>
				<DialogContent>
					<Cropper
						src={ this.props.src }
						style={{ height: '20rem', width: '100%', padding: '1rem' }}
						aspectRatio={ this.props.ratio }
						guides={ false }
						ref={ cropper }
						crop={ this.crop }
					/>
				</DialogContent>
				<DialogActions>
					<Button style={{ textTransform: 'none' }} disabled color="primary">
						{!!this.state.cropped && `${this.state.cropped.width}x${this.state.cropped.height}`}
					</Button>
					<Button style={{ textTransform: 'none' }} onClick={ this.handleClose } color="primary">
						Suivant
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
