import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	Button,
	MenuItem,
	TableBody,
	Select,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogActions,
	FormControl,
	Grid,
	InputLabel,
	Checkbox,
	FormControlLabel,
	Tooltip
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from "./UniqueTableHead";
import './Promos.scss';
import API from './API';

export default () => {
	const [ state, change ] = useState("INIT");
	const [ modalOpen, setModal ] = useState(false);
	const [ newPromo, updatePromo ] = useState({
		step: "INIT",
		offerId: 'none',
		corporateId: 'none',
		placeId: 'none',
		classId: 'none',
	});
	const [ promos, changePromos ] = useState(null);
	const [ query, changeQuery ] = useState({
		search: null,
		status: 'all',
		type: 'all',
		isCorporate: 'all',
		orderBy: 'id',
		order: 'desc',
		page: 0,
		maxPage: 1,
		resultPerPage: 50,
		total: 0
	});

	useEffect(() => {
		if (promos) return;
		change("PENDING");
		API.promo.search(query)
		.then(results => {
			changePromos( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		})
		.then( () => change("OK") )
		.catch(err => {
			console.error(err);
		});
	}, [ promos, query ]);

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changePromos(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change("PENDING");
		changePromos(null);
	};

	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changePromos(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changePromos(null);
	};
	const handleSearchChange = (e) => {
		changeQuery({ ...query, search: e.target.value });
	};
	const handleRestrictChange = (e) => {
		changeQuery({ ...query, isCorporate: e.target.value });
		changePromos(null);
	};
	const handleFilterChange = (e) => {
		changeQuery({ ...query, status: e.target.value });
		changePromos(null);
	};
	const handleTypeChange = (e) => {
		changeQuery({ ...query, type: e.target.value });
		changePromos(null);
	};
	const handleModalClose = () => {
		if (Object.values(newPromo).length > 4) {
			if (window.confirm("Des changements n'ont pas été enregistrer, continuer ?")) {
				updatePromo({ step: "INIT", corporateId: 'none', placeId: 'none', classId: 'none', offerId: 'none' });
				return setModal(!modalOpen);
			}
			else return ;
		}
		setModal(!modalOpen);
		updatePromo({ step: "INIT", corporateId: 'none', placeId: 'none', classId: 'none', offerId: 'none' });
	};
	const addPromo = (e) => {
		e.preventDefault();
		setModal(true);
		updatePromo({ ...newPromo, step: "CREATE" });
	};

	const selectType = [ { value: 'all', display: 'Tout' }, { value: 'single', display: 'Unique' }, { value: 'multiple', display: 'Multiple' } ];
	const selectCorp = [ { value: 'all', display: 'Tout' }, { value: 'corporate', display: 'Corporate' }, { value: 'public', display: 'Public' } ];
	const selectStatus = [ { value: 'all', display: 'Tous' }, { value: 'used', display: 'Utilisé' }, { value: 'enabled', display: 'Activé' }, { value: 'disabled', display: 'Désactivé' } ];
	const forms = [
		{ md: 4, type: 'text', label: 'Recherche', placeholder: 'Nom de la promo', className: 'SearchField', value: query.search, onChange: handleSearchChange },
		{ md: 2, type: 'select', className: 'SelectField', value: query.type, onChange: handleTypeChange, choices: selectType },
		{ md: 2, type: 'select', className: 'SelectField', value: query.isCorporate, onChange: handleRestrictChange, choices: selectCorp },
		{ md: 2, type: 'select', className: 'SelectField', value: query.status, onChange: handleFilterChange, choices: selectStatus },
		{ md: 2, type: 'button', className: 'AddField', display: 'Ajouter', onClick: addPromo, variant: 'contained', color: 'primary' }
	];

	return (<Container className='Promos'>
		<Grid container spacing={2} justify="center" alignItems="center" style={{ marginBottom: ".5rem" }}>
		{ forms.map( (s, i) => (
			<Grid item md={s.md} key={ "filter_" + i }>
				{s.type === 'button' ?
					<Button className={s.className} variant={s.variant} color={s.color} onClick={s.onClick}>{s.display}</Button> :
					<form onSubmit={search} noValidate autoComplete="off">
						{s.type === 'text' && <TextField fullWidth className={s.className} label={s.label} defaultValue={s.value}
																						 placeholder={s.placeholder} variant="outlined" onChange={s.onChange}/>}
						{s.type === 'select' && <Select
							variant="outlined"
							fullWidth
							className="SearchField"
							value={s.value}
							onChange={s.onChange}>
							{s.choices.map(t => <MenuItem key={'typeInvoice_' + t.value} value={t.value}>{t.display}</MenuItem>)}
						</Select>}
					</form>
				}
			</Grid>
		) )}
		</Grid>

		{ (state === "REDIRECT" && !!newPromo.id) && <Redirect to={`/promos/${newPromo.id}`} /> }
		{ newPromo.step === "CREATE" && <PromoCreate state={state} change={change} open={modalOpen} handleClose={handleModalClose} promo={newPromo} setPromo={updatePromo} /> }
		{ state === "PENDING" && <LoadingCircle /> }
		{ state.step === "ERROR" && <LoadingCircle error={ state.error }/> }
		{ state === "OK" && <PromosList
			promos={ promos }
			onRequestSort={ handleRequestSort }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }
	</Container>);
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'id', disablePadding: true, label: '#' },
		{ id: 'code', disablePadding: true, label: 'Code', disableSort: true },
		{ id: 'status', disablePadding: true, label: 'Status', disableSort: true },
		{ id: 'expiration_at', disablePadding: true, label: 'Expiration' },
		{ id: 'type', disablePadding: true, label: 'Type', disableSort: true },
		{ id: 'reduction', disablePadding: true, label: 'Réduction', disableSort: true },
		{ id: 'duration', disablePadding: true, label: 'Duration', disableSort: true },
		{ id: 'corporate', disablePadding: true, label: 'Corporate', disableSort: true },
		{ id: 'sponsor', disablePadding: true, label: 'Parainage', disableSort: true },
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const PromosList = ({ promos, ...props }) => {
	if (!promos) return null;

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{ promos.map(promo => (
					<TableRow key={promo.id} hover={true} >
						<TableCell align="center">
							<Link to={`/promos/${promo.id}`}>{promo.id}</Link>
						</TableCell>
						<TableCell align="center">{ promo.code }</TableCell>
						<TableCell align="center">{ promo.state }</TableCell>
						<TableCell align="center">{
							!promo.date.expiration ? '' : moment(promo.date.expiration).format('YYYY-MM-DD')
						}</TableCell>
						<TableCell align="center">{ promo.type }</TableCell>
						{ !!promo.value ?
							<TableCell align="center">-{ promo.value / 100 }€</TableCell> :
							<TableCell align="center">-{ promo.percentage }%</TableCell>
						}
						<TableCell align="center">{ promo.duration > 0 ? `${promo.duration} mois` : "Illimité" }</TableCell>
						<TableCell align="center" className={(promo.corporate && promo.corporate.restrict) ? 'PromoCorpRestrict' : 'PromoCorp'}>
							{promo.corporate ? promo.corporate.name : "" }
						</TableCell>
						<TableCell align="center">{
							!!promo.sponsor ?
								<Link to={`/users/${promo.sponsor}`}>{"User " + promo.sponsor}</Link>
								: "" }</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};

const PromoCreate = ({ state, open, handleClose, promo, setPromo, change }) => {
	const dateFormat = 'YYYY-MM-DD HH:mm:ss';
	const [ places, updatePlaces ] = useState([]);
	const [ classes, updateClasses ] = useState([]);
	const [ corporates, updateCorporates ] = useState([]);
	const [ offers, updateOffers ] = useState([]);

	useEffect(() => {
		const sortById = (a, b) => {
			if (+a.id > +b.id) return 1;
			else if (+a.id < +b.id) return -1;
			return 0;
		};
		if (places.length > 0 || corporates.length > 0 || offers.length > 0 || classes.length > 0) return ;
		API.place.search({ state: 'published', resultPerPage: 1000 })
		.then(place => {
			updatePlaces(place.values);
			return API.classe.search({ state: 'published', resultPerPage: 1000 })
		})
		.then(classe => {
			updateClasses(classe.values)
			return API.corporate.search({ resultPerPage: 1000 });
		})
		.then(corporate => {
			updateCorporates(corporate.values);
			return API.offer.search({ resultPerPage: 1000, status: 'published' });
		})
		.then(offer => {
			updateOffers(offer.values.sort(sortById));
		});
	}, [ corporates, places, classes, offers, updateCorporates, updatePlaces, updateOffers, updateClasses ]);

	const handleChange = (e) => {
		let tmp = {};
		tmp[e.target.name] = e.target.value || e.target.checked;
		if (e.target.name === 'code') tmp.code = tmp.code.toUpperCase();
		if (e.target.name === 'valueCurrency') {
			tmp.valueCurrency = parseFloat(e.target.value);
			if (promo.currency === 'percentage' && tmp.valueCurrency > 100)
				tmp.valueCurrency = 100;
		}
		setPromo({ ...promo, ...tmp });
	};

	const save = () => {
		let body = {
			created_at: moment().format('YYYY-MM-DD HH:mm:ss')
		};

		if (typeof promo.code !== undefined) body.code = promo.code;
		if (typeof promo.comment !== undefined) body.comment = promo.comment;
		if (typeof promo.duration !== undefined) body.duration = parseInt(promo.duration) || 0;
		if (typeof promo.type !== undefined && promo.type !== 'none') body.type = promo.type;
		if (typeof promo.state !== undefined && promo.state !== 'none') body.state = promo.state;
		if (typeof promo.currency !== undefined && promo.currency !== 'none') body[promo.currency] = parseFloat(promo.valueCurrency);
		if (typeof promo.corporateId !== undefined && promo.corporateId !== 'none') body.corporateId = promo.corporateId;
		if (typeof promo.restrictCorporate !== undefined) body.restrictCorporate = promo.restrictCorporate;
		if (typeof promo.placeId !== undefined && promo.placeId !== 'none') body.placeId = promo.placeId;
		if (typeof promo.classId !== undefined && promo.classId !== 'none') body.classId = promo.classId;
		if (typeof promo.offerId !== undefined && promo.offerId !== 'none') body.offerId = promo.offerId;

		if (!!promo.expiration_at) body.expiration_at = moment(promo.expiration_at).format(dateFormat);
		else body.expiration_at = moment().add(1, "year").format(dateFormat);

		if (body.value) body.value = body.value * 100;

		API.promo.create(body)
		.then(newPromo => {
			setPromo({ id: newPromo.id, step: "INIT" });
			change("REDIRECT");
		})
		.catch(err => {
			console.error(err);
			change({ step: "ERROR", error: err });
		});
	};
	const canSave = () => {
		return !(!!promo.code &&
			!!promo.type &&
			!!promo.state &&
			!!promo.currency);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth maxWidth="md"
			aria-labelledby="form-dialog-title">
			<div className="PromoCreate">
				<DialogTitle id="form-dialog-title">
					Création d'un code promo
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} direction={'row'} justify={'space-between'} alignItems={'center'}>
						<Grid item md={6}>
							<Grid container spacing={2} direction={'column'} justify={'center'} alignItems={'stretch'}>
								{/* CODE */}
								<Grid item md={12}>
									<FormControl fullWidth>
										<TextField
											fullWidth
											error={ !!state.error && !!state.error.resource.code }
											label="Code"
											type="text"
											name="code"
											onChange={ handleChange }
											value={ promo.code || "" }
										/>
									</FormControl>
								</Grid>
								{/* Type & State */}
								<Grid item md={12}>
									<Grid container spacing={1} direction={'row'} justify='space-around' alignItems="center">
										<Grid item md={6}>
											<FormControl fullWidth>
												<InputLabel shrink id="select-promo-type">Type</InputLabel>
												<Select
													labelId="select-promo-type"
													name="type"
													value={ promo.type || 'none' }
													onChange={ handleChange }>
													<MenuItem value={'none'}>Type de code</MenuItem>
													<MenuItem value={'single'}>Unique</MenuItem>
													<MenuItem value={'multiple'}>Multiple</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item md={6}>
											<FormControl fullWidth>
												<InputLabel shrink id="select-promo-state">Status</InputLabel>
												<Select
													labelId="select-promo-state"
													name="state"
													value={ promo.state || 'none' }
													onChange={ handleChange }>
													<MenuItem value={'none'}>Etat du code</MenuItem>
													<MenuItem value={'disabled'}>Désactivé</MenuItem>
													<MenuItem value={'enabled'}>Activé</MenuItem>
													<MenuItem value={'used'}>Utilisé</MenuItem>
												</Select>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
								{/* %/€ & Montant */}
								<Grid item md={12}>
									<Grid container spacing={1} direction={'row'} justify='space-around' alignItems="center">
										<Grid item md={6}>
											<FormControl fullWidth>
												<InputLabel shrink id="select-promo-remise">%/€</InputLabel>
												<Select
													labelId="select-promo-remise"
													name="currency"
													value={ promo.currency || 'none' }
													onChange={ handleChange }>
													<MenuItem value={'none'}>Type de reduction</MenuItem>
													<MenuItem value={'percentage'}>- X %</MenuItem>
													<MenuItem value={'value'}>- X €</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item md={6}>
											<FormControl fullWidth>
												<TextField
													fullWidth
													label="Montant %/€"
													type="number"
													inputProps={{ step: 0.5, min: 0 }}
													name="valueCurrency"
													disabled={ promo.currency === 'none' }
													onChange={ handleChange }
													value={ promo.valueCurrency || 0 }
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
								{/* Duration */}
								<Grid item md={12}>
									<FormControl fullWidth>
										<TextField
											fullWidth
											label="Durée (0=Infini, 1=1mois, ...)"
											type="number"
											inputProps={{ step: 1, min: 0 }}
											name="duration"
											onChange={ handleChange }
											value={ promo.duration || 0 }
										/>
									</FormControl>
								</Grid>
								{/* Expiration_at */}
								<Grid item md={12}>
									<FormControl fullWidth>
										<TextField
											fullWidth
											label="Valable jusqu'au"
											type="date"
											name="expiration_at"
											onChange={ handleChange }
											value={ promo.expiration_at || moment().add(1, 'year').format("YYYY-MM-DD") }
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={6}>
							<Grid container spacing={2} direction={'column'} justify={'center'} alignItems={'stretch'}>
								{/* Corporate restricted */}
								<Grid item md={12}>
									{promo.corporateId !== 'none' && <FormControlLabel label="Corporate Restricted" labelPlacement="end"
																		control={<Checkbox color="secondary" name="restrictCorporate" onChange={handleChange} defaultChecked={ promo.restrictCorporate > 0 || false } />}
									/>}
								</Grid>

								<Grid item md={12}>
									{/* Choose Corp */}
									{(!!corporates && corporates.length > 0) &&
									<FormControl style={{ width: '20rem' }}>
										<InputLabel shrink id="promo-select-place">Corporate</InputLabel>
										<Select
											labelId="promo-select-place"
											name="corporateId"
											value={promo.corporateId}
											onChange={handleChange}>
											<MenuItem value={'none'}>Choisir une entreprise</MenuItem>
											{corporates.map(co => <MenuItem key={co.id} value={co.id}>{co.name}</MenuItem>)}
										</Select>
									</FormControl>
									}
								</Grid>

								<Grid item md={12}>
									{/* Choose Place */}
									{(!!places && places.length > 0) &&
									<FormControl style={{ width: '20rem' }}>
										<InputLabel shrink id="promo-select-place">Restrict Place</InputLabel>
										<Select
											labelId="promo-select-place"
											name="placeId"
											value={promo.placeId}
											onChange={handleChange}>
											<MenuItem value={'none'}>Choisir un lieu</MenuItem>
											{places.map(e => (
												<Tooltip key={e.id} value={e.id} title={`#${e.id} status=${e.state}`} arrow>
													<MenuItem key={e.id} value={e.id}>{e.title}</MenuItem>
												</Tooltip>
											))}
										</Select>
									</FormControl>
									}
								</Grid>

								<Grid item md={12}>
									{/* Choose Classe */}
									{(!!classes && classes.length > 0) &&
									<FormControl style={{ width: '20rem' }}>
										<InputLabel shrink id="promo-select-classe">Restrict Classe</InputLabel>
										<Select
											labelId="promo-select-classe"
											name="classId"
											value={promo.classId}
											onChange={handleChange}>
											<MenuItem value={'none'}>Choisir un Cours</MenuItem>
											{classes.map(e => (
												<Tooltip key={e.id} value={e.id} title={e.description || ''} arrow>
													<MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
												</Tooltip>
											))}
										</Select>
									</FormControl>
									}
								</Grid>

								<Grid item md={12}>
									{/* Choose Offers */}
									{(!!offers && offers.length > 0) &&
									<FormControl style={{ width: '20rem' }}>
										<InputLabel shrink id="promo-select-offer">Restrict Offer</InputLabel>
										<Select
											labelId="promo-select-offer"
											name="offerId"
											value={ promo.offerId }
											onChange={handleChange}>
											<MenuItem value={'none'}>Choisir une offre</MenuItem>
											{offers.map(o => (
												<Tooltip key={o.id} value={o.id} title={o.description} arrow>
													<MenuItem>{o.name} {!!o.restriction.corporate ? "©" : ""}</MenuItem>
												</Tooltip>
											))}
										</Select>
									</FormControl>
									}
								</Grid>
								{/* Comment */}
								<Grid item md={12}>
									<FormControl fullWidth>
										<TextField
											fullWidth
											label="Commentaire"
											type="text"
											name="comment"
											onChange={ handleChange }
											value={ promo.comment || '' }
										/>
									</FormControl>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button style={{ textTransform: 'none' }} onClick={ handleClose } color="secondary">
						Annuler
					</Button>
					<Button style={{ textTransform: 'none' }} disabled={ canSave() } onClick={ save } color="primary" variant="outlined">
						Créer
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};
