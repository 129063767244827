import React from 'react';
import { Grid, Button } from "@material-ui/core";

const ORIGIN = (
	window.location.origin
	|| `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
);

const exportEndpoints = [
	{ href: 'abo', name: "Abonnements" },
	// { href: 'aboUnlimited', name: "Abonnements Illimité" }, // Hide button
	{ href: 'newsLetter', name: "Abonnés newsLetter" },
	{ href: 'coachInvoice', name: "Facturation Coach" },
	{ href: 'coachVisual', name: "Coachs Visibilité" },
	{ href: 'promo', name: "Les codes promos" },
	{ href: 'payment', name: "Payments" },
	{ href: 'user', name: "Tous les utilisateurs" },
	{ href: 'booking', name: "Toutes les réservations" },
	{ href: 'bookingRunner', name: "Booking/Runner" },
	{ href: 'trial', name: "Séances d'essai" },
	{ href: 'place', name: "Liste des lieux" },
	{ href: 'feedback', name: "Feedbacks" },
	{ href: 'perClass', name: "Par Séances" },
	{ href: 'soonClass', name: "Séances à venir" },
	{ href: 'lowBookingSeance', name: "Séances avec moins de 4 réservations" },
];

export default () => {
	return (
		<div className='Exports' style={{ paddingTop: '2rem' }}>
			<Grid container spacing={2} alignContent={'center'}>
				{exportEndpoints.map( (ee, i) => (
					<div style={{display:'flex', margin: '1rem'}}>
						<Button color="secondary" variant='outlined' href={`${ORIGIN}/api/admin/exports/${ee.href}`} download>{ee.name}</Button>
					</div>
				))}
			</Grid>
		</div>
	);
};
