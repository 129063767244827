import React, { useState }  from 'react';

import './EasterEgg.scss';

export default ({ children }) => {
	const [className, changeClassName] = useState('');
	const setEaster = () => {
		if (className === '') changeClassName('EasterRotate');
		setTimeout(() => changeClassName(''), 3000);
	};
	return (
		<>
			<div className={"EasterButton"} onClick={setEaster} />
			{ className === 'EasterRotate' && <div className="pyro"><div className="before"/> <div className="after"/></div> }
			<div className={className}>
				{children}
			</div>
		</>
	);
};
