import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import API from "./API";
import LoadingCircle from "./LoadingCircle";
import moment from "moment";

export default () => {
	const [state, update] = useState({ step: "INIT", subscription: [] });
	const { search } = useLocation();
	const params = new URLSearchParams(search);

	const sortByOffer = (sub) => {
		const byOffer = Object.values(sub).reduce(function (r, a) {
			let offerId = a.offer.id;
			r[offerId] = r[offerId] || [];
			r[offerId].push(a);
			return r;
		}, Object.create(null));
		return byOffer;
	};

	const load = () => {
		if (state.step === "OK") return ;
		API.stats.search({
			start: params.get('start'),
			end: params.get('end')
		})
		.then(ret => {
			update({ step: "OK", subscription: sortByOffer(ret.values) });
		})
		.catch(err => {
			update({ step: "ERROR", subscription: [], error: err });
		});
	};

	const countTotal = () => {
		let total = 0;
		Object.values(state.subscription).forEach(sub => {
			total += sub.reduce((acc, s) => acc + s.payed, 0);
		});
		return <li key={"total"}>Total : {total / 100}€</li>;
	};

	useEffect(load, [ ]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		window.location.assign( window.location.origin + `/stats?${new URLSearchParams({ 
			start: params.get('start') || moment().startOf('month').subtract(1, "day").format('YYYY-MM-DD'),
			end: params.get('end') || moment().endOf('month').format('YYYY-MM-DD'),
			[name]: value 
		}).toString()}`);
	};

	return (
		<div style={{ margin: "2rem" }}>
			<h4>Stats of Month (par defaut du 1er du mois à aujourd'hui)</h4>
			{ state.step === "ERROR" && <LoadingCircle error={state.error} /> }
			{ state.step === "INIT" && <LoadingCircle /> }

			<form style={{ display: 'flex', flexDirection: 'column' }}>
				<label>
					Date de début du calcul (Supérieur à, non inclus)
					<input style={{ marginLeft: '2rem' }} type={'date'} defaultValue={params.get("start") || moment().startOf('month').subtract(1, "day").format('YYYY-MM-DD')} name={'start'} onChange={handleChange} />
				</label>
				<label>
					Date de fin du calcul (Inférieur où égale, inclus)
					<input style={{ marginLeft: '2.4rem' }} type={'date'} defaultValue={params.get("end") || moment().endOf('month').format('YYYY-MM-DD')} name={'end'} onChange={handleChange} />
				</label>
			</form>
			<ul>
				{ state.step === "OK" && Object.values(state.subscription).map(subs => {
					return <li key={subs[0].id}>
						{subs.length} x {(subs[0].offer.price / 100)}€ => {subs.reduce((acc, s) => acc + s.payed, 0) / 100}€ | <Link to={`/offers/${subs[0].offer.id}`}>{subs[0].offer.name}</Link> | {subs[0].offer.description}</li>;
				}) }
				{ state.step === "OK" && countTotal() }
			</ul>

		</div>
	);
};
