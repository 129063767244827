import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect, withRouter
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import GoogleMapProvider from "./GoogleMapProvider";
import AppBar from './AppBar';
import Exports from './Exports';
import Users from './Users';
import Places from './Places';
import UserDisplay from './UserDisplay';
import PlaceDisplay from './PlaceDisplay';
import Session from './Session';
import Planning from './Planning';
import Home from './Home';
import Corporates from "./Corporates";
import CorporateDisplay from "./CorporateDisplay";
import Invoices from './Invoices';
import Offers from './Offers';
import OfferDisplay from './OfferDisplay';
import Trials from './Trials';
import Promos from './Promos';
import PromoDisplay from './PromoDisplay';
import Participants from './Participants';
import Classes from './Classes';
import ClasseDisplay from './ClasseDisplay';
import VersionChecker from "./VersionChecker";
import EasterEgg from "./EasterEgg";
import StatsOfMonth from "./StatsOfMonth";
import UserPenality from "./UserPenality";

export default ({ darkMode, versions }) => {
  return (
    <Router>
      <GoogleMapProvider>
        <div style={{display:'none'}} id={'gmapHidden'} />
        <CssBaseline/>
        <VersionChecker snackbar={true} env={ versions.env } initial={ versions.initial } delay={ versions.delay }>
          <Session>
            <EasterEgg>
              <AppBar darkMode={darkMode} />
              <ScrollToTop />
              <main>
                <Switch>
                  <Route exact path='/home' component = { Home } />

                  <Route exact path='/corporates' component= { Corporates } />
                  <Route       path='/corporates/:corporateId' component= { CorporateDisplay } />

                  <Route exact path='/planning' component = { Planning } />
                  <Route exact path='/participants/:seanceId' component = { Participants } />

                  <Route exact path='/offers' component= { Offers } />
                  <Route       path='/offers/:offerId' component= { OfferDisplay } />

                  <Route exact path='/promos' component= { Promos } />
                  <Route       path='/promos/:promoId' component= { PromoDisplay } />

                  <Route exact path='/classes' component= { Classes } />
                  <Route       path='/classes/:classeId' component= { ClasseDisplay } />

                  <Route exact path='/places' component= { Places } />
                  <Route       path='/places/:placeId' component = { PlaceDisplay } />

                  <Route exact path='/users' component = { Users } />
                  <Route       path='/users/:userId' component = { UserDisplay } />

                  <Route exact path='/invoices' component={ Invoices } />

                  <Route exact path='/trials' component={ Trials } />

                  <Route       path='/exports' component = { Exports } />

                  <Route exact path='/stats' component={ StatsOfMonth } />

                  <Route exact path='/penality' component={ UserPenality } />
                  <Redirect from='*' to='/home' />
                </Switch>
              </main>
            </EasterEgg>
          </Session>
        </VersionChecker>
      </GoogleMapProvider>
    </Router>
  );
}

const ScrollToTop = withRouter(
    ({children,location}) => {

      useEffect( () => {
        window.scrollTo(0, 0);
      }, [location] );

      return children || null;
    }
);
