import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';

import { ThemeProvider } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { createMuiTheme } from "@material-ui/core";

const RootWithTheme = () => {
	const preferedMode = useMediaQuery('(prefers-color-scheme: dark)');
	const [darkMode, setDarkMode] = useState( preferedMode );
	const [firstDisplay, setFirstDisplay] = useState(false);
	const timer = 60 * 5 * 1000; //5min in ms (s * nbMin * ms)
	const theme = useMemo(
		() =>
			createMuiTheme({
				typography: {
					fontFamily: ["Montserrat Black", "Montserrat Bold", "Montserrat Classique"].join(','),
					fontSize: 16,
				},
				palette: {
					type: darkMode ? 'dark' : 'light',
					primary: {
						main: '#1B998B',
						contrastText: '#373e46',
					},
					secondary: {
						main: '#e80d23'
					}
				},
			}),
		[ darkMode ]
	);
	useEffect(() => {
		if ((darkMode !== preferedMode) && !firstDisplay) {
			setDarkMode(preferedMode);
			setFirstDisplay(true);
		}
	}, [ darkMode, preferedMode, firstDisplay ]);

	return (
		<ThemeProvider theme={ theme }>
			<App
				darkMode={ { state: darkMode, set: setDarkMode } }
				versions={ { env: process.env.NODE_ENV, initial: process.env.REACT_APP_VERSION, delay: timer } }
			/>
		</ThemeProvider>
	);
};

ReactDOM.render(<RootWithTheme />, document.getElementById('root'));
