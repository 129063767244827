import React from 'react';

export default () => {

	return (
		<div className='Home'>
			<h1>Bienvenue</h1>
		</div>
	);
};
