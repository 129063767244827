import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
	Paper,
	Container,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Toolbar,
	Typography,
	Grid
} from '@material-ui/core';

import './Participants.scss';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from "./UniqueTableHead";
import API from './API';

export default ({ match }) => {
	const [ state, change ] = useState("INIT");
	const [ bookings, changeBookings ] = useState(null);
	const [ watchers, changeWatchers ] = useState(null);
	const [ query, changeQuery ] = useState({
		seanceId: match.params.seanceId
	});

	useEffect(() => {
		if (['PENDING', 'OK'].indexOf(state) >= 0) return;
		change("PENDING");
		API.booking.search(query)
		.then(results => {
			changeBookings(results);
			return API.watcher.search(query);
		})
		.then( watchers => changeWatchers(watchers) )
		.then( () => change("OK") )
		.catch(err => {
			console.error(err);
		});
	}, [ state, query ]);

	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change("PENDING");
		changeBookings(null);
		changeWatchers(null);
	};
	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changeBookings(null);
		changeWatchers(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changeBookings(null);
		changeWatchers(null);
	};

	return <Container className='Participants'>
		{ state === "PENDING" && <LoadingCircle /> }
		{ state.step === "ERROR" && <LoadingCircle error={ state.error }/> }
		{ state === "OK" && <BookingsList
			bookings={ bookings }
			onRequestSort={ handleRequestSort }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }
		{ state === "OK" && <WatchersList
			watchers={ watchers }
			onRequestSort={ handleRequestSort }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }

	</Container>;
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'email', disablePadding: false, label: 'Email', disableSort: true },
		{ id: 'firstname', disablePadding: false, label: 'Prénom', disableSort: true },
		{ id: 'lastname', disablePadding: false, label: 'Nom', disableSort: true },
		{ id: 'creation.date', disablePadding: false, label: 'Réservé le', disableSort: true },
		{ id: 'state', disablePadding: false, label: 'Status', disableSort: true },
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const BookingsList = ({ bookings, ...props }) => {
	if (!bookings) return null;

	const filterByPresence = (a, b) => {
		if (a.canceled || a.deleted) return 2;
		if (a.presence.value > b.presence.value) return -1;
		else if (a.presence.value < b.presence.value) return 1;
		else return 0;
	}
	let counter = {
		done: bookings.filter(b => b.presence.value === 1).length,
		abscent: bookings.filter(b => b.presence.value === 0).length,
		canceled: bookings.filter(b => !!b.canceled).length,
		reserve: bookings.length - bookings.filter(b => !!b.canceled).length
	};

	let sessionDetail = '';
	if (!!bookings[0])
		sessionDetail = `pour la séance "${bookings[0].seance.name}" du ${moment(bookings[0].starts).format("YYYY-MM-DD HH:mm")}`;

	return (<TableContainer component={Paper}>
		<Toolbar>
			<Typography variant="h6" id="tableTitle" component="div">
				Liste des reservations <em>{sessionDetail}</em>
			</Typography>
		</Toolbar>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			{ bookings.length > 0 && <EnhancedTableHead {...props} /> }
			<TableBody>
				{ bookings.length <= 0 &&
				<TableRow hover={true} >
					<TableCell align="center">Aucune reservation</TableCell>
				</TableRow>
				}
				{ bookings.length > 0 && bookings.sort(filterByPresence).map(booking => (
					<TableRow key={booking.id} hover={true} >
						<TableCell align="center">
							<Link to={`/users/${booking.user.id}`}>{booking.user.email}</Link>
						</TableCell>
						<TableCell align="center">{ booking.user.firstname }</TableCell>
						<TableCell align="center">{ booking.user.lastname }</TableCell>
						<TableCell align="center">{ moment(booking.creation.date).format('YYYY-MM-DD HH:mm') }</TableCell>
						<TableCell align="center">{
							!!booking.canceled ? "Annuler" :
								moment(booking.starts) > moment() ?
									"Réserver" : !!booking.presence.value ? "Effectuer" :
									"Absent"
						}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		{ bookings.length > 0 && <CounterBooking bookings={bookings} counter={counter} /> }
	</TableContainer>);
};

const CounterBooking = ({ bookings, counter }) => {
	const isPast = moment(bookings[0].starts).valueOf() < moment().valueOf();

	return (<Grid container alignItems="center" direction="row" justify="space-around">
		{ isPast ? <Typography variant="subtitle1" component="div">
				{counter.done} présent{counter.done > 1 ? "s" : ""}
			</Typography> :
			<Typography variant="subtitle1" component="div">
				{counter.reserve} réservation{counter.reserve > 1 ? "s" : ""}
			</Typography>
		}
		<Typography variant="subtitle1" component="div">
			{counter.abscent} abscent{counter.abscent > 1 ? "s" : ""}
		</Typography>
		<Typography variant="subtitle1" component="div">
			{counter.canceled} annulation{counter.canceled > 1 ? "s" : ""}
		</Typography>
	</Grid>)
}

const WatchersList = ({ watchers, ...props }) => {
	if (!watchers) return null;

	const filterByCanceled = (a, b) => {
		if (a.canceled > b.canceled) return -1;
		else if (a.canceled < b.canceled) return 1;
		else return 0;
	}
	let counter = {
		done: watchers.filter(w => !w.canceled).length,
		canceled: watchers.filter(w => !!w.canceled).length
	};
	let sessionDetail = '';
	if (!!watchers[0])
		sessionDetail = `pour la séance "${watchers[0].seance.name}" du ${moment(watchers[0].seance.start).format("YYYY-MM-DD HH:mm")}`;

	return <TableContainer component={Paper}>
		<Toolbar>
			<Typography variant="h6" id="tableTitle" component="div">
				Liste de notifications <em>{sessionDetail}</em>
			</Typography>
		</Toolbar>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			{ watchers.length > 0 && <EnhancedTableHead {...props} /> }
			<TableBody>
				{ watchers.length <= 0 &&
				<TableRow hover={true} >
					<TableCell align="center">Aucune demande de notification</TableCell>
				</TableRow>
				}
				{ watchers.length > 0 && watchers.sort(filterByCanceled).map(watcher => (
					<TableRow key={watcher.id} hover={true} >
						<TableCell align="center">
							<Link to={`/users/${watcher.user.id}`}>{watcher.user.id}</Link>
						</TableCell>
						<TableCell align="center">{ watcher.user.firstname }</TableCell>
						<TableCell align="center">{ watcher.user.lastname }</TableCell>
						<TableCell align="center">{ moment(watcher.creation.date).format('YYYY-MM-DD HH:mm') }</TableCell>
						<TableCell align="center">{
							!!watcher.canceled ? "Annuler" : moment(watcher.seance.start) > moment() ? "En attente" : "Séance passé"
						}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<Grid container alignItems="center" direction="row" justify="space-around">
			<Typography variant="subtitle1" component="div">
				{counter.done} demande{counter.done > 1 ? "s" : ""} de notification
			</Typography>
			<Typography variant="subtitle1" component="div">
				{counter.canceled} annulation{counter.canceled > 1 ? "s" : ""} des notifications
			</Typography>
		</Grid>
	</TableContainer>;
};
