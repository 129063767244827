import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
	Container,
	Button,
	Select,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	TableContainer,
	Paper,
	Table,
	TableBody,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Input,
	Chip,
	FormControlLabel,
	Checkbox,
	MenuItem,
	FormControl,
	InputLabel,
	TextField,
	useTheme
} from '@material-ui/core';
import {
	Autocomplete
} from '@material-ui/lab';

import LoadingCircle from './LoadingCircle';
import './Planning.scss';
import API from './API';
import TextEditor from "./TextEditor";
//Warning, order matters !
const recurrencesMap = ['Jamais', 'Tous les jours', 'Du lundi au vendredi', 'Toutes les semaines', 'Toutes les 2 semaines'];
const typeSeances = [
	{ id: 'all', name: 'Tous type', corporate: null, restricted: null },
	{ id: 1, name: 'Grand Public', corporate: false, restricted: false },
	{ id: 2, name: 'Corporate sans restriction', corporate: true, restricted: false },
	{ id: 3, name: 'Corporate avec restriction', corporate: true, restricted: true }
];

// Code from https://stackoverflow.com/a/13896633/13128649
function parseQuery(str) {
	if (typeof str != "string" || str.length === 0) return {};
	const s = str.split("&");
	const s_length = s.length;
	let bit, query = {}, first, second;
	for (let i = 0; i < s_length; i++) {
		bit = s[i].split("=");
		first = decodeURIComponent(bit[0]);
		if(first.length === 0) continue;
		second = decodeURIComponent(bit[1]);
		if(typeof query[first] == "undefined") query[first] = second;
		else if(query[first] instanceof Array) query[first].push(second);
		else query[first] = [query[first], second];
	}
	return query;
}

export default ({ location }) => {
	const urlQuery = parseQuery(location.search.slice(1));
	const [ state, change ] = useState("INIT");
	const [ places, changePlaces ] = useState(null);
	const [ coaches, changeCoaches ] = useState(null);
	const [ classes, changeClasses ] = useState(null);
	const [ corporates, changeCorp ] = useState(null);
	const [ seances, changeSeance ] = useState(null);
	const [ query, changeQuery ] = useState({
		place: !isNaN(parseInt(urlQuery.place)) ? urlQuery.place : "all",
		classe: !isNaN(parseInt(urlQuery.classe)) ? urlQuery.classe : "all",
		corp: "all",
		today: moment(urlQuery.date).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
		start_date: null
	});

	const loadSeance = useCallback((params) => {
		change("PENDING");
		const typeSeance = typeSeances.find(ts => ts.id === params.corp);
		const start = params.start_date ? params.start_date : params.today;
		return API.seance.search({
			'place.id': params.place !== 'all' ? params.place : null,
			'class.id': params.classe !== 'all' ? params.classe : null,
			'corporate': typeSeance.corporate,
			'restricted': typeSeance.restricted,
			'starts.from': start,
			'starts.to': moment(start).add(7, 'days').format('YYYY-MM-DD'),
			deleted: false,
			page: 0,
			resultPerPage: 500,
			order: 'starts-asc'
		})
		.then(results => {
			results.values.forEach(s => {
				s["corporate"] = !!s.corporate ? { ...s.corporate, enabled: true } : {
					enabled: false,
					id: 'null',
					restricted: false
				};
				s['day'] = moment(s.starts).day() > 0 ? moment(s.starts).day() - 1 : 6;
				s['hours'] = moment(s.starts).hours();
			});
			return results.values;
		})
		.then( values => {
			if (!urlQuery.seanceId) {
				changeSeance( values );
			} else {
				changeSeance( values.filter(s => s.id === parseInt(urlQuery.seanceId)) );
			}
		}).then( () => change("OK") );
	}, [ urlQuery ]);
	const loadCorp = useCallback(() => {
		return API.corporate.search({
			page: 0,
			order: 'name-asc'
		})
		.then(results => {
			changeCorp( results.values );
		});
	}, [ ]);
	const loadClass = useCallback(() => {
		return API.classe.search({
			page: 0,
			state: 'published',
			orderBy: 'title',
			order: 'asc'
		})
		.then(results => {
			changeClasses([
				{ id: "all", name: "Tous les cours"},
				...results.values
			]);
		});
	}, [ ]);
	const loadCoach = useCallback(() => {
		const withOutHiddenCoach = (c) => {
			return !!c.coach.urbanEnabled && !!c.coach.urbanCode && c;
		};
		return API.user.search({ page: 0, maxPage: 1, resultPerPage: 500, coach: true })
		.then(results => {
			changeCoaches( results.values.filter(withOutHiddenCoach) );
		});
	}, [ ]);
	const loadPlace = useCallback(() => {
		change("PENDING");
		return API.place.search({
			page: 1,
			resultPerPage: 500,
			status: 'published',
			order: 'asc', orderBy: 'title'
		})
		.then(results => {
			changePlaces( [
				{ id: "all", title: "Tous les lieux"},
				...results.values
			] );
		});
	}, [ ]);

	const getFirstDayOfWeek = () => {
		if (query.start_date) return;
		let dayNb = ((7 + moment(query.today).day() - 1) % 7);
		const realQuery = {
			...query,
			start_date: moment(query.today).subtract(dayNb, 'days').format('YYYY-MM-DD'),
		};
		changeQuery(realQuery);
		if (state === "INIT") {
			loadPlace()
			.then(loadClass)
			.then(loadCoach)
			.then(loadCorp)
			.then(() => loadSeance(realQuery))
			.catch(console.error);
		}
	};
	useEffect(getFirstDayOfWeek, [ state, query ]);

	const search = (e) => {
		!!e && !!e.preventDefault && e.preventDefault();
	};
	const handleTypeChange = (e) => {
		const newQuery = { ...query, corp: e.target.value };
		changeQuery(newQuery);
		loadSeance(newQuery).catch(console.error);
	};
	const handleClassChange = (e, newClasse) => {
		const newQuery = { ...query, classe: !!newClasse && newClasse.id ? newClasse.id : "all" };
		changeQuery(newQuery);
		loadSeance(newQuery).catch(console.error);
	};
	const handlePlaceChange = (e, newPlace) => {
		const newQuery = { ...query, place: !!newPlace && newPlace.id ? newPlace.id : "all" };
		changeQuery(newQuery);
		loadSeance(newQuery).catch(console.error);
	};
	const prevWeek = (e) => {
		e.preventDefault();
		const newQuery = {
			...query,
			start_date: moment(query.start_date).subtract(7, 'days').format('YYYY-MM-DD')
		};
		changeQuery(newQuery);
		loadSeance(newQuery).catch(console.error);
	};
	const nextWeek = (e) => {
		e.preventDefault();
		const newQuery = {
			...query,
			start_date: moment(query.start_date).add(7, 'days').format('YYYY-MM-DD')
		};
		changeQuery(newQuery);
		loadSeance(newQuery).catch(console.error);
	};
	const placeSelected = (params, place) => {
		if (`${params.id}` === `${place}`) return params.title;
	};
	const placeLabel = (params) => {
		if (typeof params !== "object") {
			let tmp = places.find( f => `${f.id}` === `${params}` );
			return tmp.title;
		}
		return params.title;
	};
	const classeSelected = (params, classe) => {
		if (`${params.id}` === `${classe}`) return params.name;
	};
	const classeLabel = (params) => {
		if (typeof params !== "object") {
			let tmp = classes.find( f => `${f.id}` === `${params}` );
			return tmp.name;
		}
		return params.name;
	};

	return <Container className='Planning'>
		<form onSubmit={ search } noValidate autoComplete="off">
			<Grid container spacing={1} direction="row" justify="space-evenly" alignItems="flex-start">
				<Grid item md={1} className="ButtonField">
					<Button
						variant="contained"
						color="primary"
						onClick={prevWeek}
					>Précédent</Button>
				</Grid>
				<Grid item md={3}>
					{!!classes && <Autocomplete
						value={ query.classe }
						onChange={ handleClassChange }
						getOptionSelected={ classeSelected }
						limitTags={1}
						id="filter-by-classes"
						options={ classes }
						getOptionLabel={ classeLabel }
						renderInput={ (params) => <TextField {...params} variant="outlined"/> }
					/>}
				</Grid>
				<Grid item md={3}>
					{!!places && <Autocomplete
						value={ query.place }
						onChange={ handlePlaceChange }
						getOptionSelected={ placeSelected }
						limitTags={1}
						id="filter-by-place"
						options={ places }
						getOptionLabel={ placeLabel }
						renderInput={ (params) => <TextField {...params} variant="outlined"/> }
					/>}
				</Grid>
				<Grid item md={3}>
					<Select
						variant="outlined"
						fullWidth
						className="SearchField"
						value={ query.corp }
						onChange={ handleTypeChange }>
						{ typeSeances.map(t => <MenuItem key={'typeSeance_' + t.id} value={t.id}>{t.name}</MenuItem>) }
					</Select>
				</Grid>
				<Grid item md={1} className="ButtonField">
					<Button
						variant="contained"
						color="primary"
						onClick={nextWeek}
					>Suivant</Button>
				</Grid>
			</Grid>
		</form>

		{ state === "PENDING" && <LoadingCircle /> }
		{ state === "OK" &&
		<CalendarView
			seances={ seances }
			classes={ classes }
			coaches={ coaches }
			places={ places }
			corporates={ corporates }
			query={ query } refresh={ () => loadSeance(query) } />
		}
	</Container>;
};

const EnhancedTableHead = ({ setEaster, ...props}) => {
	const makeDateHead = (nbDay) => {
		const formatDate = 'ddd DD MMM';
		return moment(props.query.start_date).locale('fr').add(nbDay,'days').format(formatDate);
	};
	const headCells = [
		{ id: '0', disablePadding: false, label: '', disableSort: true },
		{ id: '1', disablePadding: false, label: makeDateHead(0), disableSort: true },
		{ id: '2', disablePadding: false, label: makeDateHead(1), disableSort: true },
		{ id: '3', disablePadding: false, label: makeDateHead(2), disableSort: true },
		{ id: '4', disablePadding: false, label: makeDateHead(3), disableSort: true },
		{ id: '5', disablePadding: false, label: makeDateHead(4), disableSort: true },
		{ id: '6', disablePadding: false, label: makeDateHead(5), disableSort: true },
		{ id: '7', disablePadding: false, label: makeDateHead(6), disableSort: true }
	];

	return (
		<TableHead>
			<TableRow>
				{ headCells.map(headCell => (
					<TableCell key={headCell.id} align='center'>
						<TableSortLabel disabled={headCell.disableSort}>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const CalendarView = ({ seances, ...props }) => {
	if (!seances) return null;

	const dayStart = 7; // day start at X hours in 24h
	const displayHour = 15; // one per hours to display

	const makeWeek = () => {
		const myWeek = [];
		for (let h = 0; h < displayHour; h++) {
			myWeek[h] = myWeek[h] || [];
			for (let j = 0; j < 7; j++) {
				myWeek[h][j] = myWeek[h][j] || {
					seances: seances.filter(s => (s.hours === (h + dayStart) && s.day === j) ),
				};
			}
		}
		return myWeek;
	};

	return <TableContainer component={Paper}>
		<Table style={{minWidth: '375'}} size="small" aria-label="Planning list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{makeWeek().map((hour, i) => {
					return (
						<TableRow key={i} >
							<TableCell align="center"><em>{i + dayStart}h</em></TableCell>
							{ hour.map( (day, j) =>
								<CalendarTile key={j}
															tileMoment={{ start: props.query.start_date, hours: i + dayStart, day: j }}
															seances={day.seances} { ...props } refresh={props.refresh} />
							)}
						</TableRow>
					)
				})}
			</TableBody>
		</Table>
	</TableContainer>;
};

const CalendarTile = ({tileMoment, seances, classes, coaches, places, corporates, ...props}) => {
	const basicSeance = {
		id: 'new',
		name: '',
		video_link: '',
		replay_video_link: '',
		event: 0,
		public: 1,
		only_paid: 0,
		recurrence: 0,
		warn_after_xbooking: 12,
		starts: moment(tileMoment.start).add(tileMoment.hours, 'hours')
		.add(tileMoment.day, 'days').format('YYYY-MM-DD HH:00:00'),
		ends: moment(tileMoment.start).add(tileMoment.hours, 'hours').add(tileMoment.day, 'days')
		.add(60, 'minutes').format('YYYY-MM-DD HH:mm:00'),
		place: { id: props.query.place !== 'all' ? props.query.place : 0 },
		class: { id: 0 },
		coaches: [],
		ucPrice: 0,
		booking_price: 0,
		practical_informations: '',
		description: '',
		capacity: { total: 15 },
		restrict_booking_X_min_before_start: 0,
		bookings: { zippy: 0 },
		watcher: { current: 0, warn_after_xwatcher: 3 },
		corporate: { enabled: false, id: 'null', restricted: false },
		noTransportPaid: 0
	};
	const [open, setOpen] = useState(false);
	const theme = useTheme();
	const [seancesHours, setSeancesHours] = useState(null);
	const [selected, setSelected] = useState(null);
	const [update, setUpdate] = useState({});
	const [body, setBody] = useState(null);
	const [error, setError] = useState(null);

	const handleClickOpenUpdate = (seances, e) => {
		setOpen(true);
		setSeancesHours(seances);
		setSelected(seances[0]);
	};

	const handleClickOpenCreate = (seances, e) => {
		setOpen(true);
		setSeancesHours(seances);
		setSelected(basicSeance);
	};

	const handleClose = () => {
		if (Object.keys(update).length > 0 && !window.confirm("Etes vous sûr ? (Des changements n'ont pas été appliquer)"))
			return ;
		props.refresh();
	};

	const changeSelected = (e) => {
		e.preventDefault();
		if (Object.keys(update).length > 0 && !window.confirm("Etes vous sûr ? (Des changements n'ont pas été appliquer)"))
			return ;

		if (e.target.value === 'new') setSelected( basicSeance );
		else setSelected( seancesHours.find(f => f.id === e.target.value) );

		setUpdate({});
	};

	const handleCoach = (e) => {
		let tmp = selected.coaches;
		let present = tmp.findIndex(u => u.id === `${e.target.value[selected.coaches.length]}`);
		if (present >= 0) {
			tmp = tmp.filter(u => u.id !== `${e.target.value[selected.coaches.length]}`);
		} else {
			tmp.push({ id: `${e.target.value[selected.coaches.length]}` });
		}
		setSelected({ ...selected, coaches: tmp });
		setUpdate({ ...update, id: selected.id, coaches: tmp });
	};

	const handleDateChange = (e) => {
		e.preventDefault();
		let tmp = { id: selected.id };
		let entry = e.target.value.split(':');
		if ( e.target.name === 'start') {
			tmp['starts'] = moment(selected.starts)
			.set('hour', entry[0]).set('minute', entry[1])
			.format('YYYY-MM-DD HH:mm:00');
		} else if (e.target.name === 'end') {
			tmp['ends'] = moment(selected.ends)
			.set('hour', entry[0]).set('minute', entry[1])
			.format('YYYY-MM-DD HH:mm:00');
		}
		setSelected({ ...selected, ...tmp });
		setUpdate({ ...update, ...tmp });
	};

	const handleChange = (e) => {
		let tmp = { id: selected.id };
		if (e.target.name.indexOf('.') >= 0) {
			const targetSplit = e.target.name.split('.');
			tmp[targetSplit[0]] = update[targetSplit[0]] || { ...selected[targetSplit[0]] };
			tmp[targetSplit[0]][targetSplit[1]] = e.target.value || e.target.checked;
			if (targetSplit[0] === 'corporate' && targetSplit[1] === 'enabled' && !e.target.checked) {
				tmp[targetSplit[0]] = basicSeance.corporate;
			}
		} else {
			if (e.target.name === 'uc_price' || e.target.name === 'booking_price') tmp[e.target.name] = e.target.value * 100 || 0;
			else tmp[e.target.name] = e.target.value || e.target.checked || '';
		}
		setSelected({ ...selected, ...tmp });
		setUpdate({ ...update, ...tmp });
	};

	const sortByCode = (a, b) => {
		if (a.coach.urbanCode < b.coach.urbanCode)
			return -1;
		if (a.coach.urbanCode > b.coach.urbanCode)
			return 1;
		return 0;
	};

	const save = (e) => {
		e.preventDefault();
		let body = {
			id: update.id !== 'new' ? update.id : null
		};
		if (update.name !== 'undefined') body['name'] = update.name;
		if (update.practical_informations !== 'undefined') body['practical_informations'] = update.practical_informations;
		if (update.description !== 'undefined') body['description'] = update.description;
		if (update.starts) body['starts'] = moment(update.starts).valueOf();
		if (update.ends) body['ends'] = moment(update.ends).valueOf();
		if (update.class && update.class.id !== 0) body['class'] = update.class;
		if (update.place && update.place.id !== 0) body['place_id'] = update.place.id;
		if (update.capacity && update.capacity.total) body['capacity'] = update.capacity;
		if (update.restrict_booking_X_min_before_start !== 'undefined') body['restrict_booking_X_min_before_start'] = update.restrict_booking_X_min_before_start;
		if (update.coaches !== 'undefined') body['coaches'] = update.coaches;
		if (update.event !== 'undefined') body['event'] = update.event;
		if (update.uc_price !== 'undefined') body['ucPrice'] = update.uc_price;
		if (update.public !== 'undefined') body['public'] = update.public;
		if (update.noTransportPaid !== 'undefined') body['noTransportPaid'] = update.noTransportPaid;
		if (update.stage !== 'undefined') body['stage'] = update.stage;
		if (update.only_paid !== 'undefined') body['only_paid'] = update.only_paid;
		if (update.video_link !== 'undefined') body['video_link'] = update.video_link;
		if (update.replay_video_link !== 'undefined') body['replay_video_link'] = update.replay_video_link;
		if (update.booking_price !== 'undefined') body['booking_price'] = update.booking_price;
		if (update.warn_after_xbooking) body['warn_after_xbooking'] = update.warn_after_xbooking;
		if (update.watcher && update.watcher.warn_after_xwatcher) body['warn_after_xwatcher'] = update.watcher.warn_after_xwatcher;
		if (update.corporate) {
			if (update.corporate.enabled && update.corporate.id !== null) body['corporate'] = update.corporate;
			else body['corporate'] = null;
		}
		if (update.canceled !== 'undefined') {
			body['canceled'] = update.canceled;
			body['deleted'] = update.canceled;
		}
		// if (update.recurrence) body['recurrence'] = update.recurrence; // can't change recurrence after set

		if (body.corporate && body.corporate.enabled && body.corporate.id === "null") return makeErrorMessage({ restCode:'paramsValidation', errors: { corporate: null }}, setError);

		if (update.id !== 'new') {
			if ( (selected.bookings.zippy > 0 || selected.bookings.total > 0) && !!update.canceled ) {
				if (!window.confirm("ATTENTION, vous êtes en train de supprimer une séance avec des adhérents inscrits dessus. Souhaitez vous continuer ?")) {
					props.refresh();
					return setOpen(false);
				}
			}
			setBody(body);
		} else {
			// create
			API.seance.create({
				...body,
				coaches: body.coaches || [],
				starts: body.starts ? body.starts : moment(selected.starts).valueOf(),
				ends: body.ends ? body.ends : moment(selected.ends).valueOf(),
				place_id: body.place_id ? body.place_id : null,
				class: body.class ? body.class : { id: null },
				recurrence: update.recurrence || selected.recurrence,
				capacity: body.capacity || selected.capacity,
				restrict_booking_X_min_before_start: body.restrict_booking_X_min_before_start || selected.restrict_booking_X_min_before_start || 0,
				public: body.public || selected.public,
				noTransportPaid: body.noTransportPaid || selected.noTransportPaid,
				stage: body.stage || selected.stage,
			})
			.then(() => {
				props.refresh();
				return setOpen(false);
			}).catch( err => {
				makeErrorMessage(err, setError);
			});
		}
	};

	const isSelected = (coach, coachList, theme) => {
		const foundIndex = coachList.findIndex(s => parseInt(s.id) === coach.id );
		return {
			fontWeight:
				foundIndex >= 0
					? theme.typography.fontWeightBold
					: theme.typography.fontWeightLight,
			color: foundIndex >= 0 ? theme.palette.primary.main : ''
		};
	};

	const handleClassChange = (e, newData) => {
		let tmp = { id: selected.id };
		tmp['class'] = update['class'] || { ...selected['class'] };
		tmp['class']['id'] = !!newData && newData.id ? newData.id : null;
		setSelected({ ...selected, ...tmp });
		setUpdate({ ...update, ...tmp });
	};
	const classeSelected = (params, classe) => {
		if (`${params.id}` === `${classe}`) return params.name;
	};
	const classeLabel = (params) => {
		if (typeof params !== "object") {
			let tmp = classes.find( f => `${f.id}` === `${params}` );
			if (tmp) return tmp.name;
			else return "Choisir un cours";
		}
		return params.name;
	};
	const handlePlaceChange = (e, newData) => {
		let tmp = { id: selected.id };
		tmp['place'] = update['place'] || { ...selected['place'] };
		tmp['place']['id'] = !!newData && newData.id ? newData.id : null;
		setSelected({ ...selected, ...tmp });
		setUpdate({ ...update, ...tmp });
	};
	const placeSelected = (params, classe) => {
		if (`${params.id}` === `${classe}`) return params.title;
	};
	const placeLabel = (params) => {
		if (typeof params !== "object") {
			let tmp = places.find( f => `${f.id}` === `${params}` );
			if (tmp) return tmp.title;
			else return "Choisir un lieu";
		}
		return params.title;
	};
	const clearBody = () => {
		setError(null);
		setBody(null);
	};

	return (
		<TableCell align="center">
			<div className="SeanceList">
				<Button
					className="Seances"
					size="small"
					variant={ seances.length ? 'contained' : 'outlined' }
					color={
						seances.length === 1 ?
						seances[0].public ? 'primary' : 'secondary'
							: seances.length ? 'primary' : 'default'
					}
					onClick={ seances.length ? handleClickOpenUpdate.bind(this, seances) : handleClickOpenCreate.bind(this, seances) }
				>{
					seances.length > 1 ? `${seances.length} séances` :
						seances.length ? `${!!seances[0].name ? seances[0].name : seances[0].class.name }` :
							''
				}</Button>

				{ !!error && <ErrorModal error={error} clearError={ clearBody } {...props} />}
				{ !!body && <RecurrenceModal body={body} selected={selected} setBody={setBody} {...props} />}

				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth maxWidth="md"
					aria-labelledby="form-dialog-title">
					<div className="SeanceModal">
						<DialogTitle id="form-dialog-title">
							<Grid container spacing={1} direction="row" justify="space-evenly" alignItems="flex-start">
								<Grid item md={8}>
									Séances du {!!selected && moment(selected.starts).format('ddd DD MMMM HH:mm')}
								</Grid>
								<Grid item md={4}>
									{ (!!seancesHours && seancesHours.length > 1) &&
									<FormControl style={{ width: '20rem'}}>
										<InputLabel shrink id="seance-select-planning">{selected.id !== 'new' ? "Modication de" : "Création d'une" }</InputLabel>
										<Select
											labelId="seance-select-planning"
											className="SearchField"
											value={ selected.id }
											onChange={ changeSelected }>
											<MenuItem value="new">Nouvelle séance</MenuItem>
											{ seancesHours.map(s => <MenuItem key={s.id} value={s.id}>{s.name || s.class.name} de {moment(s.starts).format("HH:mm")} {(s.corporate && s.corporate.enabled) ? '©':''}</MenuItem>) }
										</Select>
									</FormControl>
									}
								</Grid>
							</Grid>
						</DialogTitle>
						<DialogContent>

							{!!selected && <Container maxWidth="lg">
								<Grid container spacing={2} direction="row" justify="center" alignItems="center">
									{/* First column */}
									<Grid item md={5}>
										<Grid container spacing={2} direction="column" justify="flex-start" alignItems="flex-start">
											<Grid item md={12}>
												<FormControl style={{ width: '20rem'}}>
													<TextField
														fullWidth
														label="Nom"
														type="text"
														name="name"
														onChange={ handleChange }
														value={ selected.name || '' }
													/>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												{/* Choose Classe */}
												<Autocomplete
													value={ selected.class.id }
													onChange={ handleClassChange }
													getOptionSelected={ classeSelected }
													limitTags={1}
													id="choose-by-classe"
													style={{ width: 320 }}
													options={ classes.slice(1) }
													getOptionLabel={ classeLabel }
													renderInput={ (params) => <TextField {...params} /> }
												/>
											</Grid>
											<Grid item md={12}>
												{/* Choose Place */}
												<Autocomplete
													value={ selected.place.id }
													onChange={ handlePlaceChange }
													getOptionSelected={ placeSelected }
													limitTags={1}
													id="choose-by-place"
													style={{ width: 320 }}
													options={ places.slice(1) }
													getOptionLabel={ placeLabel }
													renderInput={ (params) => <TextField {...params} /> }
												/>
											</Grid>
											<Grid item md={12}>
												{/* Choose Coach */}
												<FormControl style={{ width: '20rem'}}>
													<InputLabel id="seance-select-planning-coach">Coach</InputLabel>
													<Select
														labelId="seance-select-planning-coach"
														multiple
														name="coaches"
														value={
															selected.coaches.map(c => {
																let co = coaches.find(cc => cc.id === parseInt(c.id));
																if( !co ) return `not-a-coach-${c.id}`;
																return `${co.coach.urbanCode} ${co.firstname} ${co.lastname || ''}`;
															})
														}
														onChange={ handleCoach }
														input={ <Input id="select-multiple-chip" /> }
														renderValue={coachSelected => (
															<div>
																{!!coachSelected && coachSelected.map(value => (
																	<Chip key={value} label={value} />
																))}
															</div>
														)}
													>
														{coaches.filter(c => c.coach.urbanCode).sort(sortByCode).map(c => (
															<MenuItem
																key={c.id}
																value={c.id}
																style={ isSelected(c, selected.coaches, theme) }
															>{c.coach.urbanCode} {c.firstname} {c.lastname || ''}</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												{/* Choose Corp */}
												{(!!selected.corporate && selected.corporate.enabled) ?
												<FormControl style={{ width: '20rem'}}>
													<InputLabel shrink id="seance-select-planning-corporate">Corporate</InputLabel>
													<Select
														labelId="seance-select-planning-corporate"
														name="corporate.id"
														value={ selected.corporate.id }
														onChange={ handleChange }>
														<MenuItem value={'null'}>Choisir une entreprise</MenuItem>
														{ corporates.map(co => <MenuItem key={co.id} value={co.id}>{co.name}</MenuItem>) }
													</Select>
												</FormControl>
												: null }
											</Grid>
											<Grid item md={12}>
												{/* Put video link */}
												<FormControl style={{ width: '20rem'}}>
													<TextField
														fullWidth
														label="Lien video"
														name="video_link"
														inputProps={{ autoComplete: 'off' }}
														value={ selected.video_link }
														onChange={ handleChange }
													/>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												{/* Put replay video link */}
												<FormControl style={{ width: '20rem'}}>
													<TextField
														fullWidth
														label="Lien replay video"
														name="replay_video_link"
														inputProps={{ autoComplete: 'off' }}
														value={ selected.replay_video_link }
														onChange={ handleChange }
													/>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												{/* Choose price */}
												<FormControl style={{ width: '18rem'}}>
												<TextField
													label="Prix de la séance en €"
													type="number"
													name="booking_price"
													inputProps={{ min: 0, step: 1 }}
													onChange={ handleChange }
													value={ selected.booking_price / 100 || 0 }
												/>
											</FormControl>
											</Grid>
										</Grid>
									</Grid>

									{/* Second column */}
									<Grid item md={4}>
										<Grid container spacing={2} direction="column" justify="flex-start" alignItems="center">
											<Grid item md={12}>
												{/* Choose start time */}
												<FormControl style={{ width: '18rem'}}>
													<TextField
													fullWidth
													label="Début"
													type="time"
													name="start"
													onChange={ handleDateChange }
													value={ moment(selected.starts).format("HH:mm")}
													inputProps={{ step: 300 }}
												/>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												{/* Choose end time */}
												<FormControl style={{ width: '18rem'}}>
													<TextField
														label="Fin"
														type="time"
														name="end"
														onChange={ handleDateChange }
														value={ moment(selected.ends).format("HH:mm")}
														inputProps={{ step: 300 }}
													/>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												{/* Choose capacity max */}
												<FormControl style={{ width: '18rem'}}>
													<TextField
														label={`Capacité max (${selected.bookings.zippy}/${selected.capacity.total})`}
														type="number"
														name="capacity.total"
														onChange={ handleChange }
														value={ selected.capacity.total }
													/>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												<FormControl style={{ width: '18rem'}}>
													<TextField
														label={`Bloquer X minutes avant début`}
														type="number"
														name="restrict_booking_X_min_before_start"
														onChange={ handleChange }
														value={ selected.restrict_booking_X_min_before_start }
													/>
												</FormControl>
											</Grid>
											<Grid item md={12}>
												<Grid container spacing={1} direction="row" justify="space-around" alignItems="stretch">
													<Grid item md={6}>
														{/* Choose #booking before slack alert */}
														<FormControl fullWidth>
															<TextField
																label={`Booking Slack`}
																type="number"
																name="warn_after_xbooking"
																onChange={ handleChange }
																value={ selected.warn_after_xbooking }
															/>
														</FormControl>
													</Grid>
													<Grid item md={6}>
														{/* Choose #watcher before slack alert */}
														<FormControl fullWidth>
															<TextField
																label={`Watcher Slack:${selected.watcher.current}`}
																type="number"
																name="watcher.warn_after_xwatcher"
																onChange={ handleChange }
																value={ selected.watcher.warn_after_xwatcher }
															/>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											<Grid item md={12}>
												{/* Choose price */}
												<FormControl style={{ width: '18rem'}}>
													<TextField
														label="Prix du coach en €"
														type="number"
														name="uc_price"
														inputProps={{ min: 0, step: 1 }}
														onChange={ handleChange }
														value={ selected.uc_price / 100 || 0 }
													/>
												</FormControl>
												<FormControlLabel
													control={
														<Checkbox
															checked={!!selected.noTransportPaid}
															onChange={ handleChange }
															name="noTransportPaid"
															color="primary"
														/>
													}
													labelPlacement="start"
													label="Pas de frais de déplacement"
												/>
											</Grid>
											<Grid item md={12}>
												{/* Choose Recurrence */}
												<FormControl style={{ width: '18rem'}}>
													<InputLabel shrink id="seance-select-planning-recurrence">Récurrence</InputLabel>
													<Select
														labelId="seance-select-planning-recurrence"
														name="recurrence"
														value={ selected.recurrence }
														onChange={ handleChange }>
														{ recurrencesMap.map((r,i) => <MenuItem key={i} value={i}>{r}</MenuItem>) }
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>

									{/* Third column */}
									<Grid item md={3}>
										<Grid container spacing={2} direction="column" justify="space-evenly" alignItems="flex-end">
											<Grid item md={10}>
												{/* is public */}
												<FormControlLabel
													control={
														<Checkbox
															checked={!!selected.public}
															onChange={ handleChange }
															name="public"
															color="primary"
														/>
													}
													labelPlacement="start"
													label="Visible"
												/>
											</Grid>
											<Grid item md={10}>
												{/* is stage */}
												<FormControlLabel
													control={
														<Checkbox
															checked={!!selected.stage}
															onChange={ handleChange }
															name="stage"
															color="primary"
														/>
													}
													labelPlacement="start"
													label="Stage Only"
												/>
											</Grid>
											<Grid item md={10}>
												{/* is event */}
												<FormControlLabel
													control={
														<Checkbox
															checked={!!selected.event}
															onChange={ handleChange }
															name="event"
															color="primary"
														/>
													}
													labelPlacement="start"
													label="Event"
												/>
											</Grid>
											<Grid item md={10}>
												{/* is only_paid */}
												{ !!selected.event && <FormControlLabel
													control={
														<Checkbox
															checked={!!selected.only_paid}
															onChange={handleChange}
															name="only_paid"
															color="primary"
														/>
													}
													labelPlacement="start"
													label="Payant"
												/>}
											</Grid>
											<Grid item md={10}>
												{/* is corp */}
												<FormControlLabel
													control={
														<Checkbox
															checked={ selected.corporate.enabled }
															onChange={ handleChange }
															name="corporate.enabled"
															color="primary"
														/>
													}
													labelPlacement="start"
													label="Corporate"
												/>
											</Grid>
											<Grid item md={10}>
												{/* is corp restricted */}
												{(!!selected.corporate && selected.corporate.enabled) ?
												<FormControlLabel
													control={
														<Checkbox
															checked={ !!selected.corporate && !!selected.corporate.restricted }
															onChange={ handleChange }
															name="corporate.restricted"
															color="secondary"
														/>
													}
													labelPlacement="start"
													label="Restrict ©"
												/>
												: null }
											</Grid>
											<Grid item md={10}>
												{/* is canceled/deleted */}
												<FormControlLabel
													control={
														<Checkbox
															checked={ !!selected.canceled }
															onChange={ handleChange }
															name="canceled"
															color="secondary"
														/>
													}
													labelPlacement="start"
													label="Supprimer"
												/>
											</Grid>
										</Grid>
									</Grid>
									{ (!!selected && selected.id !== 'new') && <SeanceDetail seance={ selected } change={ handleChange } /> }
								</Grid>
							</Container>}

						</DialogContent>
						<DialogActions>
							{ !!selected && selected.id !== 'new' &&
							<Link to={`/participants/${selected.id}`} rel="noreferrer noopener" target="_blank">
								Voir les participants
							</Link>
							}
							{ !!selected && selected.id !== 'new' &&
								<a href={`https://www.urban-challenge.fr/seance/${selected.id}`} rel="noreferrer noopener" target="_blank">
									Voir la séance
								</a>
							}
							<Button style={{ textTransform: 'none' }} onClick={ handleClose } color="secondary">
								Annuler
							</Button>
							<Button style={{ textTransform: 'none' }} onClick={ save } color="primary" variant="outlined">
								{ (!!selected && selected.id !== 'new') ? "Sauvegarder" : "Créer" }
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		</TableCell>
	);
};

const SeanceDetail = ({ seance, change }) => {
	return (
		<Grid className={'SeanceDetail'} container spacing={2} direction={'row'} justify='space-around' alignItems="stretch">
			<Grid item md={6}>
				<TextEditor
					label={"Info Pratique"}
					value={ seance.practical_informations }
					fieldName="practical_informations"
					onChange={ change }
					modify={ false }
					hideCancel={ true }
				/>
			</Grid>
			<Grid item md={6}>
				<TextEditor
					label={"Description"}
					value={ seance.description }
					fieldName="description"
					onChange={ change }
					modify={ false }
					hideCancel={ true }
				/>
			</Grid>
		</Grid>
	);
};

const RecurrenceModal = ({ body, selected, setBody, ...props }) => {
	const [error, setError ] = useState(null);

	const updateSeance = (recurence) => {
		API.seance.edit(selected.id, {
			...body,
			apply_rec: recurence
		})
		.then(ret => {
			setBody(null);
			props.refresh();
		}).catch( err => {
			makeErrorMessage(err, setError);
		});
	};

	const clearBody = () => {
		setBody(null);
		setError(null);
	};

	if (error) {
		return <ErrorModal error={error} clearError={ clearBody }/>
	}

	if (selected.recurrence <= 0) {
		updateSeance(false);
		return null;
	}

	return (
		<div>
			{!!body && <Dialog open={true} fullWidth maxWidth="md">
				<DialogContent>
					Dois je appliquer les changements à toutes les récurrences ?
				</DialogContent>
				<DialogActions>
					<Button style={{ textTransform: 'none' }} onClick={ clearBody } color="secondary">
						Annuler
					</Button>
					<Button style={{ textTransform: 'none' }} onClick={updateSeance.bind(this, false)} color="primary"
									variant="outlined">
						Non, seulement cette séance
					</Button>
					<Button style={{ textTransform: 'none' }} onClick={updateSeance.bind(this, true)} color="primary"
									variant="outlined">
						Oui, toutes les séances
					</Button>
				</DialogActions>
			</Dialog> }
		</div>
	);
};
const ErrorModal = ({ error, clearError }) => {
	return (
		<div>
			<Dialog open={!!error} fullWidth maxWidth='sm'>
				<DialogContent>
					<h4>Une erreur est survenue !</h4>
					<p>{error.reason}</p>
					{ !!error.infos && <p>{error.infos}</p>}
				</DialogContent>
				<DialogActions>
					<Button style={{ textTransform: 'none' }} onClick={ clearError } color="secondary" variant="contained">
						Je corrige !
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
const makeErrorMessage = (err, setError) => {
	if (err.restCode === 'paramsValidation')
		setError({
			reason : "Des parametres sont manquants",
			infos: Object.keys(err.errors).map(e => getRealKeysName(e)).join(', ')
		});
	else if (err.restCode === 'internalServerError')
		setError({
			reason : `T'as cassé le serveur, appel un dev !`,
			infos: null
		});
	else
		console.error(err);
};
const getRealKeysName = (params) => {
	switch (params) {
		case 'place_id':
			return "Pas de lieu";
		case 'class.id':
			return "Pas de cours";
		case 'corporate':
			return "Pas d'entreprise";
		default:
			return params;
	}
};
