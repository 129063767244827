import React, { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import moment from "moment";
import {
	Grid,
	CircularProgress,
	Container,
	TextField,
	Paper,
	FormControlLabel,
	Switch,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import API from "./API";
import LoadingCircle from "./LoadingCircle";

export default ({ match }) => {
	const [ state, change ] = useState("INIT");
	const [ corporate, updateCorporate ] = useState(null);
	const [ newCorp, updateNewCorp ] = useState({});
	const [ modifiable, updateModifiable ] = useState(true);

	const loadCorporate = useCallback(() => {
		return API.corporate.get( match.params.corporateId )
		.then(corporate => {
			updateCorporate(corporate);
		})
		.then(() => change("OK") );
	}, [ match ]);

	useEffect(() => {
		if (corporate) return;
		change("LOADING");
		loadCorporate()
		.catch(console.error);
	}, [ loadCorporate, corporate ]);

	const applyUpdate = (data) => {
		return API.corporate.edit( match.params.corporateId, data)
		.then(() => {
			loadCorporate()
			.then(() => updateNewCorp({}))
			.then(() => updateModifiable(true))
			.catch(console.error);
		})
		.catch(err => {
			console.error(err);
		});
	};

	const canModify = (e) => {
		e.preventDefault();
		if (!modifiable && Object.keys(newCorp).length > 0) {
			applyUpdate(newCorp);
		}
		updateModifiable(!modifiable);
	};

	const handleChange = (e) => {
		newCorp[e.target.name] = e.target.value;
		if (e.target.name === 'on_map') newCorp[e.target.name] = !corporate.on_map;
		updateCorporate({ ...corporate, ...newCorp });
		updateNewCorp(newCorp);
	};
	const isModified = () => {
		return Object.keys(newCorp).length > 0;
	};

	return <Container style={{ padding: '2rem' }}>
		{ state === "LOADING" && <CircularProgress /> }
		{ state === "OK" && <Grid container spacing={2} direction="column" justify="space-around" alignItems="stretch">
			<Grid item md={12}>
				{ !!corporate &&
				<Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
					<Grid container spacing={1} direction={"row"} alignItems="center">
						<Grid
							item
							md={modifiable ? 10 : isModified() ? 9 : 10}
						>
							<TextField
								label="Nom"
								variant="outlined"
								name="name"
								disabled={ modifiable }
								defaultValue={ corporate.name }
								onChange={ handleChange }
								fullWidth />
						</Grid>
						<Grid
							item
							md={modifiable ? 2 : isModified() ? 3 : 2}
						>
							<FormControlLabel
								control={
									<Switch
										checked={ !modifiable }
										onChange={ canModify }
										name="public"
										color="primary"
									/>
								}
								labelPlacement="start"
								label={ modifiable ? "Bloquer" : isModified() ? "Sauvegarder les changements" : "Modifiable" }
							/>
						</Grid>
					</Grid>
				</Paper>
				}

			</Grid>
			<Grid item md={12}>
				{ !!corporate && <PromoCorpView corporate={ corporate } /> }
			</Grid>
		</Grid>
		}
	</Container>;
};

const PromoCorpView = ({ corporate, ...props }) => {
	const [ step, change ] = useState("INIT");
	const [ promo, setPromo ] = useState(null);
	const [ error, setError ] = useState(null);

	const loadPromoCode = useCallback(() => {
		return API.promo.search({
			corporate: corporate.id
		})
		.then(promo => {
			setPromo(promo.values);
		})
		.then(() => change("OK") );
	}, [ corporate ]);

	useEffect(() => {
		if (promo) return;
		change("LOADING");
		loadPromoCode()
		.catch(err => {
			change("ERROR");
			setError(err);
			console.error(err);
		});
	}, [ loadPromoCode, promo ]);

	if (step === "LOADING" || step === "INIT") return <LoadingCircle />;
	if (step === "ERROR") return <LoadingCircle error={error} />;

	return (
		<Grid container spacing={2} direction={"column"}>
			<Grid  item md={12}>
			{ promo.map(p =>
				<DetailCorpPromo key={p.id} promo={p} />
			)}
			</Grid>
		</Grid>
	);
};

const DetailCorpPromo = ({ promo }) => {
	const [ expanded, setExpanded ] = useState(null);
	const [ promoDetail, setPromoDetail ] = useState(null);
	const [ user, setUsers ] = useState(null);

	const loadAbos = useCallback(() => {
		return API.abo.search({
			promoId: promo.id,
			resultPerPage: 500
		})
		.then(abos => {
			return abos.values;
		});
	}, [ promo ]);

	const loadUsers = useCallback(() => {
		return API.user.search({
			promo: promo.id,
			resultPerPage: 500
		})
		.then(users => {
			return users.values;
		});
	}, [ promo ]);

	const loadPromo = useCallback(() => {
		return API.promo.get( promo.id )
		.then(pro => {
			setPromoDetail(pro);
		});
	}, [ promo ]);

	const load = useCallback(() => {
		return loadAbos()
		.then(abos => {
			return loadUsers()
			.then(users => {
				Object.values(abos).forEach(a => {
					const ind = users.findIndex(u => a.user.id === u.id);
					if (ind < 0) return;
					users[ind]['abo'] = a;
				});
				setUsers(users);
			});
		});
	}, [ loadUsers, loadAbos ]);

	const handleExpand = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : undefined);
	};
	const effect = (e) => {
		e.preventDefault();
		if (!!user && user.length > 0) return ;
		load()
		.catch(console.error);

		if (!promoDetail) {
			loadPromo()
			.catch(console.error);
		}
	};

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				expanded={ expanded }
				onChange={ handleExpand(promo.id) }
				onClick={ effect }
			>
				<Grid container direction="row" justify="space-around" alignItems="center">
					<Grid item md={9}>
						<Typography>{promo.code}</Typography>
					</Grid>
					<Grid item md={1}>
						<Typography>{
							promo.state === 'enabled' ? "Actif"
								: promo.state === 'used' ? "Utilisé"
									: promo.state === 'disabled' ? "Désactivé"
									: promo.state
						}</Typography>
					</Grid>
					<Grid item md={1}>
						<Typography>{
							promo.type === 'single' ? "Unique" : "Multiple"
						}</Typography>
					</Grid>
					<Grid item md={1}>
						<Typography>{
							promo.value ? `-${promo.value / 1000} €` : `${promo.percentage}%`
						}</Typography>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>

				<Table>
					<EnhancedTableHead />
					<TableBody>
						{!!user && user.filter(f=>f.abo.cancelation).map(u => {
							return (<UserDetail user={u} key={`canceled_`+u.id} />);
						})}

						{!!user && user.filter(f=>!f.abo.cancelation).map(u => {
							return (<UserDetail user={u} key={`active_`+u.id}/>);
						})}

					</TableBody>
				</Table>

			</AccordionDetails>
		</Accordion>
	);
};

const EnhancedTableHead = () => {
	const headCells = [
		{ id: 'lastname', disablePadding: false, label: 'Nom', disableSort: true },
		{ id: 'firstname', disablePadding: false, label: 'Prénom', disableSort: true },
		{ id: 'actualOffer', disablePadding: false, label: 'Offre', disableSort: true },
		{ id: 'paymentDate', disablePadding: false, label: "Date d'achat", disableSort: true },
		{ id: 'status', disablePadding: false, label: 'Status', disableSort: true },
		{ id: 'expectedEnds', disablePadding: false, label: 'Fin le', disableSort: true },
	];
	return (
		<TableHead>
			<TableRow>
				{ headCells.map(headCell => (
					<TableCell key={headCell.id} align='center' >
						<TableSortLabel disabled={headCell.disableSort} >
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const UserDetail = ({ user }) => {
	return (
		<TableRow>
			<TableCell align='center'><Link to={`/users/${user.id}`}>{user.lastname}</Link></TableCell>
			<TableCell align='center'>{user.firstname}</TableCell>
			<TableCell align='center'>{user.abo.offer.actual}</TableCell>
			<TableCell align='center'>{moment(user.abo.payment.date).format('YYYY-MM-DD HH:mm')}</TableCell>
			<TableCell align='center'>{user.abo.cancelation ? `Annulé` : "Actif"}</TableCell>
			<TableCell align='center'>
				{user.abo.cancelation ?
					`${moment(user.abo.cancelation.date).format('YYYY-MM-DD HH:mm')}` :
					`${moment(user.abo.expectedEnds).format('YYYY-MM-DD HH:mm')}`}
			</TableCell>
		</TableRow>
	);
};
