import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Select,
	Grid,
	MenuItem
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import './Users.scss';
import API from './API';
import UniqueTableHead from "./UniqueTableHead";

const roleUser = {
	'all': { value: 'all', name: 'Tous', admin: null, coach: null },
	'client': { value: 'client', name: 'Client', admin: false, coach: false },
	'coach': { value: 'coach', name: 'Coach', admin: false, coach: true },
	'admin': { value: 'admin', name: 'Admin', admin: true, coach: null }
};
const listType = [
	{ value: 'all', name: 'Tous' },
	{ value: 'prospect', name: 'Prospect' },
	{ value: 'actif', name: 'Actif' },
	{ value: 'ancien', name: 'Ancien' },
];

export default () => {
	const [ state, change ] = useState("INIT");
	const [ users, changeUsers ] = useState(null);
	const [ query, changeQuery ] = useState({
		search: null,
		promo: null,
		role: 'all',
		status: 'all',
		orderBy: 'creationDate',
		order: 'desc',
		page: 0,
		maxPage: 1,
		resultPerPage: 50,
		total: 0
	});

	useEffect(() => {
		if (users) return;
		change("PENDING");
		API.user.search({
			...query,
			admin: roleUser[query.role].admin,
			coach: roleUser[query.role].coach
		})
		.then(results => {
			changeUsers( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		})
		.then( () => change("OK") )
		.catch(console.error);

	}, [ users, query ]);

	const getRole = (user) => {
		return (user.admin && user.coach.enabled) ? "Admin & Coach" : ( user.admin ? "Admin" : (user.coach.enabled ? "Coach" : "Client") );
	};

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changeUsers(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change("PENDING");
		changeUsers(null);
	};
	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changeUsers(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changeUsers(null);
	};
	const handleSearchChange = (e) => {
		changeQuery({ ...query, search: e.target.value });
	};
	const handlePromoChange = (e) => {
		changeQuery({ ...query, promo: e.target.value });
	};
	const handleFilterStatusChange = (e) => {
		changeQuery({ ...query, status: e.target.value });
		changeUsers(null);
	};
	const handleFilterRoleChange = (e) => {
		changeQuery({ ...query, role: e.target.value });
		changeUsers(null);
	};

	const searchField = [
		{ md: 4, type: 'text', label: 'Recherche', placeholder: 'Nom / Prénom / Email / ID', defaultValue: query.search, onChange: handleSearchChange },
		{ md: 4, type: 'text', label: 'Promo', placeholder: 'Code / ID', defaultValue: query.promo, onChange: handlePromoChange },
		{ md: 2, type: 'select', label: 'Status', defaultValue: query.status, onChange: handleFilterStatusChange, choices: listType },
		{ md: 2, type: 'select', label: 'Role', defaultValue: query.role, onChange: handleFilterRoleChange, choices: Object.values(roleUser) },
	];

	return (
		<Container className='Users'>
			<Grid container spacing={1} direction="row" justify="space-evenly" alignItems="center">
				{ searchField.map( (s, i) => (
					<Grid item md={s.md} key={ "filter_" + i }>
						<form onSubmit={ search } noValidate autoComplete="off">
							{ s.type === 'text' && <TextField className="SearchField" placeholder={s.placeholder} label={s.label} defaultValue={ s.defaultValue } variant="outlined" onChange={ s.onChange } />}
							{ s.type === 'select' && <Select variant="outlined" className="SearchField" value={ s.defaultValue } onChange={ s.onChange }>
								{ s.choices.map((c, j) => <MenuItem key={c.value + j + c.label} value={c.value}>{c.name}</MenuItem>)}
							</Select>}
						</form>
					</Grid>
				) )}
			</Grid>

			{ state === "PENDING" && <LoadingCircle /> }
			{ state === "OK" && <UserList
				users={ users }
				getRole={ getRole }
				onRequestSort={ handleRequestSort }
				order={ query.order }
				orderBy={ query.orderBy }
				page={ query.page - 1 }
				total={ query.total }
				resultPerPage={ query.resultPerPage }
				changePage={ handleChangePage }
				changeRowsPerPage={ handleChangeRowsPerPage }
			/> }
		</Container>
	);
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'id', disablePadding: true, label: 'ID' },
		{ id: 'lastname', disablePadding: false, label: 'Nom' },
		{ id: 'firstname', disablePadding: false, label: 'Prénom' },
		{ id: 'email', disablePadding: false, label: 'Email' },
		{ id: 'creationDate', disablePadding: false, label: 'Date de création' },
		{ id: 'status', disablePadding: false, label: 'Status', disableSort: true },
		{ id: 'role', disablePadding: false, label: 'Role', disableSort: true }
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const UserList = ({ users, ...props }) => {
	if (!users) return null;
	const today = moment().format('YYYY-MM-DD');

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{users.map(user => (
					<TableRow key={user.id} hover={true} >
						<TableCell align="center">
							<Link to={`/users/${user.id}`}>{user.id}</Link>
						</TableCell>
						<TableCell align="center">{ user.lastname }</TableCell>
						<TableCell align="center">{ user.firstname }</TableCell>
						<TableCell align="center">{ user.email }</TableCell>
						<TableCell align="center">{ moment(user.creation.date).format('YYYY-MM-DD HH:mm') }</TableCell>
						<TableCell align="center">{ !user.subEndDate ? "Prospect" : moment(user.subEndDate).format('YYYY-MM-DD') <= today ? "Ancien" : "Actif"}</TableCell>
						<TableCell align="center">{ props.getRole(user) }</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};
