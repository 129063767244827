import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
	Grid,
	CircularProgress,
	Container,
	Paper,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from "@material-ui/core";

import API from "./API";

export default ({ match }) => {
	const [ state, change ] = useState("INIT");
	const [ promo, updatePromo ] = useState(null);
	const [ offers, updateOffers ] = useState(null);
	const [ corporates, updateCorporates ] = useState(null);
	const [ places, updatePlaces ] = useState(null);
	const [ classes, updateClasses ] = useState(null);
	const [ newPromo, updateNewPromo ] = useState({});
	const [ modifiable, updateModifiable ] = useState(true);

	const loadPromo = useCallback(() => {
		return API.promo.get( match.params.promoId )
		.then(response => {
			updatePromo(response);
		});
	}, [ match ]);

	const loadOffer = useCallback(() => {
		return API.offer.search({})
		.then(response => {
			updateOffers(response.values);
		});
	}, [ ]);
	const loadCorp = useCallback(() => {
		return API.corporate.search({})
		.then(response => {
			updateCorporates(response.values);
		});
	}, [ ]);
	const loadPlace = useCallback(() => {
		return API.place.search({})
		.then(response => {
			updatePlaces(response.values);
		});
	}, [ ]);
	const loadClasse = useCallback(() => {
		return API.classe.search({})
		.then(response => {
			updateClasses(response.values);
		});
	}, [ ]);

	useEffect(() => {
		if (promo) return;
		change("LOADING");
		Promise.all([
			loadPromo(),
			loadOffer(),
			loadCorp(),
			loadPlace(),
			loadClasse()
		])
		.then(() => change("OK") )
		.catch(console.error);
	}, [ loadPromo, promo, loadOffer, loadCorp,	loadPlace, loadClasse ]);

	const applyUpdate = (data) => {
		return API.promo.edit( match.params.promoId, data)
		.then(() => {
			loadPromo()
			.then(() => updateNewPromo({}))
			.then(() => updateModifiable(true))
			.catch(console.error);
		})
		.catch(err => {
			console.error(err);
		});
	};

	const canModify = (e) => {
		e.preventDefault();
		if (!modifiable) {
			applyUpdate(newPromo);
		}
		updateModifiable(!modifiable);
	};
	const handleChange = (e) => {
		newPromo[e.target.name] = e.target.value;
		updatePromo({ ...promo, ...newPromo });
		updateNewPromo(newPromo);
	};
	const handleDateChange = (e) => {
		newPromo['dates'] = newPromo.dates || {};
		let entry = e.target.value.split('-');
		if ( e.target.name === 'dates.expiration') {
			newPromo.dates['expiration'] = moment();
			newPromo.dates['expiration'] = newPromo.dates['expiration']
			.set('year', parseInt(entry[0]) > 0 ? entry[0] : 1)
			.set('month', parseInt(entry[1]) > 0 ? entry[1] - 1 : 0)
			.set('date', parseInt(entry[2]) > 0 ? entry[2] : 1)
			.format('YYYY-MM-DD');
		}
		updatePromo({ ...promo, ...newPromo });
		updateNewPromo(newPromo);
	};
	const isModified = () => {
		return Object.keys(newPromo).length > 0;
	};

	return <Container style={{ padding: '2rem' }}>
		{ state === "LOADING" && <CircularProgress /> }
		{ state === "OK" && <Grid container spacing={2} direction="column" justify="space-around" alignItems="stretch">
			<Grid item md={12}>
				<Grid container spacing={1} direction="row" justify="center" alignItems="center">
					<Grid item md={1}>
						<Button
							disableElevation
							style={{ textTransform:'none' }}
							variant="contained"
							color={ modifiable ? "secondary" : isModified() ? "primary" : "default" }
							onClick={ canModify }
						>
							{ modifiable ? "Modifier" : isModified() ? "Sauvegarder les changements" : "Bloquer" }
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid item md={12}>
				{ (!!promo && !!offers && !!corporates && !!places) && <PromoView
					promo={ promo } offers={ offers } corporates={ corporates } places={ places } classes = {classes}
					modify={modifiable} handleChange={ handleChange } handleDateChange={ handleDateChange } /> }
			</Grid>
		</Grid>
		}
	</Container>;
};

const PromoView = ({ promo, offers, corporates, places, classes, modify, handleChange, handleDateChange }) => {
	if (!promo) return null;
	let restrictedOffer = null;
	let restrictedCorp = null;
	let restrictedPlace = null;
	let restrictedClasse = null;

	if (!!promo.restriction_offers)
		restrictedOffer = offers.find(o => o.id === +promo.restriction_offers);
	if (!!promo.corporate.corporate)
		restrictedCorp = corporates.find(c => c.id === +promo.corporate.corporate);
	if (!!promo.restriction_places)
		restrictedPlace = places.find(o => o.id === +promo.restriction_places);
	if (!!promo.restriction_classes)
		restrictedClasse = classes.find(o => o.id === +promo.restriction_classes);

	return (
		<div className="OfferView">
			<Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
				{/* Code */}
				<Grid container spacing={2} direction={"row"} >
					<Grid item md={12}>
						<FormControl fullWidth>
							<TextField
								label="Code"
								name="name"
								inputProps={{ autoComplete: 'off' }}
								value={ promo.code }
								onChange={ handleChange }
								disabled={ modify }
							/>
						</FormControl>
					</Grid>
				</Grid>
				{/* Date start / end */}
				<Grid container spacing={2} direction={"row"} >
					<Grid item md={6}>
						<FormControl fullWidth>
							<TextField
								label="Début"
								type="date"
								name="dates.creation"
								onChange={ handleDateChange }
								value={ moment(promo.dates.creation).format("YYYY-MM-DD") }
								inputProps={{ step: 1 }}
								disabled={ true }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
					<Grid item md={6}>
						<FormControl fullWidth>
							<TextField
								label="Fin"
								type="date"
								name="dates.expiration"
								onChange={ handleDateChange }
								value={
									promo.dates.expiration ?
										moment(promo.dates.expiration).format("YYYY-MM-DD") :
										moment(promo.dates.creation).add(100, 'year').format("YYYY-MM-DD")
								}
								disabled={ modify }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
				</Grid>
				{/* Comment */}
				<Grid container spacing={2} direction={"row"} >
					<Grid item md={12}>
						<FormControl fullWidth>
							<TextField
								label="Commentaire"
								name="comment"
								inputProps={{ autoComplete: 'off' }}
								value={ promo.comment || undefined }
								onChange={ handleChange }
								disabled={ modify }
							/>
						</FormControl>
					</Grid>
				</Grid>
				{/* Type & State & Value */}
				<Grid container spacing={1} direction={'row'} justify='space-around' alignItems="center">
					<Grid item md={4}>
						<FormControl fullWidth>
							<InputLabel shrink id="select-promo-type">Type</InputLabel>
							<Select
								labelId="select-promo-type"
								name="type"
								value={ promo.type || 'none' }
								disabled={ true }
								onChange={ handleChange }>
								<MenuItem value={'none'}>Type de code</MenuItem>
								<MenuItem value={'single'}>Unique</MenuItem>
								<MenuItem value={'multiple'}>Multiple</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={4}>
						<FormControl fullWidth>
							<InputLabel shrink id="select-promo-state">Status</InputLabel>
							<Select
								labelId="select-promo-state"
								name="state"
								value={ promo.state || 'none' }
								disabled={ modify }
								onChange={ handleChange }>
								<MenuItem value={'none'}>Etat du code</MenuItem>
								<MenuItem value={'disabled'}>Désactivé</MenuItem>
								<MenuItem value={'enabled'}>Activé</MenuItem>
								<MenuItem value={'used'}>Utilisé</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={4}>
						<FormControl fullWidth>
							<TextField
								fullWidth
								label="Remise"
								type="text"
								name="remise"
								disabled={ true }
								value={`- ${!!promo.values.percentage ? promo.values.percentage : (promo.values.value / 100)} ${ !!promo.values.percentage ? "%" : "€" }`}
							/>
						</FormControl>
					</Grid>
				</Grid>

				{/* Restrictions */}
				<Grid container spacing={2} direction={"row"} >
					<Grid item md={4}>
						{/* Restrict Offers */}
						{(!!offers && offers.length > 0 && !!restrictedOffer) ?
						<FormControl fullWidth>
							<TextField
								fullWidth
								label={`Restricted on`}
								type="text"
								name={`Restricted_Corp`}
								value={ restrictedOffer.name }
								disabled={ true }
							/>
						</FormControl>
						: <NotRestricted text={"Offer"}/> }
					</Grid>
					<Grid item md={4}>
						{/* Restrict Corp */}
						{(!!corporates && corporates.length > 0 && !!restrictedCorp) ?
						<FormControl fullWidth>
							<TextField
								fullWidth
								label={`Restricted on`}
								type="text"
								name={`Restricted_Corp`}
								value={ restrictedCorp.name }
								disabled={ true }
							/>
						</FormControl>
							: <NotRestricted text={"Corporate"} /> }
					</Grid>
					<Grid item md={4}>
						{/* Choose Place */}
						{(!!places && places.length > 0 && !!restrictedPlace) ?
						<FormControl fullWidth>
							<TextField
								fullWidth
								label={`Restricted on`}
								type="text"
								name={`Restricted_Place`}
								value={ restrictedPlace.title }
								disabled={ true }
							/>
						</FormControl>
						: <NotRestricted text={"Place"} /> }
					</Grid>
					<Grid item md={4}>
						{/* Choose Place */}
						{(!!classes && classes.length > 0 && !!restrictedClasse) ?
						<FormControl fullWidth>
							<TextField
								fullWidth
								label={`Restricted on`}
								type="text"
								name={`Restricted_Classe`}
								value={ restrictedClasse.name }
								disabled={ true }
							/>
						</FormControl>
						: <NotRestricted text={"Classe"} /> }
					</Grid>
				</Grid>

			</Paper>
		</div>
	);
};

const NotRestricted = ({text}) => {
	return (
		<FormControl fullWidth>
			<TextField
				fullWidth
				label={`Not restricted on`}
				type="text"
				name={`notRestricted_${text}`}
				value={text}
				disabled={ true }
			/>
		</FormControl>
	);
};
