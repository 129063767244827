import React from 'react';
import PropTypes from 'prop-types';

const googleMaps = {
	ready : false
};

export default class GoogleMapsProvider extends React.Component {
	static childContextTypes = {
		googleMaps : PropTypes.object
	};

	getChildContext = () => {
		return {
			googleMaps : googleMaps
		};
	};

	initMap = () => {
		googleMaps.autocompleteService = new window.google.maps.places.AutocompleteService();
		googleMaps.placeService = new window.google.maps.places.PlacesService(document.getElementById("gmapHidden"));
		googleMaps.ready = true;
	};

	componentDidMount(){
		window.initMap = this.initMap;
		let script = document.createElement('script');
		script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDoVYAYhpb6RIaTOM67EyEKzRkX3ZZku2A&libraries=places&callback=initMap";
		document.head.appendChild(script);
	}
	render(){
		return this.props.children;
	}
}
