import React, { useCallback, useEffect, useState } from "react";
import {
	Grid,
	CircularProgress,
	Container,
	Paper,
	Button, TextField, FormControl, Checkbox, FormControlLabel, InputLabel, Select, MenuItem
} from "@material-ui/core";

import API from "./API";
import moment from "moment";
import TextEditor from "./TextEditor";

export default ({ match }) => {
	const [ state, change ] = useState("INIT");
	const [ offer, updateOffer ] = useState(null);
	const [ corporates, updateCorporates ] = useState([]);
	const [ newOffer, updateNewOffer ] = useState({});
	const [ modifiable, updateModifiable ] = useState(true);

	const loadCorporates = useCallback(() => {
		return API.corporate.search( { limit: 1000 } )
		.then(rep => {
			updateCorporates(rep.values);
		});
	}, [ ]);
	const loadOffer = useCallback(() => {
		return API.offer.get( match.params.offerId )
		.then(rep => {
			updateOffer(rep);
			return loadCorporates();
		})
		.then(() => change("OK") );
	}, [ match, loadCorporates ]);

	useEffect(() => {
		if (offer) return;
		change("LOADING");
		loadOffer()
		.catch(console.error);
	}, [ loadOffer, offer ]);

	const applyUpdate = (data) => {
		return API.offer.edit( match.params.offerId, data)
		.then(() => {
			loadOffer()
			.then(() => updateNewOffer({}))
			.then(() => updateModifiable(true))
			.catch(console.error);
		})
		.catch(err => {
			console.error(err);
		});
	};

	const canModify = (e) => {
		e.preventDefault();
		if (!modifiable) {
			applyUpdate(newOffer);
		}
		updateModifiable(!modifiable);
	};

	const handleChange = (e) => {
		if( e.target.name === 'price') {
			newOffer.price = 100 * +(e.target.value);
		}else if( e.target.name === 'allow_free_live' || e.target.name === 'allow_public_live' || e.target.name === 'student_only' || e.target.name === 'is_promo') {
			newOffer[e.target.name] = !offer[e.target.name];
		} else {
			newOffer[e.target.name] = e.target.value;
		}
		updateOffer({ ...offer, ...newOffer });
		updateNewOffer(newOffer);
	};
	const isModified = () => {
		return Object.keys(newOffer).length > 0;
	};

	return <Container style={{ padding: '2rem' }}>
		{ state === "LOADING" && <CircularProgress /> }
		{ state === "OK" && <Grid container spacing={2} direction="column" justify="space-around" alignItems="stretch">
			<Grid item md={12}>
				<Grid container spacing={1} direction="row" justify="center" alignItems="center">
					<Grid item md={1}>
						<Button
							disableElevation
							style={{ textTransform:'none' }}
							variant="contained"
							color={ modifiable ? "secondary" : isModified() ? "primary" : "default" }
							onClick={ canModify }
						>
							{ modifiable ? "Modifier" : isModified() ? "Sauvegarder les changements" : "Bloquer" }
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid item md={12}>
				{ !!offer && <OfferView corporates={corporates} offer={ offer } modify={modifiable} handleChange={ handleChange } /> }
			</Grid>
		</Grid>
		}
	</Container>;
};

const OfferView = ({ offer, corporates, modify, handleChange }) => {
	if (!offer) return null;

	if (offer.restriction.corporate === null) offer.corporateId = 'none';
	else offer.corporateId = offer.restriction.corporate;

	return (
		<div className="OfferView">
			<Paper style={{ padding: '1rem', marginBottom: '1rem' }}>

				<Grid container spacing={2} direction={"row"} >
					{/* Name */}
					<Grid item md={6}>
						<FormControl fullWidth>
							<TextField
								label="Nom de l'offre"
								name="name"
								inputProps={{ autoComplete: 'off' }}
								value={ offer.name }
								onChange={ handleChange }
								disabled={ modify }
							/>
						</FormControl>
					</Grid>
					{/* Description */}
					<Grid item md={6}>
						<TextEditor
							label={"Description"}
							value={ offer.description || "" }
							fieldName="description"
							onChange={ handleChange }
							modify={ modify }
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} direction={"row"} >
					{/* release_date */}
					<Grid item md={6}>
						<FormControl fullWidth>
							<TextField
								label="Date de début (inclue)"
								type="date"
								name="release_date"
								onChange={ handleChange }
								disabled={ modify }
								value={ offer.release_date ? moment(offer.release_date).format("YYYY-MM-DD") : moment(offer.dates.release).format("YYYY-MM-DD")}
								variant="outlined"
							/>
						</FormControl>
					</Grid>
					{/* expiration_date */}
					<Grid item md={6}>
						<FormControl fullWidth>
							<TextField
								label="Date de fin (exclue)"
								type="date"
								name="expiration_date"
								onChange={ handleChange }
								disabled={ modify }
								value={ offer.expiration_date ? moment(offer.expiration_date).format("YYYY-MM-DD") : moment(offer.dates.expiration).format("YYYY-MM-DD")}
								variant="outlined"
							/>
						</FormControl>
					</Grid>
				</Grid>

				<Grid container spacing={2} direction={"row"} >
					{/* Price */}
					<Grid item md={4}>
						<FormControl fullWidth>
							<TextField
								label={`Prix en €`}
								type="number"
								name="price"
								inputProps={{ min: 0, step: 1 }}
								onChange={ handleChange }
								value={ offer.price / 100 || 0 }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
					{/* Renewable */}
					<Grid item md={2}>
						<FormControlLabel label="Renouvelable" labelPlacement="start"
															control={<Checkbox
																color="secondary"
																disabled={ true }
																name="renewable"
																onChange={handleChange}
																defaultChecked={ offer.renewable > 0 || false }
															/>}
						/>
					</Grid>
					{/* Duration in month */}
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField
								label="Mois de validiter"
								type="number"
								name="durationInMonth"
								inputProps={{ min: 0, step: 1 }}
								onChange={handleChange}
								disabled={ true }
								value={offer.durationInMonth || offer.duration }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
					{/* Commitment */}
					{ offer.renewable > 0 && <Grid item md={3}>
						<FormControl fullWidth >
							<TextField
								label="Engagement (0=sans,1=1mois,..)"
								type="number"
								name="commitment"
								disabled={ true }
								inputProps={{ min: 0, step: 1 }}
								onChange={ handleChange }
								value={ offer.commitment || 0 }
								variant="outlined"
							/>
						</FormControl>
					</Grid>}
				</Grid>
				<Grid container spacing={2} direction={"row"}>
					{/* Bookings Max */}
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField
								label="Bookings Max"
								type="number"
								name="bookings"
								inputProps={{ min: 0, step: 1 }}
								onChange={ handleChange }
								value={ offer.bookings || 0 }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField
								label="Limite nb bookings à venir"
								type="number"
								name="pendingBookingsMax"
								inputProps={{ min: 0, step: 1 }}
								onChange={ handleChange }
								value={ offer.pendingBookingsMax || 0 }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container spacing={2} direction={"row"}>
					{/* Fisrt sub only */}
					<Grid item md={6}>
						<FormControlLabel label="Premier abonnement" labelPlacement="start"
															control={<Checkbox
																disabled={ modify }
																color="secondary"
																name="firstSubOnly"
																onChange={handleChange}
																defaultChecked={ offer.firstSubOnly > 0 || offer.restriction.firstSub }
															/>}
						/>
					</Grid>
					<Grid item md={6}>
						<FormControlLabel label="Offre étudiants" labelPlacement="start"
															control={<Checkbox
																disabled={ modify }
																color="secondary"
																name="student_only"
																onChange={handleChange}
																defaultChecked={offer.student_only }
															/>}
						/>
					</Grid>
					{/* allow free live */}
					<Grid item md={6}>
						<FormControlLabel label="Offre promo" labelPlacement="start"
															control={<Checkbox
																disabled={ modify }
																color="secondary"
																name="is_promo"
																onChange={handleChange}
																defaultChecked={offer.is_promo }
															/>}
						/>
					</Grid>
					<Grid item mb={6}/>
					<Grid item md={6}>
						<FormControlLabel label="Lives gratuits" labelPlacement="start"
															control={<Checkbox
																disabled={ modify }
																color="secondary"
																name="allow_free_live"
																onChange={handleChange}
																defaultChecked={offer.allow_free_live }
															/>}
						/>
					</Grid>
					{/* allow_public_live */}
					<Grid item md={6}>
						<FormControlLabel label="Lives publics" labelPlacement="start"
															control={<Checkbox
																disabled={ modify }
																color="secondary"
																name="allow_public_live"
																onChange={handleChange}
																defaultChecked={offer.allow_public_live }
															/>}
						/>
					</Grid>
					{/* Live Only */}
					<Grid item md={6}>
						<FormControlLabel label="Restriction Live" labelPlacement="start"
															control={<Checkbox
																color="secondary"
																disabled={ modify }
																name="liveOnly"
																onChange={handleChange}
																defaultChecked={ offer.liveOnly > 0 || offer.restriction.live }
															/>}
						/>
					</Grid>
					{/* Stage Only */}
					<Grid item md={6}>
						<FormControlLabel label="Restriction Stage" labelPlacement="start"
															control={<Checkbox
																color="secondary"
																disabled={ modify }
																name="stage"
																onChange={handleChange}
																defaultChecked={ offer.stage > 0 || offer.restriction.stage }
															/>}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} direction={"row"} >
					{/* corporates */}
					<Grid item md={12}>
						{(!!corporates && corporates.length > 0) &&
						<FormControl fullWidth>
							<InputLabel shrink id="offer-select-corporate">Public / Corporate</InputLabel>
							<Select
								labelId="offer-select-corporate"
								disabled={ true }
								name="corporateId"
								value={offer.corporateId}
								onChange={handleChange}>
								<MenuItem value={'none'}>Public</MenuItem>
								{corporates.map(co => <MenuItem key={co.id} value={co.id}>{co.name}</MenuItem>)}
							</Select>
						</FormControl>}
					</Grid>
				</Grid>

			</Paper>
		</div>
	);
};
