import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import {
	Grid,
	CircularProgress,
	Container,
	TextField,
	FormControlLabel,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Checkbox,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Button,
	Paper,
	Select,
	MenuItem,
	AppBar,
	Tabs,
	Tab,
	Box,
} from "@material-ui/core";

import './UserDisplay.scss';

import API from "./API";
import ImageSimple from './ImageSimple';
import TextEditor from "./TextEditor";
// import LoadingCircle from "./LoadingCircle";

const isInPast = (date) => {
	const today = new Date().getTime();
	const targetDate = moment(date).valueOf();
	return targetDate <= today;
};

const getTabProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

const TabPanel = ({...props}) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box pt={3}>
					<Grid container spacing={1} direction="row" justify="center" alignItems="stretch">
						{children}
					</Grid>
				</Box>
			)}
		</div>
	);
};

export default ({ match }) => {
	const [ state, change ] = useState("INIT");
	const [ user, updateUser ] = useState(null);
	const [ subs, updateSubs ] = useState(null);
	const [ referrals, updateReferrals ] = useState(null);
	const [ code, updateCode ] = useState(null);
	const [ visibleTab, changeTabs ] = useState(0);
	const [ expanded, setExpanded ] = useState(0);
	const [ newUser, updateNewUser ] = useState({});
	const [ error, setError ] = useState([]);

	const loadUser = useCallback(params => {
		const userId = params ? params : match.params.userId;
		change("LOADING");
		setError([]);
		return API.user.get( userId )
		.then(userResponse => {
			updateUser(userResponse);
			return API.abo.search({ userId: userId });
		})
		.then((aboResponse) => {
			updateSubs(aboResponse);
			return API.promo.search({ sponsor: userId });
		})
		.then( promo => {
			updateCode(promo.values[0]);
			return API.referral.search({ sponsor: userId });
		})
		.then( referral => updateReferrals(referral.referrals) )
		.then(() => change("OK") );
	}, [ match ]);

	const handleChangeUser = (e) => {
		!!e && !!e.preventDefault && e.preventDefault();
		let tmp = {};

		if (e.target.name.indexOf('.') >= 0) {
			const targetSplit = e.target.name.split('.');
			tmp[ targetSplit[0] ] = { ...user[ targetSplit[0] ] };
			tmp[ targetSplit[0] ][ targetSplit[1] ] = e.target.value || e.target.checked;
			if ( targetSplit[0] === 'coach' && targetSplit[1] === 'enabled' && !e.target.checked) {
				tmp[ targetSplit[0] ] = {
					enabled: false,
					urban: false,
					urbanCode: null,
					type: user.coach.type,
					price: user.coach.price,
					description: user.coach.description
				};
			}
		} else {
			tmp[e.target.name] = e.target.value || e.target.checked || null;
		}
		updateNewUser(tmp);
		updateUser({ ...user, ...tmp });
	};

	const save = () => {
		let body = {};
		let err = [];

		if (!!newUser.coach) {
			body.coach = {};
			if (newUser.coach.enabled !== undefined) body.coach.enabled = newUser.coach.enabled;
			if (newUser.coach.urban !== undefined) body.coach.urban = newUser.coach.urban;
			if (!!newUser.coach.type) body.coach.type = newUser.coach.type;
			if (!!newUser.coach.description) body.coach.description = newUser.coach.description;
			if (!!newUser.coach.urbanCode) body.coach.code = parseInt(newUser.coach.urbanCode);
			if (!!newUser.coach.price) body.coach.price = parseInt(newUser.coach.price);

			if (!!newUser.coach.tva) body.coach.tva = parseInt(newUser.coach.tva, 10);
			if (!!newUser.coach.number_tva) body.coach.number_tva = newUser.coach.number_tva;
			if (!!newUser.coach.iban) body.coach.iban = newUser.coach.iban;
			if (!!newUser.coach.bic) body.coach.bic = newUser.coach.bic;
			if (!!newUser.coach.siren) body.coach.siren = newUser.coach.siren;
			else err.push({ name: 'coach.siren', reason: 'required' });
			if (!!newUser.coach.address) body.coach.address = newUser.coach.address;
			else err.push({ name: 'coach.address', reason: 'required' });
			if (!!newUser.coach.zipcode) body.coach.zipcode = newUser.coach.zipcode;
			else err.push({ name: 'coach.zipcode', reason: 'required' });
			if (!!newUser.coach.city) body.coach.city = newUser.coach.city;
			else err.push({ name: 'coach.city', reason: 'required' });
		}

		if (err.length > 0) return setError(err);

		API.user.edit(match.params.userId, body)
		.then(() => {
			updateNewUser({});
			loadUser();
		})
		.catch(err => {
			change("ERROR");
			setTimeout( loadUser, 5000);
		});
	};

	const updateAvatar = (body) => {
		API.user.edit(match.params.userId, {
			coach_avatar: body.image
		})
		.then(() => {
			updateNewUser({});
			loadUser();
		})
		.catch(err => {
			change("ERROR");
			setTimeout( loadUser, 5000);
		});
	};

	useEffect(() => {
		if (user || subs) return;
		loadUser()
		.catch(console.error);
	}, [ loadUser, subs, user ]);

	const handleExpandChange = index => (event, newExpanded) => {
		if (newExpanded) {
			setExpanded(index);
		} else {
			setExpanded(null);
		}
	};
	const handleTabChange = (event, tab) => {
		!!event && event.preventDefault();
		changeTabs(tab);
	};

	return <Container style={{ padding: '2rem' }}>

		<AppBar position="static">
			<Tabs value={visibleTab} onChange={handleTabChange} aria-label="simple tabs example">
				<Tab label="Infos Général" {...getTabProps(0)} />
				<Tab label="Coach" {...getTabProps(1)} />
				<Tab label="Parrainages" {...getTabProps(2)} />
			</Tabs>
		</AppBar>

		{ state === "LOADING" && <CircularProgress /> }
		{ state === "ERROR" && <CircularProgress /> }

		{state === "OK" && <div>
			<TabPanel value={visibleTab} index={0}>
				{!!user && <>
					<Grid item md={12} xs={12} sm={12}>
						<UserView user={user}/>
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						<UserMeta user={user} reload={loadUser} />
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						<UserBan user={user} reload={loadUser} />
					</Grid>
				</>}
				<Grid item md={12} xs={12} sm={12}>
					{ !!subs && <UserAbonnements
						subs={Object.values(subs.values)}
						expanded={expanded}
						expandChange={handleExpandChange}
						reload={loadUser}
					/> }
				</Grid>

			</TabPanel>

			<TabPanel value={visibleTab} index={1}>

				<Grid item md={12} xs={12} sm={12}>
					{ !!user && <UserCoach change={ handleChangeUser } user={ user } updateAvatar={ updateAvatar } error={error} /> }
				</Grid>

				{ Object.keys(newUser).length > 0 && <Grid item md={12} xs={12} sm={12}>
					<Grid container spacing={1} direction="column" justify="center" alignItems="center">
						<Grid item md={12}>
							<Button variant="contained" color="secondary" onClick={save}>Sauvegarder</Button>
						</Grid>
					</Grid>
				</Grid> }

			</TabPanel>

			<TabPanel value={visibleTab} index={2}>

				<Grid item md={12} xs={12} sm={12}>
					{ !!code && <UserReferral promo={ code } referrals={ referrals } reload={ loadUser }/> }
				</Grid>

			</TabPanel>

		</div> }

	</Container>;
};

const UserView = ({ user }) => {
	if (!user) return null;
	return (
		<Paper style={{ padding: '1rem' }}>
			<Grid container spacing={2} direction={"column"}>
				<Grid item md={12}>
					<TextField label="Nom" variant="outlined" disabled defaultValue={ user.lastname } fullWidth />
				</Grid>
				<Grid item md={12}>
					<TextField label="Prénom" variant="outlined" disabled defaultValue={ user.firstname } fullWidth />
				</Grid>
				<Grid item md={12}>
					<TextField label="Email" variant="outlined" disabled defaultValue={ user.email } fullWidth />
				</Grid>
				<Grid item md={12}>
					<TextField label="Téléphone" variant="outlined" disabled defaultValue={ user.phone } fullWidth />
				</Grid>
				<Grid item md={12}>
					<TextField label="Genre" variant="outlined" disabled defaultValue={ user.gender } fullWidth />
				</Grid>
				<Grid item md={6}>
					<FormControlLabel label="Newsletter" labelPlacement="end"
														control={<Checkbox color="primary" disabled defaultChecked={ user.newsletter > 0 } />}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

const UserMeta = ({ user, reload }) => {
	const handleChange = (e) => {
		let body = {};

		if (['none', 'white', 'green', 'black'].indexOf(e.target.value) >= 0)
			body.level = e.target.value;

		if (Object.values(body).length < 0) return null;

		API.user.edit(user.id, body)
		.then(() => {
			reload();
		})
		.catch(console.error);
	};
	user.level = user.level !== null ? user.level : 'none';
	return (
		<Paper style={{ padding: '1rem' }}>
			<Grid container spacing={2} direction={"row"} justify="space-evenly" alignItems="center">

				<Grid item xs={12} md={3}>
					<TextField label="Role" variant="outlined" disabled defaultValue={
						(user.admin && user.coach.enabled) ? "Admin & Coach" : ( user.admin ? "Admin" : (user.coach.enabled ? "Coach" : "Client") )
					} fullWidth />
				</Grid>

				<Grid item xs={12} md={3}>
					<TextField label="Date de création" variant="outlined" disabled defaultValue={ moment(user.creation.date).format('YYYY-MM-DD HH:mm') } fullWidth />
				</Grid>

				<Grid item xs={12} md={3}>
					<TextField label="Dernière connexion" variant="outlined" disabled defaultValue={ moment(user.lastLogin).format('YYYY-MM-DD HH:mm') } fullWidth />
				</Grid>

				<Grid item xs={12} md={3}>
					<FormControl fullWidth variant="outlined">
						<Select
							labelId="user-display-level"
							name="level"
							value={ user.level }
							onChange={ handleChange }>
							<MenuItem value={'none'}>Attribuer un niveau</MenuItem>
							<MenuItem value={'white'}>Jaune / Rang 1</MenuItem>
							<MenuItem value={'green'}>Rouge / Rang 2</MenuItem>
							<MenuItem value={'black'}>Noir  / Rang 3</MenuItem>
						</Select>
					</FormControl>
				</Grid>

			</Grid>
		</Paper>
	);
};

const UserBan = ({ user, reload }) => {

	const cancelBan = () => {
		API.user.edit(user.id, {
			book_forbidden: 'none'
		})
		.then(reload)
		.catch(err => {
			console.error(err);
		});
	};
	const addBan = () => {
		API.user.edit(user.id, {
			book_forbidden: 'banned'
		})
		.then(reload)
		.catch(err => {
			console.error(err);
		});
	};
	const checkBan = () => {
		API.user.edit(user.id, { check_absence: true })
		.then(reload)
		.catch(err => {
			console.error(err);
		});
	};

	return (
		<Paper style={{ padding: '1rem' }}>
			<Typography style={{ margin: '0 0 1rem 0' }}>Suspension de réservation</Typography>
			<Grid container spacing={2} direction={"row"} justify="space-evenly" alignItems="center">
				<Grid item xs={12} md={12}>
						<TextField
							label="État de la suspension de réservation"
							variant="outlined"
							disabled
							defaultValue={
								!!user.book_forbidden ?
									`Est suspendu jusqu'au ${moment(user.book_forbidden).format('YYYY-MM-DD HH:mm')}` :
									"N'est pas suspendu"
							}
							fullWidth
						/>
				</Grid>
				<Grid item xs={6} md={3}>
					<Button
						variant="outlined"
						color="primary"
						onClick={cancelBan}
						disabled={!user.book_forbidden}
					>Annuler la suspension</Button>
				</Grid>
				<Grid item xs={6} md={3}>
					<Button
						variant="outlined"
						color="secondary"
						onClick={addBan}
						disabled={!!user.book_forbidden}
					>Suspendre les résa</Button>
				</Grid>
				<Grid item xs={6} md={3}>
					<Button variant="outlined" color="default" onClick={checkBan}>Vérifier les abscenses</Button>
				</Grid>

			</Grid>
		</Paper>
	);
};

const UserCoach = ({ user, change, updateAvatar, error }) => {
	if (!user) return null;
	let isCoach = (!!user && user.coach.enabled > 0);
	const checkError = (name) => error.findIndex(f => f.name === name) >= 0;

	return (
		<Paper style={{ padding: '1rem' }}>

			<Grid container spacing={2} direction="row" justify="space-between" alignItems="center">

				{/*Coach mode*/}
				<Grid item md={12} xs={12}>
					<Grid container spacing={1} direction="row" justify="center" alignItems="center">

						<Grid item xs={12} md={ isCoach ? 6 : 12}>
							{/*coach.enabled*/}
							<FormControlLabel
								control={
									<Checkbox
										checked={!!user.coach.enabled}
										disabled={!!user.coach.enabled}
										onChange={ change }
										name="coach.enabled"
										color="primary"
									/>
								}
								labelPlacement="end"
								label="Mode Coach"
							/>
						</Grid>
						{isCoach && <Grid item xs={12} md={6}>
							{/*"coach.urban"*/}
							<FormControlLabel
								control={
									<Checkbox
										checked={!!user.coach.urban}
										onChange={ change }
										name="coach.urban"
										color="primary"
									/>
								}
								labelPlacement="end"
								label="Coach urban"
							/>
						</Grid>}

					</Grid>
				</Grid>
				{/*Code and Type*/}
				{ isCoach && <Grid item md={12} xs={12}>
					<Grid container spacing={1} direction="row" justify="space-around" alignItems="center">

						{/*coach.urbanCode*/}
						<Grid item md={6} xs={12}>
							<TextField label="Coach n°" variant="outlined" type="number" defaultValue={ user.coach.urbanCode } onChange={ change } name="coach.urbanCode" fullWidth />
						</Grid>

						<Grid item md={6} xs={12}>
							{/*coach.type*/}
							<FormControl fullWidth variant="outlined">
								<Select
									labelId="user-display-coach-type"
									name="coach.type"
									value={ user.coach.type }
									onChange={ change }>
									<MenuItem value={'undefined'}>Type</MenuItem>
									<MenuItem value={'pos'}>Pos</MenuItem>
									<MenuItem value={'ae'}>AE</MenuItem>
								</Select>
							</FormControl>
						</Grid>

					</Grid>
				</Grid> }
				{/* TVA and TVA Number and SIREN */}
				{ isCoach && <Grid item md={12} xs={12}>
					<Grid container spacing={1} direction="row" justify="space-around" alignItems="center">
						{/*coach.tva*/}
						<Grid item md={4} xs={12}>
							<TextField disabled label="% TVA" variant="outlined" type="number" defaultValue={ user.coach.tva || null } onChange={ change } name="coach.tva" fullWidth />
						</Grid>
						<Grid item md={4} xs={12}>
							{/*coach.tvaNumber*/}
							<TextField label="N° TVA" variant="outlined" type="text" defaultValue={ user.coach.number_tva || "" } onChange={ change } name="coach.number_tva" fullWidth />
						</Grid>
						<Grid item md={4} xs={12}>
							{/*coach.siren*/}
							<TextField error={checkError('coach.siren')} label="N° SIREN" variant="outlined" type="text" defaultValue={ user.coach.siren || "" } onChange={ change } name="coach.siren" fullWidth required />
						</Grid>
					</Grid>
				</Grid> }
				{/* Address, ZipCode, City */}
				{ isCoach && <Grid item md={12} xs={12}>
					<Grid container spacing={1} direction="row" justify="space-around" alignItems="center">

						{/*coach.address*/}
						<Grid item md={4} xs={12}>
							<TextField error={checkError('coach.address')} label="Numéro, Rue" variant="outlined" type="text" defaultValue={ user.coach.address || "" } onChange={ change } name="coach.address" fullWidth required />
						</Grid>

						<Grid item md={4} xs={12}>
							{/*coach.zipCode*/}
							<TextField error={checkError('coach.zipcode')} label="Code Postal" variant="outlined" type="text" defaultValue={ user.coach.zipcode || "" } onChange={ change } name="coach.zipcode" fullWidth required />
						</Grid>
						<Grid item md={4} xs={12}>
							{/*coach.city*/}
							<TextField error={checkError('coach.city')} label="Ville" variant="outlined" type="text" defaultValue={ user.coach.city || "" } onChange={ change } name="coach.city" fullWidth required />
						</Grid>
					</Grid>
				</Grid> }
				{/* IBAN & BIC */}
				{ isCoach && <Grid item md={12} xs={12}>
					<Grid container spacing={1} direction="row" justify="space-around" alignItems="center">
						{/*coach.iban*/}
						<Grid item md={6} xs={12}>
							<TextField label="IBAN" variant="outlined" type="text" defaultValue={ user.coach.iban || "" } onChange={ change } name="coach.iban" fullWidth />
						</Grid>
						<Grid item md={6} xs={12}>
							{/*coach.bic*/}
							<TextField label="BIC" variant="outlined" type="text" defaultValue={ user.coach.bic || "" } onChange={ change } name="coach.bic" fullWidth />
						</Grid>
					</Grid>
				</Grid> }

				{/*Description*/}
				{ isCoach && <Grid item md={12} xs={12}>
					<Grid container spacing={1} direction="row" justify="space-around" alignItems="center">

						{/*coach.description*/}
						<Grid item md={12} xs={12}>
							<TextEditor
								label={"Description"}
								value={ user.coach.description }
								fieldName="coach.description"
								onChange={ change }
								modify={ false }
								hideCancel={ true }
							/>
						</Grid>

					</Grid>
				</Grid> }

				{/*Avatar*/}
				{ isCoach && <Grid item md={12} xs={12}>
					<Grid container spacing={1} direction="column" justify="center" alignItems="center">

						<Grid item md={12} xs={12}>
							<ImageSimple
								image={ user.coach.avatar }
								from={{ type: 'Coach', id: user.id }}
								modify={ false }
								applyUpdate={ updateAvatar }
							/>
						</Grid>

					</Grid>
				</Grid> }

			</Grid>
		</Paper>
	);
};

// const UserTeacher = ({ user }) => {
// 	const sampleTeacher = {
// 		jobs: '',
// 		speciality: '',
// 		lesson: [],
// 		description: ''
// 	};
// 	const [ state, update ] = useState({ step: 'INIT', changed: false, teacher: sampleTeacher });
//
// 	const handleChange = (e) => {
// 		const tmp = state.teacher;
// 		tmp[e.target.name] = e.target.value;
// 		update({ ...state, changed: true, teacher: tmp });
// 	};
// 	const setLesson = (lessons) => {
// 		update({
// 			...state,
// 			changed: true,
// 			teacher: {
// 				...state.teacher,
// 				lesson: lessons
// 			}
// 		});
// 	};
// 	const saveTeacher = () => {
// 		const body = {
// 			userId: user.id,
// 			jobs: state.teacher.jobs,
// 			speciality: state.teacher.speciality,
// 			description: state.teacher.description,
// 			lesson: typeof state.teacher.lesson === 'string' ? state.teacher.lesson.split(',') : state.teacher.lesson,
// 		};
// 		if (!!state.teacher.id) {
// 			API.teacher.edit(state.teacher.id, body)
// 			.then(res => update({ step: "INIT", changed: false, teacher: sampleTeacher }))
// 			.catch(err => { console.log(err, "error on edit teacher")});
// 		} else {
// 			API.teacher.create(body)
// 			.then(res => {
// 				update({ step: "INIT", changed: false, teacher: sampleTeacher });
// 			})
// 			.catch(err => {
// 				update({ step: "ERROR", changed: false, teacher: sampleTeacher, error: err });
// 			});
// 		}
// 	};
// 	const load = () => {
// 		if (!!state.teacher.id) return ;
// 		API.teacher.getByUserId( user.id )
// 		.then(teacher => {
// 			update({ step: "OK", teacher: teacher });
// 		})
// 		.catch(err => {
// 			if (err.statusCode === 404) update({ step: "OK", teacher: sampleTeacher });
// 			else update({ step: 'ERROR', error: err, teacher: null });
// 		});
// 	};
//
// 	useEffect(load, [ state.teacher ]);
//
// 	if (state.step === 'ERROR') return <LoadingCircle error={state.error} />
//
// 	return (
// 		<Paper style={{ padding: '1rem' }}>
//
// 			{ state.step === 'OK' && <Grid container spacing={2} direction="row" justify="space-between" alignItems="center">
// 				<Grid item md={12} xs={12}>
// 					<h4>Coach individuel</h4>
// 				</Grid>
//
// 				<Grid item md={12} xs={12}>
// 					{/*teacher.jobs*/}
// 					<Grid item md={12} xs={12}>
// 						<TextField label="Jobs" variant="outlined" type="text" defaultValue={ state.teacher.jobs } onChange={ handleChange } name="jobs" fullWidth />
// 					</Grid>
// 				</Grid>
//
// 				<Grid item md={12} xs={12}>
// 					{/*teacher.speciality*/}
// 					<Grid item md={12} xs={12}>
// 						<TextField label="Spécialité" variant="outlined" type="text" defaultValue={ state.teacher.speciality } onChange={ handleChange } name="speciality" fullWidth />
// 					</Grid>
// 				</Grid>
//
// 				<Grid item md={12} xs={12}>
// 					{/*teacher.lesson*/}
// 					<TeacherLesson lesson={ state.teacher.lesson } setLesson={ setLesson } />
// 				</Grid>
//
// 				<Grid item md={12} xs={12}>
// 					<Grid container spacing={1} direction="row" justify="space-around" alignItems="center">
// 						{/*teacher.description*/}
// 						<Grid item md={12} xs={12}>
// 							<TextEditor
// 								label={"Description"}
// 								value={state.teacher.description || ''}
// 								fieldName="description"
// 								onChange={handleChange}
// 								modify={false}
// 								hideCancel={true}
// 							/>
// 						</Grid>
// 					</Grid>
// 				</Grid>
//
// 				{ !!state.changed && <Grid item md={12} xs={12} sm={12}>
// 					<Grid container spacing={1} direction="column" justify="center" alignItems="center">
// 						<Grid item md={12}>
// 							<Button variant="contained" color="secondary" onClick={saveTeacher}>Sauvegarder</Button>
// 						</Grid>
// 					</Grid>
// 				</Grid> }
//
// 			</Grid>
// 			}
// 		</Paper>
// 	);
// };

// const TeacherLesson = ({ lesson, setLesson }) => {
// 	const [ lessonToAdd, updateLesson ] = useState('');
//
// 	if (typeof lesson === 'string') lesson = lesson.split(',');
//
// 	const handleChange = (e) => {
// 		updateLesson(e.target.value);
// 	};
// 	const deleteIt = (value, event) => {
// 		const lessonIndex = lesson.findIndex(f => f === value);
//
// 		if (!window.confirm(`Étes-vous sûr de vouloir supprimer le cours de ${lesson[lessonIndex]} ?`)) return ;
//
// 		setLesson(lesson.filter((f,i) => i !== lessonIndex));
// 	};
// 	const addLesson = (event) => {
// 		if (event.key === 'Enter' || event.keyCode === 13) {
// 			lesson.push(lessonToAdd);
// 			setLesson(lesson);
// 			updateLesson('');
// 		}
// 	};
//
// 	return (
// 		<Grid item md={12} xs={12} className={"TeacherLesson"}>
// 			<ol>
// 			{ !!lesson.length && lesson.map((l,i) => {
// 				return (
// 					<li key={i}>
// 						<Button variant="outlined" color="primary">
// 							{l}&nbsp;<button onClick={deleteIt.bind(this, l)}>X</button>
// 						</Button>
// 					</li>
// 				);
// 			}) }
// 			</ol>
// 			<TextField
// 				label="Ajouter un cours"
// 				variant="outlined"
// 				type="text"
// 				value={ lessonToAdd }
// 				onChange={ handleChange }
// 				onKeyUp={addLesson}
// 				name="lesson"
// 				fullWidth />
// 		</Grid>
// 	);
// };

const UserReferral = ({ promo, referrals, reload }) => {
	const [expanded, setExpand] = useState('viewRef');

	if (!promo) return null;

	const handleExpandChange = index => (event, newExpanded) => {
		if (newExpanded) {
			setExpand(index);
		} else {
			setExpand(null);
		}
	};

	const changeUser = (value, event) => {
		event.preventDefault();
		reload(value);
	};

	return (
		<Accordion expanded={expanded === "viewRef"} onChange={ handleExpandChange("viewRef") }>
			<AccordionSummary>
				<Typography>Parrainage #{promo.code}</Typography>
			</AccordionSummary>
			<AccordionDetails style={{ display:'flex', flexDirection:'column', justifyContent:"space-around" }}>
				<Grid container spacing={2} direction={"column"} justify="center">

					{(!!referrals && referrals.length <= 0) && <Grid item md={12}>Aucun filleul</Grid>}

					{(!!referrals && referrals.length > 0) && referrals.map(r =>
						<Grid item md={12} key={r.id}>
							<Grid container direction="row" spacing={1} justify="space-evenly" alignItems="center">
								<Grid item md={2}>
									<span style={{ cursor: 'pointer', color:'blue' }} onClick={ changeUser.bind(this, r.id) }>{ r.name }</span>
								</Grid>
								<Grid item md={4}>
									{ r.email }
								</Grid>
								<Grid item md={4}>
									{ r.offer.name }
								</Grid>
								<Grid item md={2}>
									{ moment(r.sub.start).format("DD MMM YYYY") }
								</Grid>
							</Grid>
						</Grid>
					)}

				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

const UserAbonnements = ({ subs, expanded, expandChange, reload }) => {

	if (!subs || !subs.length) return null;

	const stopAbo = (abo, event) => {
		event.preventDefault();
		let result = prompt("Tapez 'confirmer' pour mettre fin à l'abonnement");

		if (result !== 'confirmer') return;

		API.abo.delete(abo.id)
		.then( () => reload() )
		.catch(console.error)
		;
	};

	const submitDateChange = (subId) => {
		const inputDate = document.getElementById(`subEndDate-${subId}`);
		API.subscription.edit(subId, {
			subEndDate: inputDate.value
		})
		.then(reload)
		.catch(err => {
			console.error(err);
		});
	};

	return (
		<div>
		{subs.reverse().map((s, i) => (
				<Accordion key={i} expanded={expanded === i} onChange={ expandChange(i) }>
					<AccordionSummary>
						<Typography>Abonnement #{s.id}</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ display:'flex', flexDirection:'column', justifyContent:"space-around" }}>
						<Grid container spacing={2} direction={"column"}>
							{ ((s.cancelation && s.cancelation.date) ? false : !isInPast(s.ends) ) &&
								<Grid item md={12} style={{ display:'flex', justifyContent:'center', marginTop:'-2rem' }}>
									<Button onClick={ stopAbo.bind(this, s) } color={'secondary'} variant={'outlined'}>Arrêt immédiat</Button>
								</Grid>
							}
							{ !s.following && <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
								<TextField id={`subEndDate-${s.id}`} label="Date de fin" variant="outlined" type="date" defaultValue={ moment(s.ends).format('YYYY-MM-DD') } name={`subEnd-${s.id}`} style={{ width: '85%' }} />
								<Button onClick={() => submitDateChange(s.id)} color={'primary'} variant={'outlined'} style={{ marginLeft: '10px' }} >Mettre à jour</Button>
							</Grid> }
							<Grid item md={12}>
								<TextField label="Nom de la formule" variant="outlined" disabled defaultValue={ s.offer.actual || s.offer.name } fullWidth />
							</Grid>
							<Grid item md={12}>
								<TextField label="Date d'achat" variant="outlined" disabled defaultValue={ moment(s.creation.date).format('YYYY-MM-DD HH:mm') } fullWidth />
							</Grid>
							<Grid item md={12}>
								<TextField label="Date de résiliation" variant="outlined" disabled defaultValue={ s.cancelation ? moment(s.cancelation.date).format('YYYY-MM-DD HH:mm') : "Pas résilié" } fullWidth />
							</Grid>
							<Grid item md={12}>
								<TextField
									label={ isInPast(s.ends) ? "Date de fin" : "Date de fin présumé"}
									variant="outlined"
									disabled
									defaultValue={ moment(s.ends).format('YYYY-MM-DD HH:mm') }
									fullWidth />
							</Grid>
							<Grid item md={12}>
								<FormControl fullWidth variant="outlined">
									<InputLabel htmlFor={`offer-price-${i}`}>Prix de la formule à l'achat</InputLabel>
									<OutlinedInput
										id={`offer-price-${i}`} disabled
										defaultValue={ s.offer.price / 100 } labelWidth={200}
										startAdornment={<InputAdornment position="start">€</InputAdornment>}
									/>
								</FormControl>
							</Grid>
							{ !!s.promo &&
							<Grid item md={12}>
								<TextField label="Code utilisé a l'achat" variant="outlined" disabled defaultValue={ s.promo.code } fullWidth />
							</Grid>
							}
							{ !!s.promo &&
							<Grid item md={12}>
								<TextField label="Commentaire code promo" variant="outlined" disabled defaultValue={ s.promo.comment } fullWidth />
							</Grid>
							}
							{ !!s.promo && !!s.promo.corporate &&
							<Grid item md={12}>
								<TextField label="Entreprise" variant="outlined" disabled defaultValue={ s.promo.corporate.name } fullWidth />
							</Grid>
							}
						</Grid>

						{ s.subs.reverse().map( (ss, k) => <AboPeriod key={k} sub={ ss } reload={reload}/> )}

					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
};

const AboPeriod = ({ sub, reload }) => {
	const [ expanded, setExpanded ] = useState(null);
	const [ bookings, setBookings ] = useState([]);

	const loadBooking = useCallback(() => {
		return API.booking.search({ subscription: expanded })
		.then(booking => {
			setBookings(booking);
		});
	}, [ expanded ]);

	useEffect( () => {
		if (!expanded) return;
		loadBooking();
	}, [ loadBooking, expanded ]);

	if (!sub) return;

	const handleExpandChange = subId => (event, newExpanded) => {
		if (newExpanded) {
			setExpanded(subId);
		} else {
			setExpanded(null);
		}
	};

	const stopBooking = (booking, event) => {
		event.preventDefault();
		API.booking.delete(booking.id)
		.then(() => {
			loadBooking();
		})
		.catch(console.error)
		;
	};

	const switchDoNotRetry = (e) => {
		e.stopPropagation();
		API.subscription.edit(sub.id, {
			payment_do_not_retry: !sub.payment_do_not_retry
		})
		.then(reload)
		.catch(err => {
			console.error(err);
		});
	};

	const switchBlockBooking = (e) => {
		e.stopPropagation();
		API.subscription.edit(sub.id, {
			payment_block_booking: !sub.payment_block_booking
		})
		.then(reload)
		.catch(err => {
			console.error(err);
		});
	};

	let payment = null;
	if (sub.payment) {
		payment = <Typography>{`Payé: ${(sub.payment.price / 100).toFixed(2)} €`}</Typography>
	} else if (sub.starts <= moment().format('YYYY-MM-DD') && !(sub.cancelation && !!sub.cancelation.date)) {
		payment = (
			<div style={{display:'flex', flexDirection:"column"}}>
				<Typography color={sub.payment_do_not_retry ? "secondary" : "primary"}>
					Impayé {!!sub.payment_do_not_retry ? "sans" : "avec"} retry
					<Button onClick={switchDoNotRetry}>Switch</Button>
				</Typography>
				<Typography color={sub.payment_block_booking ? "secondary" : "primary"}>
					{sub.payment_block_booking ? "Bloque les réservations" : "Ne bloque pas les réservations"}
					<Button onClick={switchBlockBooking}>Switch</Button>
				</Typography>
			</div>
		);
	}

	return (
		<Accordion expanded={expanded === sub.id} onChange={ handleExpandChange(sub.id) }>
			<AccordionSummary  >
				<div style={{ display: 'flex', flexGrow:2, justifyContent:'space-between' }}>
					<Typography>#{sub.id} Periode du {sub.starts} au {sub.ends}</Typography>
					{ (sub.cancelation && !!sub.cancelation.date) && <Typography color="primary">Période Annulée</Typography> }
					{payment}
				</div>
			</AccordionSummary>
			<AccordionDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
				{ bookings.length > 0 && <TableContainer>
					<Table size="small" aria-label="all booking">
						<TableHead>
							<TableRow>
								<TableCell align="left">Cours</TableCell>
								<TableCell align="left">Lieu</TableCell>
								<TableCell align="left">Date de debut</TableCell>
								<TableCell align="left">Status</TableCell>
								<TableCell align="left">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{ bookings.reverse().map( (book, key) =>
							(
								<TableRow key={key}>
									<TableCell component="th" scope="row">{book.seance.name}</TableCell>
									<TableCell component="th" scope="row">{book.place.name}</TableCell>
									<TableCell component="th" scope="row">{ moment(book.starts).format('YYYY-MM-DD HH:mm') }</TableCell>
									<TableCell component="th" scope="row">{ book.canceled ? "Annulé" : isInPast(book.ends) ? (!!book.presence.value ? "Effectué" : "Absent") : "Réservé" }</TableCell>
									<TableCell component="th" scope="row">
										{ !book.canceled ?
											<Button size='small' onClick={ stopBooking.bind(this, book) } color='secondary' variant={'outlined'}>Annuler</Button> : null
										}
									</TableCell>
								</TableRow>
							)
						)}
						</TableBody>
					</Table>
				</TableContainer>
				}
				<p style={{ textAlign: 'center' }}>{ bookings.filter(b => !b.canceled).length } cours réservé & { bookings.filter(b => b.canceled).length } cours annulé</p>
			</AccordionDetails>
		</Accordion>
	);
};
