import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	AppBar,
	Toolbar,
	ButtonBase,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	Grid,
	Button,
	Fade
} from '@material-ui/core';
import { useSession } from './Session';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';

const toolBarMenuItems = [{
	to: '/planning',
	name: 'Planning'
}, {
	to: '/places',
	name: 'Lieux'
}, {
	to: '/classes',
	name: 'Cours'
}, {
	to: '/corporates',
	name: 'Entreprises'
}, {
	to: '/users',
	name: 'Utilisateurs'
}, {
	to: '/trials',
	name: "Séance d'essai"
}, {
	to: '/invoices',
	name: 'Relevé Coach'
}, {
	to: '/offers',
	name: 'Offres'
}, {
	to: '/promos',
	name: 'Promos'
}, {
	to: '/exports',
	name: 'Exports'
}, {
	to: '/penality',
	name: 'Pénalité'
}];

export default ({ darkMode }) => {
	const [anchorMainMenu, setAnchorMainMenu] = useState(null);
	const [title, setTitleMenu] = useState('Admin UC');
	const [anchorEl, setAnchorEl] = useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const [session, signOut] = useSession();

	const openProfileMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	if (!session.user.admin) {
		signOut();
	}

	const menuId = 'primary-search-account-menu-mobile';

	const subMenu = (
		<Menu
		  anchorEl={anchorEl}
		  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		  id={menuId}
		  keepMounted
		  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
		  open={isMenuOpen}
		  onClose={handleMenuClose}
		>
			{session.user.id && <MenuItem component={Link} to={`/users/${session.user.id}`}>
				{session.user.fullname}
			</MenuItem>}
			{!session.user.admin_readOnly &&
				<MenuItem component={Link} to={`/stats`}>Stats</MenuItem>
			}
			<MenuItem>
				<ButtonBase
				  aria-label="disconnect current user"
				  aria-controls="primary-search-account-menu"
				  aria-haspopup="true"
				  color="inherit"
				  onClick={signOut}
				>
					<p>Sign Out</p>
				</ButtonBase>
			</MenuItem>
		</Menu>
	);

	return (
		<div>
			<AppBar position="static">
				<Toolbar>

					<Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
						<ToolBarMenu
							anchorMainMenu={anchorMainMenu} setAnchorMainMenu={setAnchorMainMenu}
							title={title} setTitleMenu={setTitleMenu} />

						<Grid item md={2} xs={8}>
							<Grid container direction="row" justify="space-evenly" alignItems="center">
								<Grid item xs={3}>
									<MenuItem onClick={ () => darkMode.set(!darkMode.state) }>
										<ButtonBase
											aria-label="Button to switch to dark/light mode"
											aria-controls="primary-theme-button"
											aria-haspopup="true"
											color="inherit"
										>
											{ darkMode.state ? <BrightnessHighIcon /> : <Brightness4Icon /> }
										</ButtonBase>
									</MenuItem>
								</Grid>
								<Grid item xs={9}>
									<MenuItem onClick={openProfileMenu}>
										<IconButton
											aria-label="account of current user"
											aria-controls="primary-search-account-menu"
											aria-haspopup="true"
											color="inherit"
										>
											<Typography>Admin</Typography>
										</IconButton>
									</MenuItem>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{subMenu}
				</Toolbar>
			</AppBar>
		</div>
	);
};

const ToolBarMenu = ({anchorMainMenu, setAnchorMainMenu, title, setTitleMenu}) => {
	const isMainMenuOpen = Boolean(anchorMainMenu);
	const detectMob = () => {
		const toMatch = [
			/Android/i,
			/webOS/i,
			/iPhone/i,
			/iPad/i,
			/iPod/i,
			/BlackBerry/i,
			/Windows Phone/i
		];
		return toMatch.some((toMatchItem) => {
			return navigator.userAgent.match(toMatchItem);
		});
	};
	const openMainMenu = event => {
		setAnchorMainMenu(event.currentTarget);
	};
	const handleMainMenuClose = () => {
		setAnchorMainMenu(null);
	};
	const setMenuName = (value, event) => {
		setTitleMenu(value.name);
		setAnchorMainMenu(null);
	};

	if (detectMob()) {
		return (
			<Grid item md={8} xs={4}>
				<Button
					style={{ textTransform: 'none' }}
					aria-controls="fade-menu"
					aria-haspopup="true"
					onClick={ openMainMenu }
					variant='contained'>
					{title}
				</Button>
				<Menu
					id="fade-menu"
					anchorEl={ anchorMainMenu }
					keepMounted
					open={ isMainMenuOpen }
					onClose={ handleMainMenuClose }
					TransitionComponent={ Fade }
				>
					{ toolBarMenuItems.map((el, i) => (
						<MenuItem key={i} component={Link} to={el.to} onClick={ setMenuName.bind(this, el) }>
							{el.name}
						</MenuItem>
					)) }
				</Menu>
			</Grid>
		);
	} else {
		return (
			<Grid item md={10} xs={8}>
				<Grid container spacing={1} direction="row" justify="center" alignItems="center">
				{toolBarMenuItems.map((el, i) => (
					<Grid item key={i}>
						<MenuItem component={Link} to={el.to}>
							{el.name}
						</MenuItem>
					</Grid>
				))}
				</Grid>
			</Grid>
		);
	}
};
