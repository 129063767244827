import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
	Button,
} from "@material-ui/core";
import './TextEditor.scss';

const modules = {
	toolbar: [
		'bold', 'italic', 'underline',
		{ 'list': 'bullet' },
		'link',
		{ 'color': [ "#ff0003","#0dff09","#0200ff","#ffffff","#000000"] },
		'clean'
	]
};

export default class TextEditor extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			step: 'DISPLAY',
			text: this.props.value
		};
	}

	cancel = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			step: 'DISPLAY',
			text: this.props.value
		});
	};

	onChange = (text) => {
		this.setState({text}, () => {
			let text = this.state.text;
			if(text === '<p><br></p>') {
				text = '';
			}
			this.props.onChange({
				target: {
					name: this.props.fieldName,
					value: text
				}
			});
		});
	};

	render() {
		let toolbar = null;

		if(!this.props.modify)
			toolbar = modules.toolbar;

		return (
			<div className={'TextEditor' + (this.props.modify ? ' -disabled': '') }>
				<label>{this.props.label}</label>
				<ReactQuill
					value={this.state.text}
					readOnly={ this.props.modify }
					onChange={this.onChange}
					modules={{toolbar}}
				/>
				{!this.props.modify && <div className='actions'>
					{ !(!!this.props.hideCancel && this.props.hideCancel) &&
						<Button className='cancel' variant='text' onClick={this.cancel}>Annuler</Button>
					}
				</div>}
			</div>
		);
	}
}
