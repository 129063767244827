import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	Button,
	MenuItem,
	TableBody,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	FormControl,
	InputLabel,
	FormControlLabel,
	Checkbox,
	DialogActions
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from './UniqueTableHead';
import './Offers.scss';
import API from './API';
import TextEditor from "./TextEditor";

export default () => {
	const initialOffer = {
		corporateId: 'none',
		bookingsMax: 1,
		durationInMonth: 1,
		commitment: 0
	};
	const [ state, change ] = useState("INIT");
	const [ modalOpen, setModal ] = useState(false);
	const [ newOffer, updateOffer ] = useState({
		step: "INIT",
		...initialOffer
	});
	const [ offers, changeOffers ] = useState(null);
	const [ query, changeQuery ] = useState({
		search: null,
		status: 'published',
		corporate: 'none',
		orderBy: 'price',
		order: 'desc',
		page: 0,
		maxPage: 1,
		resultPerPage: 50,
		total: 0
	});

	useEffect(() => {
		if (offers) return;
		change("PENDING");
		API.offer.search(query)
		.then(results => {
			changeOffers( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		})
		.then( () => change("OK") )
		.catch(err => {
			console.error(err);
		});

	}, [ offers, query ]);

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changeOffers(null);
	};
	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change("PENDING");
		changeOffers(null);
	};
	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changeOffers(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changeOffers(null);
	};
	const handleSearchChange = (e) => {
		changeQuery({ ...query, search: e.target.value });
	};
	const handleFilterChange = (e) => {
		changeQuery({ ...query, status: e.target.value });
		changeOffers(null);
	};
	const handleTypeChange = (e) => {
		changeQuery({ ...query, corporate: e.target.value });
		changeOffers(null);
	};
	const handleModalClose = () => {
		if (Object.values(newOffer).length > 5) {
			if (window.confirm("Des changements n'ont pas été enregistrer, continuer ?")) {
				updateOffer({ step: "INIT", ...initialOffer });
				return setModal(!modalOpen);
			}
			else return ;
		}
		setModal(!modalOpen);
		updateOffer({ step: "INIT", ...initialOffer });
	};
	const addOffer = (e) => {
		e.preventDefault();
		setModal(true);
		updateOffer({ ...newOffer, step: "CREATE" });
	};
	const selectCorp = [ { value: 'none', display: 'Tout' }, { value: 'corporate', display: 'Corporate' }, { value: 'public', display: 'Public' } ];
	const selectStatus = [ { value: 'all', display: 'Tous' }, { value: 'pending', display: 'En attente' }, { value: 'published', display: 'Publié' }, { value: 'deleted', display: 'Supprimé' } ];
	const forms = [
		{ md: 6, type: 'text', label: 'Recherche', placeholder: "Nom de l'offre", className: 'SearchField', value: query.search, onChange: handleSearchChange },
		{ md: 2, type: 'select', className: 'SelectField', value: query.corporate, onChange: handleTypeChange, choices: selectCorp },
		{ md: 2, type: 'select', className: 'SelectField', value: query.status, onChange: handleFilterChange, choices: selectStatus },
		{ md: 2, type: 'button', className: 'AddField', display: 'Ajouter', onClick: addOffer, variant: 'contained', color: 'primary' }
	];

	return <Container className='Offers'>

		<Grid container spacing={2} justify="center" alignItems="center" style={{ marginBottom: ".5rem" }}>
			{ forms.map( (s, i) => (
				<Grid item md={s.md} key={ "filter_" + i }>
					{s.type === 'button' ?
						<Button className={s.className} variant={s.variant} color={s.color} onClick={s.onClick}>{s.display}</Button> :
						<form onSubmit={search} noValidate autoComplete="off">
							{s.type === 'text' && <TextField fullWidth className={s.className} label={s.label} defaultValue={s.value}
																							 placeholder={s.placeholder} variant="outlined" onChange={s.onChange}/>}
							{s.type === 'select' && <Select
								variant="outlined"
								fullWidth
								className="SearchField"
								value={s.value}
								onChange={s.onChange}>
								{s.choices.map(t => <MenuItem key={'typeInvoice_' + t.value} value={t.value}>{t.display}</MenuItem>)}
							</Select>}
						</form>
					}
				</Grid>
			) )}
		</Grid>

		{ (state === "REDIRECT" && !!newOffer.id) && <Redirect to={`/offers/${newOffer.id}`} /> }
		{ newOffer.step === "CREATE" && <OfferCreate change={change} open={modalOpen} handleClose={handleModalClose} offer={newOffer} setOffer={updateOffer} /> }
		{ state === "PENDING" && <LoadingCircle /> }
		{ state === "OK" && <OffersList
			offers={ offers }
			onRequestSort={ handleRequestSort }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }

	</Container>;
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'id', disablePadding: true, label: 'ID' },
		{ id: 'name', disablePadding: true, label: 'Nom' },
		{ id: 'description', disablePadding: true, label: 'Description' },
		{ id: 'price', disablePadding: true, label: 'Prix' },
		{ id: 'restriction_corporate', disablePadding: true, label: 'Corp/Gp' },
		{ id: 'releaseDate', disablePadding: true, label: 'Commence' },
		{ id: 'expirationDate', disablePadding: true, label: 'Termine' },
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const OffersList = ({ offers, ...props }) => {
	if (!offers) return null;

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{offers.map(offer => (
					<TableRow key={offer.id} hover={true} >
						<TableCell align="center">
							<Link to={`/offers/${offer.id}`}>{offer.id}</Link>
						</TableCell>
						<TableCell align="center">{ offer.name }</TableCell>
						<TableCell align="center">{offer.description}</TableCell>
						<TableCell align="center">{ offer.price / 100 }€</TableCell>
						<TableCell align="center">{ !!offer.restriction.corporate ? "Corporate" : "Public" }</TableCell>
						<TableCell align="center">{ moment(offer.date.release).format('YYYY-MM-DD') }</TableCell>
						<TableCell align="center">{ moment(offer.date.expire).format('YYYY-MM-DD') }</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};

const OfferCreate = ({ open, handleClose, offer, setOffer, change }) => {
	const [ corporates, updateCorporates ] = useState([]);

	useEffect(() => {
		if (corporates.length > 0) return ;

		API.corporate.search({ resultPerPage: 1000 })
		.then(corporate => {
			updateCorporates(corporate.values);
		});
	}, [ corporates, updateCorporates ]);

	const handleChange = (e) => {
		let tmp = {};
		tmp[e.target.name] = e.target.value || e.target.checked;
		if (e.target.name === 'price') tmp[e.target.name] = (e.target.value * 100);
		setOffer({ ...offer, ...tmp });
	};

	const save = () => {
		let body = {};

		if (typeof offer.durationInMonth !== undefined) {
			body.renewable = 0;
			body.durationInMonth = parseInt(offer.durationInMonth);
		}
		if (typeof offer.renewable !== undefined && offer.renewable > 0) {
			body.renewable = 1;
			body.durationInMonth = 1;
		}
		if (typeof offer.name !== 'undefined') body.name = offer.name;
		if (typeof offer.description !== 'undefined') body.description = offer.description;
		if (typeof offer.price !== 'undefined') body.price = parseInt(offer.price);
		if (typeof offer.commitment !== 'undefined') body.commitment = parseInt(offer.commitment);
		if (typeof offer.bookingsMax !== 'undefined') body.bookingsMax = parseInt(offer.bookingsMax);
		if (typeof offer.corporateId !== 'undefined' && offer.corporateId !== 'none') body.corporateId = parseInt(offer.corporateId);
		if (typeof offer.liveOnly !== undefined && offer.liveOnly > 0) body.liveOnly = offer.liveOnly;
		if (typeof offer.stage !== undefined && offer.stage > 0) body.stage = offer.stage;
		if (typeof offer.firstSubOnly !== undefined && offer.firstSubOnly > 0) body.firstSubOnly = offer.firstSubOnly;

		body.release_date = offer.release_date ||  moment().format('YYYY-MM-DD');
		body.expiration_date = offer.expiration_date ||  moment().add(1, 'year').format('YYYY-MM-DD');

		const now = moment().subtract(1, 'day');
		if (
			now < moment(body.expiration_date) &&
			now > moment(body.release_date) &&
			!window.confirm("ATTENTION, l'offre va être publié sur le site (offre valable d'avant aujourd'hui a après). Souhaitez vous continuer ?")
		) return ;

		API.offer.create(body)
		.then(newOffer => {
			setOffer({ id: newOffer.id, step: "INIT" });
			change("REDIRECT");
		})
		.catch(err => {
			console.error(err);
			change({ step: "ERROR", error: err });
		});
	};

	const canSave = () => {
		return !(!!offer.name &&
			!!offer.description &&
			offer.price >= 0);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth maxWidth="md"
			aria-labelledby="form-dialog-title">
			<div className="PromoCreate">
				<DialogTitle id="form-dialog-title">
					Création d'une offre
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} direction={'row'} justify={'space-between'} alignItems={'center'}>
						{/* Top */}
						<Grid item md={12}>
							<Grid container spacing={1} direction={'row'} justify={'space-around'} alignItems={'center'}>
								{/* Nom */}
								<Grid item md={6}>
									<FormControl fullWidth>
										<TextField
											fullWidth
											label="Nom"
											type="text"
											name="name"
											onChange={ handleChange }
											value={ offer.name || "" }
										/>
									</FormControl>
								</Grid>
								{/* Description */}
								<Grid item md={6}>
									<TextEditor
										label={"Description"}
										value={ offer.description || "" }
										fieldName="description"
										onChange={ handleChange }
										modify={ false }
									/>
								</Grid>
							</Grid>
						</Grid>
						{/* Left side */}
						<Grid item md={6}>
							<Grid container spacing={2} direction={'column'} justify={'center'} alignItems={'stretch'}>
								{/* Group Price/Renewable*/}
								<Grid item md={12}>
									<Grid container spacing={1} direction={'row'} justify='space-around' alignItems="center">
										{/* Price */}
										<Grid item md={7}>
											<FormControl fullWidth>
												<TextField
													fullWidth
													label="Prix (en €)"
													type="number"
													inputProps={{ step: 1, min: 0 }}
													name="price"
													onChange={handleChange}
													value={offer.price / 100 || 0 }
												/>
											</FormControl>
										</Grid>
										{/* Renewable */}
										<Grid item md={5}>
											<FormControlLabel label="Renouvelable" labelPlacement="start"
																				control={<Checkbox
																					color="secondary"
																					name="renewable"
																					onChange={handleChange}
																					defaultChecked={ offer.renewable > 0 || false }
																				/>}
											/>
										</Grid>
									</Grid>
								</Grid>
								{/* Group Release/Expiration date*/}
								<Grid item md={12}>
									<Grid container spacing={1} direction={'row'} justify='space-around' alignItems="center">
										{/* Release_Date */}
										<Grid item md={6}>
											<FormControl fullWidth>
												<TextField
													fullWidth
													label="Date de début (inclue)"
													type="date"
													name="release_date"
													onChange={ handleChange }
													value={ offer.release_date || moment().format("YYYY-MM-DD") }
												/>
											</FormControl>
										</Grid>
										{/* Expiration_Date */}
										<Grid item md={6}>
											<FormControl fullWidth>
												<TextField
													fullWidth
													label="Date de fin (exclue)"
													type="date"
													name="expiration_date"
													onChange={ handleChange }
													value={ offer.expiration_date || moment().add(1, 'year').format("YYYY-MM-DD") }
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
								{/* Live only */}
								<Grid item md={12}>
									<FormControlLabel label="Restriction Live uniquement" labelPlacement="end"
																		control={<Checkbox
																			color="secondary"
																			name="liveOnly"
																			onChange={handleChange}
																			defaultChecked={ offer.liveOnly > 0 || false }
																		/>}
									/>
								</Grid>
								{/* stage only */}
								<Grid item md={12}>
									<FormControlLabel label="Restriction Stage uniquement" labelPlacement="end"
																		control={<Checkbox
																			color="secondary"
																			name="stage"
																			onChange={handleChange}
																			defaultChecked={ offer.stage > 0 || false }
																		/>}
									/>
								</Grid>
							</Grid>
						</Grid>
						{/* Right side */}
						<Grid item md={6}>
							<Grid container spacing={2} direction={'column'} justify={'center'} alignItems={'stretch'}>
								{/* Conditional group Duration In Month, Booking Max, Commitment */}
								<Grid item md={12}>
									<Grid container spacing={1} direction={'row'} justify='space-around' alignItems="center">
										{/* Duration In Month */}
										<Grid item md={!(offer.renewable) ? 6 : 4}>
											<FormControl fullWidth>
												<TextField
													fullWidth
													label="Durée (1=1mois,...)"
													type="number"
													disabled={ offer.renewable > 0 }
													inputProps={{ step: 1, min: 1 }}
													name="durationInMonth"
													onChange={ handleChange }
													value={ offer.renewable > 0 ? 1 : offer.durationInMonth || 1 }
												/>
											</FormControl>
										</Grid>
										{/* Booking Max (0 = illimité) */}
										{ !(offer.renewable) && <Grid item md={6}>
											<FormControl fullWidth>
												<TextField
													fullWidth
													label="Booking Max"
													type="number"
													inputProps={{ step: 1, min: 0 }}
													name="bookingsMax"
													onChange={handleChange}
													value={offer.bookingsMax || 0 }
												/>
											</FormControl>
										</Grid>}
										{/* Commitment */}
										{ offer.renewable > 0 && <Grid item md={8}>
											<FormControl fullWidth>
												<TextField
													fullWidth
													label="Durée engagement (0=sans,1=1mois,...)"
													type="number"
													inputProps={{ step: 1, min: 0 }}
													name="commitment"
													onChange={handleChange}
													value={offer.commitment || 0 }
												/>
											</FormControl>
										</Grid>}
									</Grid>
								</Grid>
								{/* Choose Corp */}
								<Grid item md={12}>
									{(!!corporates && corporates.length > 0) &&
									<FormControl fullWidth>
										<InputLabel shrink id="offer-select-corporate">Corporate</InputLabel>
										<Select
											labelId="offer-select-corporate"
											name="corporateId"
											value={offer.corporateId}
											onChange={handleChange}>
											<MenuItem value={'none'}>Choisir une entreprise pour restreindre</MenuItem>
											{corporates.map(co => <MenuItem key={co.id} value={co.id}>{co.name}</MenuItem>)}
										</Select>
									</FormControl>}
								</Grid>
								{/* first Sub only */}
								<Grid item md={12}>
									<FormControlLabel label="Restriction Premier abonnement" labelPlacement="end"
																		control={<Checkbox
																			color="secondary"
																			name="firstSubOnly"
																			onChange={handleChange}
																			defaultChecked={ offer.firstSubOnly > 0 || false }
																		/>}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button style={{ textTransform: 'none' }} onClick={ handleClose } color="secondary">
						Annuler
					</Button>
					<Button style={{ textTransform: 'none' }} disabled={ canSave() } onClick={ save } color="primary" variant="outlined">
						Créer
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};
