const ORIGIN = (
    window.location.origin
    || `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
);

function request(path, method, _body) {
  const url = `${ORIGIN}/api/admin${path}`;
  const body = JSON.stringify(_body);
  const headers = {
    'content-type': 'application/json',
    'Accept': 'application/json'
  };

  return fetch(url, {body, credentials:'include', headers, method})
      .then(response => {
        if (!response) Promise.reject({ statusCode: 0 });

        if (!response.ok) {
          if (!response.headers.get('content-type').startsWith('application/json')) {
            return Promise.reject({
              statusCode: response.status,
              statusText: response.statusText
            });
          }

          return response.json()
              .then(e =>Promise.reject(e))
              ;
        }

        if (response.status === 204) {
          return null;
        }

        return response.json();
      });
}


let API = {
  GET				: (path) 		=> request(path, "GET"),
  DELETE		: (path, body)	=> request(path, "DELETE", body),
  PATCH			: (path, body)  => request(path, "PATCH", body),
  POST			: (path, body)  => request(path, "POST", body),
  PUT				: (path, body)  => request(path, "PUT", body),
};


export default API;
