import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	Button,
	TableBody,
	Grid
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from "./UniqueTableHead";
import './Corporates.scss';
import API from './API';

export default () => {
	const [ state, change ] = useState("INIT");
	const [ corporates, changeCorporates ] = useState(null);
	const [ query, changeQuery ] = useState({
		name: null,
		orderBy: 'creation.date',
		order: 'desc',
		page: 1,
		resultPerPage: 50,
		total: 0
	});

	useEffect(() => {
		if (corporates) return;
		change("PENDING");
		API.corporate.search({ ...query, order: `${query.orderBy}-${query.order}` })
		.then(results => {
			changeCorporates( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		})
		.then( () => change("OK") )
		.catch(err => {
			console.error(err);
		});

	}, [ corporates, query ]);

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changeCorporates(null);
	};
	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change("PENDING");
		changeCorporates(null);
	};
	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changeCorporates(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changeCorporates(null);
	};
	const handleSearchChange = (e) => {
		changeQuery({ ...query, name: e.target.value });
	};
	const addCorporate = (e) => {
		e.preventDefault();
		const name = window.prompt("Entre le nom de l'entreprise");
		if (name && name.length > 2) {
			change("PENDING");
			API.corporate.create({ name })
			.then(corporate => {
				changeCorporates(null);
			})
			.catch(console.error);
		}
	};

	return <Container className='Corporates'>
			<form onSubmit={ search } noValidate autoComplete="off">
				<Grid container spacing={2} direction="row" justify="space-around" alignItems="center">
					<Grid item md={8}>
						<TextField
							fullWidth
							label="Recherche"
							placeholder="Nom / ID"
							defaultValue={ query.search }
							variant="outlined"
							onChange={ handleSearchChange }
						/>
					</Grid>
					<Grid item md={2}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={ addCorporate }
						>Ajouter</Button>
					</Grid>
				</Grid>
			</form>

		{ state === "PENDING" && <LoadingCircle /> }
		{ state === "OK" && <CorporatesList
			corporates={ corporates }
			onRequestSort={ handleRequestSort }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }

	</Container>;
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'id', disablePadding: true, label: 'ID' },
		{ id: 'name', disablePadding: false, label: 'Nom' },
		{ id: 'creation.date', disablePadding: false, label: 'Date de création' },
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const CorporatesList = ({ corporates, ...props }) => {
	if (!corporates) return null;

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{corporates.map(corporate => (
					<TableRow key={ "corp_" + corporate.id } hover={true} >
						<TableCell align="center">
							<Link to={`/corporates/${corporate.id}`}>{corporate.id}</Link>
						</TableCell>
						<TableCell align="center">{ corporate.name }</TableCell>
						<TableCell align="center">{ moment(corporate.creation.date).format('YYYY-MM-DD HH:mm') }</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};
