import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from "./UniqueTableHead";
import './Trials.scss';
import API from './API';

export default () => {
	const [ state, change ] = useState({ step: "INIT" });
	const [ trials, changeTrials ] = useState(null);
	const [ query, changeQuery ] = useState({
		search: null,
		orderBy: 's.start_date',
		order: 'desc',
		page: 1,
		maxPage: 1,
		resultPerPage: 50,
		total: 0
	});

	const loadTrials = useCallback(() => {
		return API.trial.search(query)
		.then(results => {
			changeTrials( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		});
	}, [ query ]);

	useEffect(() => {
		if (trials) return;
		change({ step: "PENDING" });
		loadTrials()
		.then( () => change({ step: "OK" }) )
		.catch(err => {
			if (err.restCode === 'resourceNotFound') err.restCode = "Pas de résultat trouver :/";

			change({ step: "ERROR", error: err });
		});
	}, [ trials, loadTrials ]);

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changeTrials(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change({ step: "PENDING" });
		changeTrials(null);
	};

	const handleChangePage = (event, page) => {
		changeQuery({ ...query, page: +(page+1) });
		changeTrials(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changeTrials(null);
	};
	const handleSearchChange = (e) => {
		changeQuery({ ...query, search: e.target.value });
	};

	return <Container className='Trials'>
			<form onSubmit={ search } noValidate autoComplete="off">
				<TextField
					className="SearchField"
					label={`Recherche`}
					placeholder="Nom / Prénom"
					defaultValue={ query.search }
					variant="outlined"
					onChange={ handleSearchChange }
				/>
			</form>

		{ state.step === "ERROR" && <LoadingCircle error={state.error} /> }
		{ state.step === "PENDING" && <LoadingCircle /> }
		{ state.step === "OK" && <TrialsList
			trials={ trials }
			onRequestSort={ handleRequestSort }
			reload={ loadTrials }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }

	</Container>;
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'sub.id', disablePadding: true, label: '#' },
		{ id: 'user', disablePadding: true, label: 'Utilisateur', disableSort: true },
		{ id: 's.start_date', disablePadding: true, label: 'Date' },
		{ id: 'cours', disablePadding: true, label: 'Cours', disableSort: true },
		{ id: 'presence', disablePadding: true, label: 'Présence', disableSort: true },
		{ id: 'nextSub', disablePadding: true, label: 'Abo suivant', disableSort: true },
		{ id: 'conversionCall', disablePadding: true, label: 'Appelé', disableSort: true },
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const TrialsList = ({ trials, ...props }) => {
	if (!trials) return null;

	const choices = [
		{ value: 'yes', name: 'Oui' },
		{ value: 'no', name: 'Non' },
		{ value: 'na', name: 'NA' }
	];

	const setCall = (value, event) => {
		API.subscription.edit(value.trial.id, {
			conversionCall: value.trial.conversionCall.value === value.entry.value ? null : value.entry.value
		})
		.then(() => {
			props.reload();
		})
		.catch(console.error);
	};

	const getColorField = (trial, field) => {
		if (trial.conversionCall.value === 'na' && field.value === 'na') return 'ChoicesNaSelected';
		else if (trial.conversionCall.value === 'yes' && field.value === 'yes') return 'ChoicesYesSelected';
		else if (trial.conversionCall.value === 'no' && field.value === 'no') return 'ChoicesNoSelected';
		return '';
	};

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{ trials.map(trial => (
					<TableRow key={trial.id} hover={true} >
						<TableCell align="center">
							{trial.id}
						</TableCell>
						<TableCell align="center">
							<a style={{ color: "inherit" }} target="_blank" rel="noreferrer noopener" href={`/users/${trial.user.id}`}>
								<small>{ trial.user.firstname } {trial.user.lastname}</small>
							</a><br/>
							<em>{ trial.user.phone }</em>
						</TableCell>
						<TableCell align="center">{  moment(trial.starts).format('YYYY-MM-DD HH:mm:ss') }</TableCell>
						<TableCell align="center">{ trial.class.name } à <strong>{trial.place.name}</strong></TableCell>
						<TableCell align="center">{
							trial.presence.value !== null ? !!trial.presence.value ? "Oui" : "Non" : "??"
						}</TableCell>
						{!!trial.abo ?
							<TableCell align="center">
								<small>{ moment(trial.abo.starts).format("DD/MM/YYYY") }</small><br/>
								<em>{ trial.abo.offer.name }</em>
							</TableCell> :
							<TableCell align="center">
							</TableCell>
						}
						<TableCell align="center">
							{ choices.map((c,i) => (
								<span
									key={ i }
									className={ `TrialAction ${getColorField(trial, c)}`}
									value={c.value}
									onClick={ setCall.bind(this, { entry: c, trial: trial }) }
								>{c.name}</span>
							)) }
						</TableCell>
					</TableRow>
				)) }
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};
