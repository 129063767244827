import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Paper, Table, TableBody, TableCell, TableRow, TableContainer } from "@material-ui/core";
import moment from "moment";

import API from "./API";
import UniqueTableHead from "./UniqueTableHead";
import './UserPenality.scss';

const UserPenality = () => {
  const [ users, setUsers ] = useState(false);

  useEffect(() => {
    if (users.length) return;
    API.penality.search()
    .then(results => {
      setUsers(results);
    })
    .catch(err => {
      console.error(err);
    });
  }, [ users ]);

  if (!users || users.length < 0) return <div>Aucun utilisateur pénalisé</div>;

  return (
    <Container className={'UserPenality'}>
      <TableContainer component={Paper}>
        <Table style={{minWidth: '375'}} size="small" aria-label="Users list">
          <EnhancedTableHead />
          <TableBody>
            { users.map(user => (
              <TableRow key={user.id} hover={true} >
                <TableCell align="center">
                  <Link to={`/users/${user.id}`}>{user.id}</Link>
                </TableCell>
                <TableCell align="center">{ user.lastname }</TableCell>
                <TableCell align="center">{ user.firstname }</TableCell>
                <TableCell align="center">{
                  !user.book_forbidden ? '' : moment(user.book_forbidden).format('YYYY-MM-DD HH:mm')
                }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const EnhancedTableHead = ({ ...props}) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => onRequestSort(event, property);

  const headCells = [
    { id: 'id', disablePadding: true, label: '#', disableSort: true },
    { id: 'lastname', disablePadding: true, label: 'Nom', disableSort: true },
    { id: 'firstname', disablePadding: true, label: 'Prénom', disableSort: true },
    { id: 'book_forbidden', disablePadding: true, label: "Pénalité jusqu'au", disableSort: true }
  ];

  return (
    <UniqueTableHead
      headCells={ headCells }
      orderBy={ orderBy }
      order={ order }
      createSortHandler={ createSortHandler } />
  );
};

export default UserPenality;
