import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import {
	Grid,
	Switch,
	CircularProgress,
	Container,
	TextField,
	Paper,
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	Select,
	MenuItem
} from "@material-ui/core";

import API from "./API";
import TextEditor from "./TextEditor";
import AddressSearch from "./AddressSearch";
import ImageGallery from "./ImageGallery";
import { Link } from "react-router-dom";

export default ({ match }) => {
	const [ state, change ] = useState("INIT");
	const [ place, updatePlace ] = useState(null);
	const [ newPlace, updateNewPlace ] = useState({});
	const [ modifiable, updateModifiable ] = useState(true);

	const loadPlace = useCallback(() => {
		return API.place.get( match.params.placeId )
		.then(userResponse => {
			updatePlace(userResponse);
		})
		.then(() => change("OK") );
	}, [ match ]);

	useEffect(() => {
		if (place) return;
		change("LOADING");
		loadPlace()
		.catch(console.error);
	}, [ loadPlace, place ]);

	const applyUpdate = (data) => {
		return API.place.edit( match.params.placeId, data)
		.then(() => {
			loadPlace()
			.then(() => updateNewPlace({}))
			.then(() => updateModifiable(true))
			.catch(console.error);
		})
		.catch(err => {
			console.error(err);
		});
	};

	const canModify = (e) => {
		e.preventDefault();
		if (!modifiable) {
			applyUpdate(newPlace);
		}
		updateModifiable(!modifiable);
	};

	const handleChange = (e) => {
		newPlace[e.target.name] = e.target.value;
		if (e.target.name === 'on_map') newPlace[e.target.name] = !place.on_map;
		if (e.target.name === 'is_live') newPlace[e.target.name] = !place.is_live;
		updatePlace({ ...place, ...newPlace });
		updateNewPlace(newPlace);
	};
	const isModified = () => {
		return Object.keys(newPlace).length > 0;
	};

	return <Container style={{ padding: '2rem' }}>
		{ state === "LOADING" && <CircularProgress /> }
		{ state === "OK" && <Grid container spacing={2} direction="column" justify="space-around" alignItems="stretch">
			<Grid item md={12}>
				<Grid container spacing={1} direction="row" justify="center" alignItems="flex-end">
					<Grid item md={3}>
						{ !!place && <a href={`https://www.urban-challenge.fr/place/${place.id}`} target="_blank" rel="noopener noreferrer">
							<Button
								size="small"
								disableElevation
								variant="outlined"
								color="default"
								style={{ textTransform:'none' }}
							>
								Voir sur urban
							</Button>
						</a> }
					</Grid>

					<Grid item md={3}>
						{ !!place && <Link to={`/planning?place=${place.id}`} >
							<Button
								size="small"
								disableElevation
								variant="outlined"
								color="default"
								style={{ textTransform:'none' }}
							>
								Voir le planning
							</Button>
						</Link> }
					</Grid>

					<Grid item md={3}>
						<Button
							disableElevation
							style={{ textTransform:'none' }}
							variant="contained"
							color={ modifiable ? "secondary" : isModified() ? "primary" : "default" }
							onClick={ canModify }
						>
							{ modifiable ? "Modifier" : isModified() ? "Sauvegarder les changements" : "Bloquer" }
						</Button>
					</Grid>

				</Grid>
			</Grid>

			<Grid item md={12}>
				{ !!place && <PlaceView place={ place } modify={modifiable} handleChange={ handleChange } applyUpdate={ applyUpdate } /> }
			</Grid>
		</Grid>
		}
	</Container>;
};

const PlaceView = ({ place, modify, handleChange, applyUpdate }) => {
	if (!place) return null;
	return (
		<div className="PlaceView">
			<Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
				<Grid container spacing={2} direction={"row"} >
					<Grid item md={10}>
						<PlaceLeftField place={place} handleChange={handleChange} modify={modify} applyUpdate={applyUpdate} />
					</Grid>
					<Grid item md={2}>
						<PlaceRightField place={place} handleChange={handleChange} modify={modify} />
					</Grid>
				</Grid>
			</Paper>
			<Paper style={{ padding: '1rem' }}>
				<ImageGallery
					gallery={place.images}
					from={{ id: place.id, type: 'place' }}
					modify={modify}
					applyUpdate={applyUpdate}
				/>
			</Paper>
		</div>
	);
};

const PlaceLeftField = ({ place, modify, handleChange, applyUpdate }) => {
	return (
		<Grid container spacing={2} direction={"column"}>
			<Grid item md={12}>
				<TextField onChange={ handleChange } label="Nom du lieu" name='title' variant="outlined" disabled={modify} defaultValue={ place.title } fullWidth />
			</Grid>

			<Grid item md={12}>
				<AddressSearch
					name='address'
					label="Adresse (point sur la carte)"
					modify={ modify }
					value={ place.address }
					update={ applyUpdate }
					onChange={ handleChange }
				/>
			</Grid>

			<Grid item md={12}>
				<TextEditor
					label={"Description"}
					value={ place.infos.description }
					fieldName="description"
					onChange={ handleChange }
					modify={ modify }
				/>
			</Grid>

			<Grid item md={12}>
				<TextEditor
					label={"Adresse & Informations Pratiques"}
					value={ place.infos.practical }
					fieldName="practical"
					onChange={ handleChange }
					modify={ modify }
				/>
			</Grid>

			<Grid item md={12}>
				<TextEditor
					label={"Informations Pratiques Coach"}
					value={ place.infos.coach }
					fieldName="coach_practical"
					onChange={ handleChange }
					modify={ modify }
				/>
			</Grid>

		</Grid>
	);
};

const PlaceRightField = ({ place, modify, handleChange }) => {
	return (
		<Grid container spacing={2} direction={"column"}>
			<Grid item md={12}>
				<FormControl variant="outlined" fullWidth >
					<InputLabel id="placeState">Status</InputLabel>
					<Select
						disabled={modify}
						name='state'
						labelId="placeState"
						id="select-place-state"
						value={ place.state }
						labelWidth={45}
						onChange={ handleChange }
					>
						<MenuItem value={'pending'}>En Attente</MenuItem>
						<MenuItem value={'suspended'}>Suspendu</MenuItem>
						<MenuItem value={'published'}>Publié</MenuItem>
						<MenuItem value={'deleted'}>Supprimé</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item md={12}>
				<TextField label="Créer le" variant="outlined" disabled defaultValue={ moment(place.dates.creation).format('DD-MM-YYYY HH:mm') } fullWidth />
			</Grid>
			<Grid item md={12}>
				<TextField label="Mis a jour le" variant="outlined" disabled defaultValue={ moment(place.dates.updated).format('DD-MM-YYYY HH:mm') } fullWidth />
			</Grid>
			{ place.dates.published &&
			<Grid item md={12}>
				<TextField label="Publier le" variant="outlined" disabled defaultValue={ moment(place.dates.published).format('DD-MM-YYYY HH:mm') } fullWidth />
			</Grid>
			}
			<Grid item md={12}>

				<FormControlLabel
					control={
						<Switch
							disabled={ modify }
							checked={ !!place.on_map }
							onChange={ handleChange }
							name="on_map"
							color="primary"
						/>
					}
					label="Sur la carte"
				/>
			</Grid>
			<Grid item md={12}>

				<FormControlLabel
					control={
						<Switch
							disabled={ modify }
							checked={ !!place.is_live }
							onChange={ handleChange }
							name="is_live"
							color="primary"
						/>
					}
					label="Live"
				/>
			</Grid>
		</Grid>
	);
};
