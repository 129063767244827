import React, { useCallback, useEffect, useState } from "react";
import {
	Grid,
	CircularProgress,
	Container,
	Paper,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from "@material-ui/core";

import API from "./API";
import moment from "moment";
import TextEditor from "./TextEditor";

export default ({ match }) => {
	const [ state, change ] = useState("INIT");
	const [ classe, updateClasse ] = useState(null);
	const [ newClasse, updateNewClasse ] = useState({});
	const [ modifiable, updateModifiable ] = useState(true);

	const loadClasse = useCallback(() => {
		return API.classe.get( match.params.classeId )
		.then(response => {
			if( response.uc_price !== null ) {
				response.uc_price = response.uc_price / 100;
			}
			return updateClasse(response);
		})
		.then(() => change("OK") );
	}, [ match ]);

	useEffect(() => {
		if (classe) return;
		change("LOADING");
		loadClasse()
		.catch(console.error);
	}, [ loadClasse, classe ]);

	const applyUpdate = (data) => {
		if( data.uc_price === '') {
			data.uc_price = null;
		} else {
			data.uc_price *= 100;
		}
		return API.classe.edit( match.params.classeId, data)
		.then(() => {
			loadClasse()
			.then(() => updateNewClasse({}))
			.then(() => updateModifiable(true))
			.catch(console.error);
		})
		.catch(err => {
			console.error(err);
		});
	};

	const canModify = (e) => {
		e.preventDefault();
		if (!modifiable) {
			applyUpdate(newClasse);
		}
		updateModifiable(!modifiable);
	};

	const handleChange = (e) => {
		newClasse[e.target.name] = e.target.value;
		updateClasse({ ...classe, ...newClasse });
		updateNewClasse(newClasse);
	};
	const isModified = () => {
		return Object.keys(newClasse).length > 0;
	};

	return <Container style={{ padding: '2rem' }}>
		{ state === "LOADING" && <CircularProgress /> }
		{ state === "OK" && <Grid container spacing={2} direction="column" justify="space-around" alignItems="stretch">
			<Grid item md={12}>
				<Grid container spacing={1} direction="row" justify="center" alignItems="center">
					<Grid item md={ 12 } style={{ textAlign: 'center' }}>
						<Button
							disableElevation
							style={{ textTransform:'none' }}
							variant="contained"
							color={ modifiable ? "secondary" : isModified() ? "primary" : "default" }
							onClick={ canModify }
						>
							{ modifiable ? "Modifier" : isModified() ? "Sauvegarder les changements" : "Bloquer" }
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid item md={12}>
				{ !!classe && <ClasseView classe={ classe } modify={modifiable} handleChange={ handleChange } /> }
			</Grid>
		</Grid>
		}
	</Container>;
};

const ClasseView = ({ classe, modify, handleChange }) => {
	if (!classe) return null;

	return (
		<div className="ClasseView">
			<Paper style={{ padding: '1rem', marginBottom: '1rem' }}>

				<Grid container spacing={2} direction={"row"}>
					{/* title */}
					<Grid item md={6}>
						<FormControl fullWidth>
							<TextField
								label="Nom"
								name="title"
								inputProps={{ autoComplete: 'off' }}
								value={ classe.title }
								onChange={ handleChange }
								disabled={ modify }
							/>
						</FormControl>
					</Grid>
					{/* State */}
					<Grid item md={6}>
						<FormControl fullWidth>
							<InputLabel shrink id="select-classe-state">Status</InputLabel>
							<Select
								labelId="select-classe-state"
								name="state"
								value={ classe.state }
								disabled={ modify }
								onChange={ handleChange }>
								<MenuItem value={'none'}>Etat du code</MenuItem>
								<MenuItem value={'deleted'}>Supprimer</MenuItem>
								<MenuItem value={'suspended'}>Suspendu</MenuItem>
								<MenuItem value={'published'}>Publier</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item md={6}>
						<FormControl fullWidth>
							<TextField
								label="Prix à utiliser dans le refund coach"
								name="uc_price"
								inputProps={{ autoComplete: 'off' }}
								value={ (classe.uc_price === null || classe.uc_price === '') ? '' : (classe.uc_price)  }
								onChange={ handleChange }
								disabled={ modify }
							/>
						</FormControl>
					</Grid>
				</Grid>


				<Grid container spacing={2} direction={"row"} >
					{/* description */}
					<Grid item md={12} style={{ marginTop: '1rem', marginBottom: '1rem'}}>
						<TextEditor
							label={"Description"}
							value={ classe.description || '' }
							fieldName="description"
							onChange={ handleChange }
							hideCancel={ true }
							modify={ modify }
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} direction={"row"} >
					{/* created_date */}
					<Grid item md={classe.published.date !== null ? 4 : 6}>
						<FormControl fullWidth>
							<TextField
								label="Créer le"
								type="text"
								name="created_date"
								disabled={ true }
								value={ moment(classe.creation.date).format("YYYY-MM-DD HH:mm") }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
					{/* modified_date */}
					<Grid item md={classe.published.date !== null ? 4 : 6}>
						<FormControl fullWidth>
							<TextField
								label="Dernière modification le"
								type="text"
								name="modified_date"
								disabled={ true }
								value={ moment(classe.modification.date).format("YYYY-MM-DD HH:mm") }
								variant="outlined"
							/>
						</FormControl>
					</Grid>
					{/* published_date */}
					{ classe.published.date && <Grid item md={4}>
						<FormControl fullWidth>
							<TextField
								label="Publié le"
								type="text"
								name="published_date"
								disabled={ true }
								value={ moment(classe.published.date).format("YYYY-MM-DD HH:mm") }
								variant="outlined"
							/>
						</FormControl>
					</Grid>}
				</Grid>

			</Paper>
		</div>
	);
};
