import React, { useState, useEffect, createContext, useContext } from "react";
import Login from './Login';
import API from './API';
import { LinearProgress } from '@material-ui/core';

//TODO remove after when all others TODO are removed

const after = (ms) => new Promise( resolve => setTimeout(resolve, ms) );

export const Context = createContext();
export const useSession = () => useContext(Context);

export default ({children}) => {
  let [session, update] = useState({
    step: 'INIT',
    user: null
  });

  useEffect( () => {
    update({ step:'LOADING', user: null });
    after(1000)
    .then(() => {
      return API.session.get('mine')
      .then(user => {
        update({ step:'OK', user, msg: null });
      })
      .catch(e => {
        if (e.restCode === 'authRequired') return update({ step: 'OK', msg: "TODO" });
        if (e.restCode === 'authFailed') return update({ step: 'OK', msg: "TODO" });
        if (e.restCode === 'authExpired') return update({ step: 'OK', msg: "TODO" });
        if(e.restCode === 'authInvalid') return update({ step:'OK', msg: "TODO"});
        console.error(e);
        update({ step: 'ERROR', error: e });
      });
    });
  }, []);

  const signIn = ({ email, password }) => {
    update({ step:'LOADING', user: null });
    after(1000)
    .then( () => {
      API.session.create({ email, password })
      .then( () => {
        return API.session.get('mine');
      })
      .then( user => {
        update({ step: 'OK', user, msg: null });
      })
      .catch(e => {
        console.error(e);
        update({ step: 'ERROR', error: e });
      });
    });
  };

  const signOut = () => {
    update({ step:'SIGNOUT', user:null });
    after(1000)
    .then( () => {
      API.session.delete('mine')
      .then(() => {
        return API.session.get('mine').then(user => {
          update({ step: 'OK', user, msg:null });
        });
      })
      .catch(e => {
        if (e.restCode === 'authRequired') return update({ step: 'OK', msg: "TODO" });
        if (e.restCode === 'authFailed') return update({ step: 'OK', msg: "TODO" });
        if (e.restCode === 'authExpired') return update({ step: 'OK', msg: "TODO" });
        console.error(e);
        update({ step: 'ERROR', error: e });
      });
    });
  };

  if( session.step === 'INIT' || session.step === 'LOADING' ) {
    return <LinearProgress />;
  }

  if( session.step === 'SIGNOUT' ) {
    //TODO
    return <p>Déconnection</p>;
  }

  if( session.step === 'ERROR' ) {
    //TODO
    return <p>ERROR</p>;
  }

  if (!session.user) {
    return (
      <Login signIn={ signIn } />
    );
  }

  return (
    <Context.Provider value={[session, signOut]} >
      {children}
    </Context.Provider>
  );
};
