import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import {
	Paper,
	Container,
	TextField,
	TableContainer,
	TablePagination,
	Table,
	TableRow,
	TableCell,
	Button,
	MenuItem,
	TableBody,
	Select,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogActions,
	FormControl,
	Grid,
	InputLabel,
} from '@material-ui/core';

import LoadingCircle from './LoadingCircle';
import UniqueTableHead from "./UniqueTableHead";
import './Classes.scss';
import API from './API';
import TextEditor from "./TextEditor";

export default () => {
	const [ state, change ] = useState("INIT");
	const [ modalOpen, setModal ] = useState(false);
	const [ newClasses, updateClasse ] = useState({
		step: "INIT",
		state: 'none'
	});
	const [ classes, changeClasses ] = useState(null);
	const [ query, changeQuery ] = useState({
		search: null,
		state: 'published',
		orderBy: 'title',
		order: 'asc',
		page: 0,
		maxPage: 1,
		resultPerPage: 50,
		total: 0
	});

	useEffect(() => {
		if (classes) return;
		change("PENDING");
		API.classe.search(query)
		.then(results => {
			changeClasses( results.values );
			changeQuery({
				...query,
				page: results.pagination.current,
				maxPage: results.pagination.max,
				total: results.pagination.count
			});
		})
		.then( () => change("OK") )
		.catch(err => {
			console.error(err);
		});
	}, [ classes, query ]);

	const search = (e) => {
		e.preventDefault();
		changeQuery({ ...query, page: 1 });
		changeClasses(null);
	};
	const handleRequestSort = (event, property) => {
		const isAsc = query.orderBy === property && query.order === 'asc';
		changeQuery({
			...query,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		});
		change("PENDING");
		changeClasses(null);
	};
	const handleChangePage = (event, page) => {
		changeQuery({...query, page: +(page+1) });
		changeClasses(null);
	};
	const handleChangeRowsPerPage = (event) => {
		changeQuery({ ...query, resultPerPage: +event.target.value });
		changeClasses(null);
	};
	const handleSearchChange = (e) => {
		changeQuery({ ...query, search: e.target.value });
	};
	const handleFilterChange = (e) => {
		changeQuery({ ...query, state: e.target.value });
		changeClasses(null);
	};
	const handleModalClose = () => {
		if (Object.values(newClasses).length > 1) {
			if (window.confirm("Des changements n'ont pas été enregistrer, continuer ?")) {
				updateClasse({ step: "INIT" });
				return setModal(!modalOpen);
			}
			else return ;
		}
		setModal(!modalOpen);
		updateClasse({ step: "INIT" });
	};
	const addClasse = (e) => {
		e.preventDefault();
		setModal(true);
		updateClasse({ ...newClasses, step: "CREATE" });
	};

	const selectStatus = [ { value: 'all', display: 'Tous' }, { value: 'published', display: 'Publié' }, { value: 'deleted', display: 'Supprimé' }, { value: 'suspended', display: 'Suspendu' } ];
	const forms = [
		{ md: 6, type: 'text', label: 'Recherche', placeholder: 'Nom / ID', className: 'SearchField', value: query.search, onChange: handleSearchChange },
		{ md: 3, type: 'select', className: 'SelectField', value: query.state, onChange: handleFilterChange, choices: selectStatus },
		{ md: 3, type: 'button', className: 'AddField', display: 'Ajouter', onClick: addClasse, variant: 'contained', color: 'primary' }
	];

	return <Container className='Classes'>
		<Grid container spacing={2} justify="center" alignItems="center" style={{ marginBottom: ".5rem" }}>
			{ forms.map( (s, i) => (
				<Grid item md={s.md} key={ "filter_" + i }>
					{s.type === 'button' ?
						<Button className={s.className} variant={s.variant} color={s.color} onClick={s.onClick}>{s.display}</Button> :
						<form onSubmit={search} noValidate autoComplete="off">
							{s.type === 'text' && <TextField fullWidth className={s.className} label={s.label} defaultValue={s.value}
																							 placeholder={s.placeholder} variant="outlined" onChange={s.onChange}/>}
							{s.type === 'select' && <Select
								variant="outlined"
								fullWidth
								className="SearchField"
								value={s.value}
								onChange={s.onChange}>
								{s.choices.map(t => <MenuItem key={'typeInvoice_' + t.value} value={t.value}>{t.display}</MenuItem>)}
							</Select>}
						</form>
					}
				</Grid>
			) )}
		</Grid>

		{ (state === "REDIRECT" && !!newClasses.id) && <Redirect to={`/classes/${newClasses.id}`} /> }
		{ newClasses.step === "CREATE" && <ClasseCreate change={change} open={modalOpen} handleClose={handleModalClose} classe={newClasses} setClasse={updateClasse} /> }
		{ state === "PENDING" && <LoadingCircle /> }
		{ state.step === "ERROR" && <LoadingCircle error={ state.error }/> }
		{ state === "OK" && <ClassesList
			classes={ classes }
			onRequestSort={ handleRequestSort }
			order={ query.order }
			orderBy={ query.orderBy }
			page={ query.page - 1 }
			total={ query.total }
			resultPerPage={ query.resultPerPage }
			changePage={ handleChangePage }
			changeRowsPerPage={ handleChangeRowsPerPage }
		/> }

	</Container>;
};

const EnhancedTableHead = ({ ...props}) => {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	const headCells = [
		{ id: 'id', disablePadding: true, label: '#' },
		{ id: 'title', disablePadding: true, label: 'Nom' },
		{ id: 'state', disablePadding: true, label: 'Status', disableSort: true },
		{ id: 'published_date', disablePadding: true, label: 'Publié le', disableSort: true }
	];

	return (
		<UniqueTableHead
			headCells={ headCells }
			orderBy={ orderBy }
			order={ order }
			createSortHandler={ createSortHandler } />
	);
};

const ClassesList = ({ classes, ...props }) => {
	if (!classes) return null;

	return <TableContainer component={Paper}>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
		<Table style={{minWidth: '375'}} size="small" aria-label="Users list">
			<EnhancedTableHead {...props} />
			<TableBody>
				{ classes.map(classe => (
					<TableRow key={classe.id} hover={true} >
						<TableCell align="center">
							<Link to={`/classes/${classe.id}`}>{classe.id}</Link>
						</TableCell>
						<TableCell align="center">{ classe.name }</TableCell>
						<TableCell align="center">{ classe.status }</TableCell>
						<TableCell align="center">{
							!classe.published.date ? '' : moment(classe.published.date).format('YYYY-MM-DD')
						}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		<TablePagination
			rowsPerPageOptions={[25, 50, 100]}
			component="div"
			labelRowsPerPage={"# par page"}
			count={ props.total }
			rowsPerPage={ props.resultPerPage }
			page={ props.page }
			onChangePage={ props.changePage }
			onChangeRowsPerPage={ props.changeRowsPerPage }
		/>
	</TableContainer>;
};

const ClasseCreate = ({ open, handleClose, classe, setClasse, change }) => {

	const handleChange = (e) => {
		let tmp = {};
		tmp[e.target.name] = e.target.value || e.target.checked;
		setClasse({ ...classe, ...tmp });
	};

	const save = () => {
		let body = {
			created_at: moment().format('YYYY-MM-DD HH:mm:ss')
		};

		if (typeof classe.title !== undefined) body.title = classe.title;
		if (typeof classe.description !== undefined) body.description = classe.description;
		if (typeof classe.state !== undefined) body.state = classe.state;

		API.classe.create(body)
		.then(newPromo => {
			setClasse({ id: newPromo.id, step: "INIT" });
			change("REDIRECT");
		})
		.catch(err => {
			console.error(err);
			change({ step: "ERROR", error: err });
		});
	};
	const canSave = () => {
		return !(!!classe.title && !!classe.state && classe.state !== 'none');
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth maxWidth="sm"
			aria-labelledby="form-dialog-title">
			<div className="ClassesCreate">
				<DialogTitle id="form-dialog-title">
					Création d'un cours
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} direction={'row'} justify={'space-between'} alignItems={'center'}>

						<Grid item md={12}>
							<Grid container spacing={2} direction={'column'} justify={'center'} alignItems={'stretch'}>

								{/* Nom */}
								<Grid item md={12}>
									<FormControl fullWidth>
										<TextField
											fullWidth
											label="Nom"
											type="text"
											name="title"
											onChange={ handleChange }
											value={ classe.title || "" }
										/>
									</FormControl>
								</Grid>
								{/* State */}
								<Grid item md={12}>
									<FormControl fullWidth>
										<InputLabel shrink id="select-classe-state">Status</InputLabel>
										<Select
											labelId="select-classe-state"
											name="state"
											value={ classe.state }
											onChange={ handleChange }>
											<MenuItem value={'none'}>Etat du code</MenuItem>
											<MenuItem value={'deleted'}>Supprimer</MenuItem>
											<MenuItem value={'suspended'}>Suspendu</MenuItem>
											<MenuItem value={'published'}>Publier</MenuItem>
										</Select>
									</FormControl>
								</Grid>

								<Grid item md={12}>
									<TextEditor
										label={"Description"}
										value={ classe.description || '' }
										fieldName="description"
										onChange={ handleChange }
										hideCancel={ true }
									/>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button style={{ textTransform: 'none' }} onClick={ handleClose } color="secondary">
						Annuler
					</Button>
					<Button style={{ textTransform: 'none' }} disabled={ canSave() } onClick={ save } color="primary" variant="outlined">
						Créer
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};
