import React from 'react';
import {
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel
} from "@material-ui/core";

export default ({ headCells, orderBy, order, createSortHandler }) => {
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell key={headCell.id} align='center' sortDirection={orderBy === headCell.id ? order : false} >
						<TableSortLabel
							active={orderBy === headCell.id}
							disabled={headCell.disableSort}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={ !headCell.disableSort ? createSortHandler(headCell.id) : (e=>e.preventDefault()) }
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};
