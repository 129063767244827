import apiBase from './API-base';

const serialize = (obj) => obj && Object.entries(obj)
.filter(([ k, v ]) => typeof v !== 'undefined' && v !== null)
.map(([ k, v ]) => encodeURIComponent(k) + "=" + encodeURIComponent(v))
.join('&');

const ressources = [
  'session',
  'user',
  'abo',
  'booking',
  'place',
  'image',
  'seance',
  'classe',
  'corporate',
  'promo',
  'referral',
  'invoices',
  'offer',
  'trial',
  'subscription',
  'watcher',
  'teacher',
  'stats',
  'penality'
].reduce( (acc,name) => {
  acc[name] = {
    create: (body) => apiBase.POST(`/${name}`, body),
    edit: (id, body) => apiBase.PATCH(`/${name}/${id}`, body),
    search: (params) => apiBase.GET(`/${name}?${serialize(params)}`),
    get: 	(id) => apiBase.GET(`/${name}/${id}`),
    delete: (id) => apiBase.DELETE(`/${name}/${id}`)
  };
  return acc;
}, {});

ressources.teacher = {
  ...ressources.teacher,
  getByUserId: (id) => apiBase.GET(`/teacher/byUserId/${id}`)
};

export default ressources;
