import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from "@material-ui/core";

export default class AddressSearch extends React.Component {

	static contextTypes = {
		googleMaps : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		let placeAddress = (this.props.value.street +' '+ this.props.value.zip +' '+ this.props.value.city).trim();
		this.state = {
			step: 'DISPLAY',
			text: placeAddress,
			initial : placeAddress
		};
	}

	cancel = (e) => {
		e.preventDefault();
		this.setState({
			step: 'DISPLAY',
			text: this.state.initial
		});
	};

	save = (e) => {
		e.preventDefault();
		this.props.update({ address: this.state.address })
		.then(res => {
			this.setState({
				step: 'DISPLAY',
			});
		})
		.catch(e => console.error(e));
	};

	selectSuggestion = (e) => {
		e.preventDefault();

		let placeId = e.target.getAttribute('placeid');
		this.context.googleMaps.placeService.getDetails({placeId}, (placeDetails) => {
			let text = placeDetails.formatted_address.replace('France','').replace(/,/g,'');

			const formatAddress = (addr) => {
				return {
					number: addr.find(f => f.types.indexOf("street_number") >= 0 ),
					street: addr.find(f => f.types.indexOf("route") >= 0 ),
					city: addr.find(f => f.types.indexOf("locality") >= 0 ),
					zip: addr.find(f => f.types.indexOf("postal_code") >= 0 )
				}
			};
			let addr = formatAddress(placeDetails.address_components);
			this.setState({
				step: 'READY2SAVE',
				text: text,
				suggestions: null,
				address: {
					street: `${!!addr.number ? addr.number.long_name : ""} ${!!addr.street ? addr.street.long_name : ""}`,
					city: !!addr.city ? addr.city.long_name : "",
					zip: !!addr.zip ? addr.zip.long_name : "",
					geo : {
						lat: placeDetails.geometry.location.lat(),
						lng: placeDetails.geometry.location.lng()
					}
				}
			});
		});
	};

	onChange = (e) => {
		let searchText = e.target.value;
		if (!searchText)
			return this.setState({ text: '' });

		let request = {
			input: searchText,
			componentRestrictions: {country: 'fr'}
		};
		this.context.googleMaps.autocompleteService.getPlacePredictions(request, (suggestions, status)=> {

			var valid = (suggestions || [])
			.filter(s => {
				return s.types.length && s.terms.length === 4;
			})
			;
			this.setState({
				step: 'EDITING',
				text: searchText,
				suggestions: valid
			});
		});
	};
	render() {
		return (
			<div className='AddressSearch'>
				<TextField type="text" label={ this.props.label } placeholder="Entrez l'adresse" disabled={ this.props.modify }
									 value={this.state.text} onChange={this.onChange} fullWidth variant="outlined"
				/>
				{this.state.step === 'EDITING' &&
				<ul>
					{this.state.suggestions.map((e, i) => (
						<li key={`suggestion_${i}`} placeid={e.place_id} value={e.description} onClick={ this.selectSuggestion }>
							{e.description}
						</li>
					))}
				</ul>
				}
				{this.state.step === 'READY2SAVE' && <div className='actions'>
					<button className='save' onClick={this.save}>Sauvegarder</button>
					<button className='cancel' onClick={this.cancel}>Annuler</button>
				</div>}
			</div>
		);
	}
}
